export enum ProjectCardType {
  SCHEMA_MIGRATION = 'Schema Migration',
  SCHEMA_ANALYSIS = 'Schema Analysis',
  OBSERVABILITY_DASHBOARD = 'Observability Dashboard',
  PERFORMANCE = 'Performance'
}

export const STAGE_ENV = {
  type: 'Recent Activity'
};
