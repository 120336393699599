import { MarkerType } from '@xyflow/react';
import { SQLExplainNode, ParseContext, SQLExplainEdge } from './dbParserTypes';

export const createEdge = (source: string, target: string, context: ParseContext, reverse: boolean = false): SQLExplainEdge => {
  const edgeId = `edge_${context.nodeIdCounter++}`;
  const edge: SQLExplainEdge = {
    id: edgeId,
    source: reverse ? target : source, 
    target: reverse ? source : target,
    animated: true,
    markerEnd: { type: MarkerType.ArrowClosed }, 
    style: { strokeWidth: 2 },
  };

  context.edges.push(edge);
  return edge;
};

export const createTableNode = (tableData: any, context: ParseContext,parentId?: string): SQLExplainNode => {
  const nodeId = `node_${context.nodeIdCounter++}`;
  const isPrimaryIndexUsed = tableData.key === 'PRIMARY';
  const estimatedRows = tableData.filtered && tableData.rows_produced_per_join
      ? Number((tableData.rows_produced_per_join * 100 / parseFloat(tableData.filtered)).toFixed(0)).toLocaleString()
      : 'N/A';
  const filteredPercentage = !isPrimaryIndexUsed && tableData.filtered ? `${tableData.filtered}%` : null;
  const rowsAfterFilter = !isPrimaryIndexUsed ? tableData.rows_produced_per_join?.toLocaleString() || 'N/A' : null;
  const node: SQLExplainNode = {
    id: nodeId,
    type: 'sql-explain-node',
    data: {
      label: `Table ${tableData.table_name}`,
      type: 'table',
      accessMethod: tableData.access_type,
      indexName: tableData.key,
      estimatedRows: estimatedRows,
      rowsAfterFilter: rowsAfterFilter,
      filteredPercentage: filteredPercentage,
      filterCondition: tableData.attached_condition || null
    },
    position: {
      x: context.currentXPosition - (context.horizontalSpacing + 50),
      y: context.currentYPosition
    },
    ...(context.hasUnion || context.hasSubQuery ? { parentId, extent: 'parent' } : {})
  };

  context.nodes.push(node);
  context.currentXPosition -= (context.horizontalSpacing + 50);
  return node;
};

export const createGroupByNode = (context: ParseContext, parentId?: string): SQLExplainNode => {
  const nodeId = `node_${context.nodeIdCounter++}`;
  const node: SQLExplainNode = {
    id: nodeId,
    type: 'sql-explain-node',
    data: {
      label: 'Group By',
      type: 'group_by',
    },
    position: {
      x: context.currentXPosition,
      y: context.currentYPosition -(context.verticalSpacing +context.nodeIdCounter)
    },
    ...(context.hasUnion || context.hasSubQuery ? { parentId, extent: 'parent' } : {})
  };

  context.nodes.push(node);
  context.currentYPosition -= (context.verticalSpacing + 200)

  return node;
};

export const createJoinNode = (
    maxFilteredRows: number,
    filter: string,
    context: ParseContext,
    parentId?: string
): SQLExplainNode => {
  const nodeId = `node_${context.nodeIdCounter++}`;
  const node: SQLExplainNode = {
    id: nodeId,
    type: 'sql-explain-node',
    data: {
      label: 'Join',
      type: 'join',
      estimatedRows: maxFilteredRows.toLocaleString(),
      filter: filter !== 'No Filter' ? filter : undefined,
    },
    position: {
      x: context.currentXPosition,
      y: context.currentYPosition-(context.verticalSpacing +(context.nodeIdCounter)),
    },
    ...(context.hasUnion || context.hasSubQuery ? { parentId, extent: 'parent' } : {})
  };

  context.currentXPosition += context.horizontalSpacing/2;
  context.currentYPosition -=(context.verticalSpacing +(context.nodeIdCounter));


  return node;
};

export const createSortingNode = (context: ParseContext, parentId?: string): SQLExplainNode => {
  const nodeId = `node_${context.nodeIdCounter++}`;
  const node: SQLExplainNode = {
    id: nodeId,
    type: 'sql-explain-node',
    data: {
      label: 'Sorting Rows',
      type: 'sorting_rows',
      estimatedRows: context.latestRowsAfterFilter
          ? context.latestRowsAfterFilter?.toLocaleString()
          : '0',
    },
    position: {
      x: context.currentXPosition,
      y: context.currentYPosition -(context.verticalSpacing +context.nodeIdCounter)
    },
    ...(context.hasUnion || context.hasSubQuery ? { parentId, extent: 'parent' } : {})
  };

  context.nodes.push(node);
  context.currentYPosition -= (context.verticalSpacing + 200)


  return node;
};

export const createResultNode = (queryBlock: any, context: ParseContext, estimatedRows: number | null = null, parentId?: string): SQLExplainNode => {
  const nodeId = `node_${context.nodeIdCounter++}`;
  const node: SQLExplainNode = {
    id: nodeId,
    type: 'sql-explain-node',
    data: {
      label: 'Query Result',
      type: 'result',
      queryCost: queryBlock.cost_info ? Number(queryBlock.cost_info.query_cost)  : null,
      estimatedRows: estimatedRows?.toLocaleString() || 'N/A',
    },
    position: {
      x: context.currentXPosition,
      y: context.currentYPosition -(context.verticalSpacing + context.nodeIdCounter),
    },
    ...(context.hasUnion || context.hasSubQuery ? { parentId, extent: 'parent' } : {})
  };

  context.nodes.push(node);
  context.currentYPosition -= (context.verticalSpacing + 200);

  return node;
};

export const createUnionNode = (
    context: ParseContext,
    parentId?: string
): SQLExplainNode => {
  const nodeId = `node_${context.nodeIdCounter++}`;
  const node: SQLExplainNode = {
    id: nodeId,
    type: 'sql-explain-node',
    data: {
      label: 'Union',
      type: 'union',
    },
    position: {
      x: context.currentXPosition,
      y: context.currentYPosition - (context.verticalSpacing + context.nodeIdCounter)
    },
    ...(context.hasUnion || context.hasSubQuery ? { parentId, extent: 'parent' } : {})
  };

  context.nodes.push(node);
  context.currentYPosition -= (context.verticalSpacing + 200);

  return node;
};

export const createGroupNode = (
    groupId: string,
    context: ParseContext,
    width: number,
    height: number,
    parentId?: string
): SQLExplainNode => {
  const node: SQLExplainNode = {
    id: groupId,
    type: 'sql-sub-query-node',
    data: {
      label: `SUB-QUERY-${groupId}`,
      type: 'group',
      width,
      height
    },
    position: { x: context.currentXPosition, y: context.currentYPosition },
    ...(context.hasUnion || context.hasSubQuery ? { parentId, extent: 'parent' } : {})
  };

  context.nodes.push(node);
  return node;
};
