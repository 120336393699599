import DOMPurify from 'dompurify';

interface SafeInnerHtmlProps {
  unsafeInnerHtmlText: string | undefined;
}

const SafeInnerHtml = ({ unsafeInnerHtmlText }: SafeInnerHtmlProps) => {
  return <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(unsafeInnerHtmlText || '<div />')?.replace(/href/g, "target='_blank' href") }} />;
};
export default SafeInnerHtml;
