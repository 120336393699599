import { format } from 'sql-formatter';
import * as Sentry from '@sentry/react';

import { LogRecord } from '../types/LogRecord.type';
import { INSIGHT_STATUS, INSIGHT_STATUS_ENUM, RDBMS } from './constants';
import dayjs from 'dayjs';
import Duration from 'dayjs/plugin/duration';
import { HostsApiKeyT } from '../atoms/user.settings.atom';
dayjs.extend(Duration);

let baseDate: dayjs.Dayjs | null = null;

const customTickFormatter = (x: string): string => {
  const currentDate = dayjs(x);
  if (!baseDate) {
    baseDate = currentDate; 
    return currentDate.format('DD/MM'); 
  }

  if (!currentDate.isSame(baseDate, 'day')) {
    baseDate = currentDate; 
    return currentDate.format('DD/MM');
  }

  return currentDate.format('HH:mm');
};

/**
 * A string literal type that represents the level of precision for a short date.
 * - `'xs'`: Includes MMM-DD-YY HH:MM:ss.
 * - `'s'`: Includes MMM-DD-YY HH:MM.
 * - `'m'`: Includes MMM-DD-YY HH.
 * - `'l'`: Includes MMM-DD-YY.
 * - `'h'`: Includes HH:MM.
 * - `'dt'`: Includes DD/MM.
 * - `'custom'`: run customized customTickFormatter.
 */
export type ShortDateFormat = 'xs' | 's' | 'm' | 'l' | 'y' | 'h' | 'dt' | 'custom';

export const getFormattedDate = (date?: Date | string, shortDate: ShortDateFormat = 'y'): string => {
  const formatHandlers: Record<ShortDateFormat, (date?: Date | string) => string> = {
    xs: (date?: Date | string) => dayjs(date).format('MMM-DD-YY HH:mm:ss'),
    s: (date?: Date | string) => dayjs(date).format('MMM-DD-YY HH:mm'),
    m: (date?: Date | string) => dayjs(date).format('MMM-DD-YY HH'),
    l: (date?: Date | string) => dayjs(date).format('MMM-DD-YY'),
    h: (date?: Date | string) => dayjs(date).format('HH:mm'),
    y: (date?: Date | string) => dayjs(date).format('MMM-DD HH:mm'),
    dt: (date?: Date | string) => dayjs(date).format('DD/MM'),
    custom: (date?: Date | string) => customTickFormatter(date as string)
  };
  if (!date) return '';
  return formatHandlers[shortDate](date);
};

export const getTags = (tags: any[]) => {
  return tags.reduce((totalTags: any, curTag: any) => {
    if (totalTags.length == 0 || totalTags.filter((t: any) => Object.values(t)[0] != Object.values(curTag)[0]).length) {
      totalTags.push(curTag);
    }
    return totalTags;
  }, []);
};

export const formatSQL = (data: string, catchCb?: any): string => {
  try {
    const queryLong = data?.length > 3000 ? data.substring(0, 3000) + '...' : data;
    return format(queryLong || '', { language: 'postgresql' });
  } catch (error) {
    catchCb && catchCb(error);
    return `${data}`;
  }
};

export const getCookie = (name: string) => {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const isTypeOf = (val: any, type: string) => {
  return typeof val === type;
};

export const numberWithCommas = (x: number, toFixedNumber = 0): string => {
  if (typeof x !== 'number') {
    return '0';
  }
  const numberFixed = x.toFixed(toFixedNumber);
  return numberFixed.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getFacts = (facts: any) => {
  if (facts?.length > 0) {
    return facts.map((fact: any) => {
      return {
        'Metric Name': fact.metric_name,
        Value: isTypeOf(fact.value, 'number') ? numberWithCommas(fact.value) : fact.value,
        Category: fact.category,
        Type: fact.type,
        Description: fact.description
      };
    });
  }
  return [];
};

export const getFactsAsKeyVal = (facts: any) => {
  if (facts?.length > 0) {
    return facts.reduce((acc: any, cur: any) => {
      return {
        ...acc,
        [cur.metric_name]: cur.value
      };
    }, {});
  }
  return {};
};

export function getTotalSeverity(assertion: any[]) {
  if (assertion.length) {
    return assertion.reduce((acc, part) => {
      const priority = part?.priority ? part?.priority : part?.condition?.event?.priority;
      const counter: number = acc[INSIGHT_STATUS[priority]] ? acc[INSIGHT_STATUS[priority]] : 0;

      return {
        ...acc,
        [INSIGHT_STATUS[priority]]: counter + 1
      };
    }, {});
  }
  return {};
}

export const getSeverity = (severityTotal: any, severityItem: any) => ({
  [INSIGHT_STATUS_ENUM.CRITICAL]: INSIGHT_STATUS[severityItem[1]]
    ? severityTotal[INSIGHT_STATUS[1]] + severityItem[1]
    : severityTotal[INSIGHT_STATUS_ENUM.CRITICAL],
  [INSIGHT_STATUS_ENUM.HIGH]: INSIGHT_STATUS[severityItem[2]]
    ? severityTotal[INSIGHT_STATUS[2]] + severityItem[2]
    : severityTotal[INSIGHT_STATUS_ENUM.HIGH],
  [INSIGHT_STATUS_ENUM.MEDIUM]: INSIGHT_STATUS[severityItem[3]]
    ? severityTotal[INSIGHT_STATUS[3]] + severityItem[3]
    : severityTotal[INSIGHT_STATUS_ENUM.MEDIUM],
  [INSIGHT_STATUS_ENUM.LOW]: INSIGHT_STATUS[severityItem[4]]
    ? severityTotal[INSIGHT_STATUS[4]] + severityItem[4]
    : severityTotal[INSIGHT_STATUS_ENUM.LOW],
  [INSIGHT_STATUS_ENUM.INFO]: INSIGHT_STATUS[severityItem[5]]
    ? severityTotal[INSIGHT_STATUS[5]] + severityItem[5]
    : severityTotal[INSIGHT_STATUS_ENUM.INFO]
});

export const groupBy = (list: any[], key: string) => {
  return list.reduce((prev, curr) => {
    return {
      ...prev,
      [curr[key]]: [...(prev[curr[key]] || []), curr]
    };
  }, {});
};

export const toNumbersSuffix = (num: number | string, fixed = 2): { num: number | string; suffix: string } => {
  const numSuffix = {
    num: +roundedNumber(+num, fixed) || 0,
    suffix: ''
  };
  if (num === undefined || num === null) {
    return numSuffix;
  }
  if ((typeof num === 'number' && num > 1_000_000_000) || (typeof num === 'string' && parseFloat(num) > 1_000_000_000)) {
    numSuffix.num = parseFloat(((typeof num === 'number' ? num : parseFloat(num)) / 1_000_000_000).toFixed(fixed));
    numSuffix.suffix = 'B';
    return numSuffix;
  }
  if ((typeof num === 'number' && num > 1_000_000) || (typeof num === 'string' && parseFloat(num) > 1_000_000)) {
    numSuffix.num = parseFloat(((typeof num === 'number' ? num : parseFloat(num)) / 1_000_000).toFixed(fixed));
    numSuffix.suffix = 'M';
    return numSuffix;
  }
  if ((typeof num === 'number' && num > 1_000) || (typeof num === 'string' && parseFloat(num) > 1_000)) {
    numSuffix.num = parseFloat(((typeof num === 'number' ? num : parseFloat(num)) / 1_000).toFixed(fixed));
    numSuffix.suffix = 'K';
    return numSuffix;
  }
  return numSuffix;
};

export const getDuration = (dateStart: string, dateEnd: string, isToObject = false, duration = 0) => {
  const range = (dateStart && dateEnd && new Date(dateEnd).getTime() - new Date(dateStart).getTime()) || duration;
  const toObject = (duration: number, type: string) => (isToObject ? { duration, type } : `${duration} ${type}`);
  if (range < 0) return toObject(0, ' now');
  if (range < 1000) return toObject(Math.floor(range), 'ms');
  if (range / 60000 < 1) return toObject(Math.floor(range / 1000), 'sec');
  if (range / 3600000 < 1) return toObject(Math.floor(range / 60000), 'min');
  return toObject(Math.floor(range / 3600000), 'h');
};

export const validateLogRecords = (logRecords: LogRecord[]): void => {
  if (logRecords?.length) {
    const errors = logRecords.filter((log: LogRecord) => log.route == null || log.statusCode == null || log.queries == null);
    if (errors.length) {
      Sentry.captureException({ errors: JSON.stringify(errors) }, { extra: { ErrorMessage: 'Invalid LogRecords params' } });
    }
  }
};

export const capitalizeFirstLetter = (str: string) => {
  if (str && typeof str === 'string') {
    const strLower = str.toLowerCase();
    return strLower.charAt(0).toUpperCase() + strLower.slice(1);
  }
  return '';
};

export const getNaChartValue = (list: (string | number)[]): any => {
  const chartTooltipValue: any = {};

  if (list.length) {
    list.forEach((value: string | number, index: number) => {
      if (typeof value === 'string' && value?.length === 0) {
        chartTooltipValue[`tooltipValue${index ? index : ''}`] = 'N/A';
        chartTooltipValue[`value${index ? index : ''}`] = 0;
        chartTooltipValue.na = 0;
      }
      else if (value === null || value === undefined) {
        chartTooltipValue[`tooltipValue${index ? index : ''}`] = 'N/A';
        chartTooltipValue[`value${index ? index : ''}`] = 0;
      } else {
        chartTooltipValue[`tooltipValue${index ? index : ''}`] = value;
        chartTooltipValue[`value${index ? index : ''}`] = +value || 0;
      }
    });
  }
  return chartTooltipValue;
};

export const getHighestRole = (roles: any[]): { key: string; level: number; description: string } => {
  const defaultUserRole = {
    key: 'ReadOnly',
    description: 'Read Only',
    level: 500
  };
  if (roles?.length) {
    roles.forEach((role) => {
      if (role?.level < defaultUserRole.level) {
        defaultUserRole.key = role?.key;
        defaultUserRole.level = role?.level;
        defaultUserRole.description = role?.description;
      }
    });
  }

  return defaultUserRole;
};

export const getAverage = (numbers: any[]) => {
  if (numbers.length === 0) {
    return 0;
  }

  const sum = numbers.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const average = sum / numbers.length;

  return average;
};

export const getCPUScore = (avg: number): number => {
  if (avg > 95) return 0;
  else if (avg > 85) return 20;
  else if (avg > 60) return 60;
  else if (avg > 25) return 80;
  return 100;
};
export const getMemoryScore = (avg: number): number => {
  const KB = 1024 ** 2;
  if (avg / KB < 200) return 0;
  else if (avg / KB < 500) return 20;
  else if (avg / KB / 1024 < 1) return 60;
  else if (avg / KB / 1024 < 2) return 80;
  return 100;
};

export const validateConnectionString = (connectionString: string, type: RDBMS) => {
  // Example regex for a simple connection string (adjust as needed)
  // const regex = /^([a-zA-Z]+:\/\/)?[a-zA-Z0-9_]+:[a-zA-Z0-9_]+@[a-zA-Z0-9.-]+\/[a-zA-Z0-9_]+$/;
  // Example regex for a simple connection string without a protocol
  if (type === RDBMS.postgres) {
    const regex = /^(postgresql|sqlserver):\/\/\S+@\S+:\d+\S*$/;
    return regex.test(connectionString);
  } else {
    const regex = /^(mysql|sqlserver):\/\/\S+@\S+:\d+\S*$/;
    return regex.test(connectionString);
  }
};

export const linkTo = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, cb: any) => {
  if (!(event?.button === 1 || event?.button === 2 || event?.metaKey || event?.ctrlKey)) {
    event.preventDefault();
    return cb();
  }
};
export const roundedNumber = (number: number, fixNumber = 2): string => {
  if (!number) return '';
  return number.toFixed(fixNumber).replace(/\.0+$/, '');
};

export const extractApiKeyByHost = (hostId: string | number | undefined, hostsApiKey: HostsApiKeyT): string => {
  return hostId ? hostsApiKey[hostId]?.apiKey || '' : '';
};

export const convertKbToSize = (kb: number): string => {
  if (kb < 0) {
    throw new Error('Size cannot be negative');
  }

  const units = ['KB', 'MB', 'GB'];
  const unitIndex = Math.floor(Math.log(kb) / Math.log(1024));


  if (unitIndex >= units.length) {
    return `${kb.toFixed(2)} ${units[0]}`;
  }

  const size = kb / Math.pow(1024, unitIndex);

  return `${size.toFixed(2)} ${units[unitIndex]}`;
};
