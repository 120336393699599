import { useLocation, useNavigate } from 'react-router-dom';
import { analyticsTrack } from '../utils/segment-analytics';

export default function useNavigateBack() {
  const navigate = useNavigate();
  const { pathname }: any = useLocation();
  return (state?: any) => {
    try {
      analyticsTrack('Navigate back', { from: pathname, state });
      if (state?.navigateTo) navigate(state.navigateTo, { state });
      else {
        const backPath = pathname.slice(0, pathname.lastIndexOf('/'));
        navigate(backPath + '/', { state });
      }
    } catch (e) {
      navigate(-1);
    }
  };
}
