import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function Error() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('error');
  }, []);

  return (
    <main style={{ padding: '1rem 0' }}>
      <h2>Whoops! Looks like you’re lost.</h2>
    </main>
  );
}
