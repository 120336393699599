import { Node, Edge } from '@xyflow/react';

export type SQLEngineType = 'MySQL' | 'PostgreSQL' | 'MSSQL' | 'Oracle';
export type operationType = 'cost' |'hash' | 'nested_loop' | 'merge' | 'sort_merge' | 'table' | 'union' | 'sort' | 'UNKNOWN'
export type SQLExplainNode = Node<SQLExplainNodeData>;
export type SQLExplainEdge = Edge;

export interface SQLExplainNodeData extends Record<string, unknown> {
  label: string;
  details?: {
    estimatedRows?: string | number,
    indexUsage?: string
  },
}

export interface SQLExplainNodeData {
  table_name?: string;
  accessMethod?: string;
  indexUsed?: string;
  estimatedRows?: string;
  filter?: string;
  rowsAfterFilter?: number;
  queryCost?: number | null;
  type?: string;
}

export interface ParseResult {
  nodes: SQLExplainNode[];
  edges: SQLExplainEdge[];
}

export interface ParseContext {
  currentUnionKey: any;
  currentSubQueryKey: any;
  discoveryTree: any;
  currentSubQueryGroupNodeId: string;
  hasSubQuery: boolean;
  subqueryCount: any;
  hasUnion: boolean;
  currentLoopName: string;
  currentLevel: number;
  currentXPosition: number;
  currentYPosition: number;
  latestRowsAfterFilter: number;
  nodeIdCounter: number;
  nodes: SQLExplainNode[];
  edges: SQLExplainEdge[];
  horizontalSpacing: number;
  verticalSpacing: number;
}

export interface NodeResult {
  nodeId: string | null;
  estimatedRows: number | null;
}

export interface NodeDiscoveryResult {
  subqueryCount: number;
  unionCount: number;
  hasNestedLoop: boolean;
  tableNames: Set<string>;
}

export enum SQLOperationType {
  NESTED_LOOP = 'nested_loop',
  TABLE = 'table',
  SORT = 'ordering_operation',
  GROUP_BY ='grouping_operation',
  QUERY_RESULT = 'query_block',
  UNION = 'union_result',
  SUBQUERY = 'materialized_from_subquery'
}
  