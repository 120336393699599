import styled from 'styled-components';

import { styled as MuiStyle } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Flex from '../../core/Flex';
import { TableContainer } from '@mui/material';
import Typography from '../../core/Typography';

export const CelWrapper = MuiStyle(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.primary.contrastText
  }
}));

export const ErrorItem = MuiStyle('div')(({ theme }) => ({
  color: theme.palette.error.main
}));
export const Cell = MuiStyle(TableCell)(({ theme }) => ({
  height: '34px',
  maxWidth: '250px',
  overflowWrap: 'break-word',
  backgroundColor: theme.palette.background.default,
  color: theme.palette.grey[500],
  fontSize: '14px',
  fontWeight: '500'
}));

export const EmptyState = styled(Flex)`
  height: 100px;
  text-align: center;
  color: ${({ theme }) => theme.colors.text.title};
`;

export const Container = styled(TableContainer)<{ head_height?: string }>`
  max-height: ${({ head_height }: any) => (head_height ? `calc(100vh - ${head_height})` : 'calc(100vh - 270px)')};
  border-radius: 2px;
  margin: 6px 0;
  width: inherit;
  border: ${({ theme }) => `1px solid ${theme.colors.grey[150]}`};
`;

export const IconMenu = MuiStyle('img')({
  maxWidth: 'none',
  maxHeight: 'none',
  cursor: 'pointer'
});

export const FilterCell = styled(Typography)`
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
