import styled, { css } from 'styled-components';

interface IFlex {
  direction?: 'row' | 'column';
  align?: string;
  justify?: string;
  margin?: string;
  fw?: boolean; // full width
  fh?: boolean; // full height
}

const StyledFlex = styled.div<IFlex>`
  display: flex;
  flex-direction: ${(props) => props?.direction || 'row'};
  align-items: ${(props) => props?.align || 'center'};
  justify-content: ${(props) => props?.justify || 'center'};
  margin: ${(props) => props?.margin || 0};
  ${({ fw }) =>
    fw &&
    css`
      width: 100%;
    `}
  ${({ fh }) =>
    fh &&
    css`
      height: 100%;
    `}
`;

function Flex(props: any) {
  return <StyledFlex {...props} />;
}

export default Flex;
