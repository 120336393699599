import styled from 'styled-components';
import Flex from '../../core/Flex';
import Typography from '../../core/Typography';

export const InvestigationDetailContainer = styled(Flex)`
  &:first-child {
    margin-top: 0px;
    display: block;
  }
  &:last-child {
    margin-bottom: 5px;
  }
  margin-top: 4px;
  width: calc(100% - 56px);
  margin-left: 16px;
  border-bottom: ${({ theme, hideLine }) => !hideLine && `1px solid ${theme.colors.grey[150]}`};
`;

export const Details = styled(Typography)`
  width: inherit;
  color: ${({ theme }) => theme.colors.text.main};
`;

export const DetailsWrapper = styled.div`
  width: 100%;
`;
export const FlexFullWrapper = styled(Flex)`
  width: 100%;
`;
export const IconWrapper = styled(Flex)`
  padding: 5px;
  margin-right: 6px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.new.primary[150]};
`;
