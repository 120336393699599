import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import * as Sentry from '@sentry/react';

import Select from '../Select/Select';
import Flex from '../../core/Flex';
import Button from '../../core/Button/Button';
import { createRestClient } from '../../common/rest-client';
import { useMutation, useQuery } from 'react-query';
import { SnackbarStateI, snackbarState } from '../../atoms/snackbar.atom';
import { SnackbarSeverityE } from '../../enums/snackbar-severity.enum';
import { userSettingsAtom } from '../../atoms/user.settings.atom';
import { analyticsTrack } from '../../utils/segment-analytics';
import Typography from '../../core/Typography';
import { isDemo } from '../../common/consts';
import MTooltip from '../../core/Tooltip/Tooltip';
import {apiKeysState, convertApiIdToApiKey} from "../../atoms/api-keys.atom";

interface DbEnrichmentSettingProps {
  selectedApiKey: string;
  handleSelectedDb?: (hostId: number, databaseId: number) => void;
}

const DbEnrichmentSetting = ({ selectedApiKey, handleSelectedDb }: DbEnrichmentSettingProps) => {
  const restClient = createRestClient();
  const [selectedHost, setSelectedHost] = useState(0);
  const [selectedDatabase, setSelectedDatabase] = useState(0);
  const settings = useRecoilValue(userSettingsAtom);
  const {apiKeys} = useRecoilValue(apiKeysState);

  const setSnackbarS = useSetRecoilState(snackbarState);
  const DEFAULT_PORT = '5432';

  const { data: hostList = [], isLoading: isHostListLoading }: any = useQuery(
    ['hosts/by-api-key', settings?.tenantId],
    (): Promise<any> =>
      restClient
        .get('hosts/by-api-key', {
            headers: { 'x-api-key': convertApiIdToApiKey(selectedApiKey, apiKeys) }
        })
        .json(),
    {
      enabled: !!settings?.tenantId,
      onError: (err: any) => {
        analyticsTrack('Error Fetch Monitoring Hosts', { tenantId: settings.tenantId, err });
      },
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
      if (hostList && hostList.length && !isHostListLoading) {
          const defaultHost = hostList[0];
          const hostId = defaultHost.host_id;
          const dbId = defaultHost.db_names[0].id;
          setSelectedHost(hostId);
          setSelectedDatabase(dbId);
      }
  }, [hostList, isHostListLoading]);
  const setHostValue = (host: number) => {
    setSelectedHost(host);
    setSelectedDatabase(0);
  };

  const filteredHostList = useMemo(
    () => hostList.map((h: any, i: number) => ({ id: i, name: h?.host_name, val: h?.host_id })),
    [hostList.length, selectedHost]
  );
  const selectedHostRes = hostList?.find((host: any) => host?.host_id === selectedHost) || { db_names: [] };
  const handleViewDb = () => {
    if (handleSelectedDb && selectedHostRes?.db_names?.length) {
        handleSelectedDb(selectedHost, selectedDatabase);
    }
  };
  const isDisableCTA: boolean = selectedDatabase === 0;
  const useUpdatePMC = () => {
    const pmc = selectedHostRes.db_names?.find((p: any) => p.db_name === selectedDatabase);
    return useMutation(
      () =>
        restClient
          .post('pmc-device/preferences', {
            json: { db_name: selectedDatabase, db_host: selectedHost, port: pmc?.port || DEFAULT_PORT },
            headers: { 'x-api-key': convertApiIdToApiKey(pmc?.api_key || selectedApiKey, apiKeys)}
          })
          .json(),
      {
        onSuccess: () => {
          setSnackbarS((oldSnackbarState: SnackbarStateI) => ({
            ...oldSnackbarState,
            open: true,
            severity: SnackbarSeverityE.success,
            text: 'The host and database used for Production Enrichment were updated successfully.'
          }));
        },
        onError: (err: any) => {
          console.log('Error', err);
          Sentry.captureException(new Error(`Error-${err?.message}`), { extra: { ErrorMessage: "couldn't update PMC" } });
        }
      }
    );
  };
  const { mutate } = useUpdatePMC();

  return (
    <Flex justify="start">
      <Flex margin="12px 6px">
        <Select
          minWidth={'440px'}
          changeValueOnPress={true}
          name={'Host'}
          list={filteredHostList}
          value={selectedHost}
          onSelect={setHostValue}
          disabled={!filteredHostList?.length}
        />
      </Flex>
      <Flex margin="8px">
        <Select
          changeValueOnPress={true}
          name={'Database'}
          list={selectedHostRes?.db_names?.map((d: any, i: number) => ({ id: i, name: d?.name, val: d?.id })) || []}
          value={selectedDatabase}
          onSelect={setSelectedDatabase}
          disabled={!selectedHost || selectedHost ===0}
        />
      </Flex>

      <Flex margin="0 16px 0 8px">
        <MTooltip title="Demo user can't make changes in the web app." disable={!isDemo}>
          <Button variant="secondary" onClick={mutate} disabled={isDisableCTA || isDemo}>
            <Typography weight="500">Save</Typography>
          </Button>
        </MTooltip>
      </Flex>
      <Button onClick={handleViewDb} disabled={!(selectedDatabase && filteredHostList.length)}>
        <Typography weight="500">View Data</Typography>
      </Button>
    </Flex>
  );
};

export default DbEnrichmentSetting;
