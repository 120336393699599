//Note: not create function for investigtion cause dont know what is the actaul text yet.

import TableItem from '../../../components/Table/TableItem';
import SafeInnerHtml from '../../SafeInnerHtml';
import { wrapNumbersInBold } from '../utils';

export const AutomatedInvestigationOfSSAFK00002 = (assertion: any, tableItem: any) => ({
  investigation: (
    <SafeInnerHtml
      unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
    />
  ),
  impact: (
    <>
      Usually, the query joins the tables using the PK and FK. If a query involves joining two or more tables on columns with
      mismatched data types, the database may need to perform additional type conversions in order to match the data types of the
      columns being joined. This can slow down the query significantly, especially if the tables being joined are large.
    </>
  ),
  remediation: <>Use the same data type(s) for the PK and FK</>
});

export const AutomatedInvestigationOfSSAFK00001 = (assertion: any, tableItem: any) => ({
  investigation: (
    <SafeInnerHtml
      unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
    />
  ),
  impact: (
    <>
      Performance - A foreign key is usually used to join data with the related primary key table. Without an index, the Query
      database must perform a full table scan of the related table in order to locate the rows that match the foreign key values
      in the original table. This can be very slow, especially for large tables.{' '}
    </>
  ),
  remediation: <>Use the same data type(s) for the PK and FK</>
});

export const RULE_SCHEMA_IDX_02_AI = (assertion: any, tableItem: any) => ({
  investigation: (
    <SafeInnerHtml
      unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
    />
  ),
  impact: (
    <>
      Slow Data Retrieval: Without an index, PostgreSQL has to scan the entire table to retrieve the requested data. This can
      result in slower query performance, especially when dealing with larger datasets. High CPU and Memory Usage: When performing
      table scans, PostgreSQL uses more CPU and memory resources, which can impact the overall performance of the system. Reduced
      Concurrency: Without an index, table locks can be held for a longer period, resulting in reduced concurrency, which can
      impact the scalability of the system.{' '}
    </>
  ),
  remediation: (
    <>
      Create the appropriate indexes on the frequently queried columns and consider partitioning large tables to reduce data
      retrieval times.
    </>
  )
});

export const AutomatedInvestigationOfTBL0002 = (assertion: any, tableItem: any) => ({
  investigation: (
    <SafeInnerHtml
      unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
    />
  ),
  impact: (
    <>
      Increased Storage Requirements: Duplicate indexes result in redundant data, which increases storage requirements and can
      lead to wastage of disk space. Reduced Query Performance: Duplicate indexes can negatively impact query performance, as the
      PostgreSQL query optimizer may choose an inefficient index to execute a query. Slow Data Modification: Insert, update, and
      delete operations on a table with duplicate indexes can be slower due to the overhead of maintaining multiple indexes.{' '}
    </>
  ),
  remediation: (
    <>
      Create the appropriate indexes on the frequently queried columns and consider partitioning large tables to reduce data
      retrieval times.
    </>
  )
});

export const RULE_SCHEMA_IDX_01_AI = (assertion: any, tableItem: any) => {
    const numOfIndexes = tableItem?.table_insights?.metrics?.find((metric) => metric?.metric_name === 'num-of-indexes')?.value;

    if (numOfIndexes === 0) {
        return {
            investigation: (
                <>
                    The table {tableItem.schema_name}.{tableItem.table_name} has no indexes
                </>
            ),
            impact: (
                <>
                    <ul>
                        <li><b>Slow Query Performance:</b></li> Without indexes, the database must perform full table scans for queries, which can be time-consuming, especially for large tables. This significantly slows down data retrieval operations.
                        <li><b>High Resource Consumption:</b></li> Full table scans consume more CPU and memory resources, leading to increased load on the database server and potential performance degradation for other operations.
                    </ul>
                </>
            ),
            remediation: (
                <>
                    <b>Identify Frequently Queried Columns</b>
                    <br/>
                    Analyze query patterns to determine which columns are frequently used in WHERE clauses, JOIN conditions, ORDER BY, and GROUP BY clauses. These columns are prime candidates for indexing. Use the widget “Top Queries”.
                    <br/>
                    <b>Create Indexes on Identified Columns</b>
                    <br/>
                    Based on the analysis, create indexes on the identified columns. The Query insights should guide these recommendations.
                </>
            )
        }
    }

    return {
        investigation: (
            <>
                <SafeInnerHtml
                    unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
                />
                {tableItem?.table_details?.indexes && tableItem?.table_details?.indexes.length > 0 && (
                    <TableItem
                        headHeight={'320px'}
                        data={
                            tableItem?.table_details?.indexes.map((index: {
                                name: string;
                                relatedColumns: any;
                                relatedExpressions: any
                            }) => ({
                                Name: index.name,
                                ['Related Columns']:
                                    index?.relatedColumns?.length > 0
                                        ? index.relatedColumns.map((relatedColumn: any) => relatedColumn?.name).join(', ')
                                        : 'No Related Columns',
                                ['Related Expressions']:
                                    index?.relatedExpressions?.length > 0
                                        ? index.relatedExpressions.map((relatedExpression: any) => relatedExpression?.name).join(', ')
                                        : 'No Related Expressions'
                            })) || []
                        }
                    />
                )}
            </>
        ),
        impact: (
            <>
                High number of indexes can slow down write operations and use more disk space. Unused indexes still
                consume disk space and
                may not improve performance. Queries may not always use indexes, leading to slower performance.{' '}
            </>
        ),
        remediation: (
            <>
                Remove unnecessary or duplicate indexes. Analyze and optimize queries to reduce reliance on indexes. Use
                partial indexes or
                index-only scans where appropriate to reduce disk space usage.
            </>
        )
    };
}

export const AutomatedInvestigationOfTBLPK0009 = (assertion: any, tableItem: any) => ({
  investigation: (
    <SafeInnerHtml
      unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
    />
  ),
  impact: (
    <>
      Indexing: PostgreSQL does not natively support indexing on JSON data, which means that querying and sorting large datasets
      can be slower and less efficient. In contrast, traditional data types like integers and strings can be indexed for faster
      access. Data Integrity: JSON data is not a primitive data type, and as such, it can be harder to ensure data integrity when
      using JSON as the primary key. The JSON data may contain nested arrays or objects that can make it harder to validate the
      data. Data Size: Storing JSON data as the primary key can lead to an increase in data size, which can impact performance,
      especially in systems that have a high volume of transactions. Complexity: Storing JSON data as a primary key can make
      queries and updates more complex than working with traditional data types.
    </>
  ),
  remediation: (
    <>
      Use a separate column to store the primary key and use the JSON data as a separate column in the table. Alternatively, you
      can use a hash of the JSON data as the primary key, which can help with indexing and querying performance. Additionally, you
      may consider using a NoSQL database like MongoDB, which is designed to work with JSON data natively
    </>
  )
});

export const RULE_SCHEMA_PK_01_AI = (assertion: any, tableItem: any) => {
    return {
        investigation: (
            <SafeInnerHtml
                unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
            />
        ),
        impact: (
            <>
                <ul>
                    <li><b>Uniqueness and Integrity:</b></li>
                    Without a primary key, duplicate rows can exist, leading to data integrity issues.
                    <li><b>Efficient Data Retrieval:</b></li> Many database operations, such as indexing and joins, are
                    optimized based on the presence of a primary key. Without it, these operations can become less
                    efficient, leading to slower query performance.
                    <li><b>Referential Integrity:</b></li> Primary keys are often used to establish relationships
                    between tables. Without a primary key, it becomes difficult to create foreign key constraints,
                    weakening the relational structure of the database.{' '}
                </ul>
            </>
        ),
        remediation: (
            <>
                <b>Identify a Unique Column or Set of Columns</b><br/>
                Analyze the table's data to find a column or a combination of columns that uniquely identifies each row.<br/>
                If no such column(s) exist, consider adding a new column that can serve as a unique
                identifier.<br/><br/>
                <b>Alter the Table to Add a Primary Key</b> {' '}
                <ul>
                    <li>Once you have identified or created a suitable column, you can modify the table schema to add
                        the
                        primary key. Here’s how you can do it in SQL:<br/>
                        <b>Example 1: Adding a Primary Key to an Existing Column</b>
                        <div style={{
                            backgroundColor: '#f4f4f4',
                            padding: '10px',
                            borderRadius: '5px',
                            border: '1px solid #ddd'
                        }}>
                            <code>ALTER TABLE {tableItem.table_name} ADD CONSTRAINT pk_{tableItem.table_name} PRIMARY KEY
                                (unique_column);</code></div>
                    </li>
                </ul>
            </>
        )
    }
};

export const RULE_SCHEMA_PK_02_AI = (assertion: any, tableItem: any) => {
    return {
        investigation: (
            <SafeInnerHtml
                unsafeInnerHtmlText={`A Primary Key of a table should have 1-2 columns. ${wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}`}
            />
        ),
        impact: (
            <>
                <ul>
                    <li><b>Increased complexity:</b></li>
                    A multi-column PK makes queries and joins more complex, as you need to reference all columns when
                    linking tables.
                    <li><b>Reduced performance:</b></li> Indexes on composite keys with many columns are larger and
                    slower to search, potentially impacting query speed.
                </ul>
            </>
        ),
        remediation: (
            <>
                <ul>
                    <li>Introduce a surrogate key: Add a new single-column identifier (e.g., auto-incrementing
                        integer).
                    </li>
                    <li>Designate the surrogate key as the new primary key.</li>
                    <li>Create a unique constraint or index on the original multi-column key.</li>
                    <li>Update foreign key relationships to reference the new surrogate key.</li>
                </ul>
            </>
        )
    }
};

export const RULE_SCHEMA_FK_01_AI = (assertion: any, tableItem: any) => {
    const columns = tableItem.table_insights.metrics.find(metric => metric.metric_name === 'extend-columns' && metric.label === 'columns').value;
    const fkColumnsWithoutIndex = columns.filter(column => column.isForeignKey && !column.indexes?.length);

    const fkNames = fkColumnsWithoutIndex.map(column => column.name).map((column) => {
        return tableItem.table_insights.metrics.find(metric => metric.metric_name === 'fk-data').value.find(fk => fk.columns.find((fkColumn) => fkColumn.name === column)).name
    });
    return {
        investigation: (
            <> The Foreign Keys: {fkNames.join(', ')} Don't Have an Index</>
        ),
        impact: <>
            <ul>
                <li><b>Slow Queries:</b>Database operations involving the foreign key, such as joins, lookups, and updates, will be slower, especially as the table grows in size.</li>

                <li><b>Inefficient Joins:</b>Queries that join tables on the foreign key will require full table scans instead of efficient index lookups, leading to longer execution times.</li>

                <li><b>Delayed Inserts and Deletes:</b>Without an index, enforcing referential integrity during insert and delete operations becomes more time-consuming, as the database must perform full scans to check for valid references.</li>

                <li><b>Increased Lock Contention:</b>Longer-running queries can lead to increased lock contention, where multiple operations compete for access to the same data, causing delays and potential deadlocks.</li>
            </ul>
            </>,
        remediation: (
            <>
                Create Missing Indexes:
                <br/>
                <pre>
                {fkNames.map((fkName, idx: number) => {
                    return <>
                            <code>CREATE INDEX {fkName} ON {tableItem.table_name} ({fkColumnsWithoutIndex?.[idx]?.name});</code>
                        </>
                })}
                </pre>
            </>
        )
    };
};

export const RULE_SCHEMA_IDX_03_AI = (assertion: any, tableItem: any) => {
    return {
      investigation: (
        // <SafeInnerHtml
        //   unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
        // />
        <>
        The following indexes use the same columns in the same order, making some of them redundant:

        <TableItem
        headHeight={'320px'}
        data={
          Object.values(tableItem)?.map((indexes, idx) => {
              const columnsOrExpression = (indexes as any[])?.some(index => index?.relatedColumns?.length)
                  ? 'relatedColumns' : 'relatedExpressions';
              let columnOrExpressionsValue = '';
              if (columnsOrExpression === 'relatedExpressions') {
                  columnOrExpressionsValue = (indexes as any[])?.[idx]?.[columnsOrExpression]?.join(', ')
              }
              else if (columnsOrExpression === 'relatedColumns') {
                    columnOrExpressionsValue = [...new Set((indexes as any[]).map(index => index?.relatedColumns?.map(column => column?.name)).flat())].join(', ')
              }

              return {
            ['Index Name']: (indexes as any[])?.[0]?.name,
            ['Same As']: (indexes as any[])?.filter((el, idx) => idx !== 0).map(el => el?.name).join(','),
            ['Columns or Expressions']: columnOrExpressionsValue
          }  || [] })
        }
      />
      </>
      ),
      impact: (
        <>
              <h3>Issues with Duplicate Indexes</h3>
        <ul>
            <li>
                <strong>Wasted Storage Space:</strong> Duplicate indexes consume additional storage space without providing any benefit, leading to inefficient use of disk resources.
            </li>
            <li>
                <strong>Reduced Performance:</strong> Maintaining duplicate indexes adds unnecessary overhead during insert, update, and delete operations, potentially reducing overall database performance.
            </li>
            <li>
                <strong>Increased Maintenance:</strong> Regular maintenance tasks like vacuuming and reindexing take longer and require more resources due to the additional indexes.
            </li>
        </ul>
        </>
      ),
      remediation: (
        <>
        <ol>
        <li>
            <strong>Drop one of the Redundant Indexes:</strong> Use the <code>DROP INDEX</code> command to remove the duplicate indexes. The script deletes the first index.
            <pre><code>DROP INDEX IF EXISTS index_name;</code></pre>
        </li>
        <li>
            <strong>Verify Index Usage:</strong> Ensure the remaining indexes are used efficiently using the Monitoring page.
        </li>
    </ol></>
      )
    };
}

export const RULE_01_SCHEMA_TBL_AI = (assertion: any, tableItem: any) => ({
  investigation: (
    // <>
    //   In PostgreSQL, a wide table refers to a table with many columns, which can lead to performance issues and difficulties in
    //   managing the table.
    // </>
    <SafeInnerHtml
      unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
    />
  ),
  impact: (
    <>
      Wide tables can negatively impact query performance, especially if the table has a lot of null values or if the table is
      frequently updated.
    </>
  ),
  remediation: (
    <>
      To avoid these issues, it's recommended to normalize the table by breaking it down into smaller tables with fewer columns
      and establishing relationships between them. This can improve performance, simplify management, and reduce storage
      requirements.
    </>
  )
});

export const RULE_01_SCHEMA_TGR_AI = (assertion: any, tableItem: any) => ({
  investigation: (
    <SafeInnerHtml
      unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
    />
    // <> In PostgreSQL, a trigger is a database object that automatically executes a function in response to certain events.</>
  ),
  impact: (
    <>
      A high number of triggers can negatively impact database performance, especially if the triggers are complex or execute
      frequently.
    </>
  ),
  remediation: (
    <>
      To avoid these issues, it's recommended to periodically review and optimize the number of triggers in the database by
      removing any redundant or unnecessary triggers. Additionally, simplifying the logic of triggers and reducing their frequency
      can help improve overall database performance.
    </>
  )
});

export const RULE_SCHEMA_PK_03_AI = (assertion: any, tableItem: any) => ({
  investigation: (
    <>
      <SafeInnerHtml
        unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
      />

      <div>{tableItem?.table_details?.pkData?.name}</div>
      {tableItem?.table_details?.pkData?.columns && tableItem?.table_details?.pkData?.columns.length > 0 && (
        <TableItem
          headHeight={'320px'}
          data={
            tableItem?.table_details?.pkData?.columns.map((column: any) => ({
              Name: column.name,
              ['Data Type']: column.dataType
            })) || []
          }
        />
      )}
    </>
  ),
  impact: (
    <>
      A high primary key size in bytes can impact query performance, especially if the primary key is used as a foreign key in
      other tables.
    </>
  ),
  remediation: (
    <>
      To avoid these issues, it's recommended to use smaller data types for primary keys whenever possible. For example, using a
      serial data type instead of a bigint can significantly reduce the size of the primary key. Additionally, it's important to
      ensure that the primary key is optimized for the query workload of the database.
    </>
  )
});

export const RULE_SCHEMA_PK_04_AI = (assertion: any, tableItem: any) => ({
  investigation: (
    <SafeInnerHtml
      unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
    />
  ),
  impact: (
    <>
    Primary Key values are typically generated automatically. However, if a newly generated value exceeds the maximum supported value for the data type, the table becomes unable to accommodate new records.
    </>
  ),
  remediation: (
    <>
     Change the datatype to one that supports larger numbers. For ex. if the primary key uses <code>int</code> but the table has more than 2B records, change to <code>bigint</code>.
    </>
  )
});

export const RULE_SCHEMA_FK_02_AI = (assertion: any, tableItem: any) => {

    const fkData = tableItem?.table_insights?.metrics?.find((metric: any) => metric?.metric_name === 'fk-data')?.value || [];
    return {
        investigation: (
            <>
                <SafeInnerHtml
                    unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
                />
                {fkData && fkData.length > 0 && (
                    <TableItem
                        headHeight={'320px'}
                        data={
                            fkData.map((foreignKeyData: any) => {
                                const columns =  foreignKeyData?.columns?.map((column: any) => `${column?.name}(${column?.dataType})`).join(', ');
                                const referencedTable = foreignKeyData?.referencedColumns?.[0]?.fullCatalogName?.split('.').at(-2);
                                const referencedColumns = foreignKeyData?.referencedColumns
                                        ?.map((column: any) => `${referencedTable}.${column?.name}(${column?.dataType})`)
                                        .join(', ');
                                const foreignKeyName = foreignKeyData?.name;
                                return {
                                    ['Columns']: columns,
                                    ['Referenced Columns']: referencedColumns,
                                    ['FK Name']: foreignKeyName,
                                }}) || []
                        }
                    />
                )}
            </>
        ),
        impact: (
            <>
                Performance: Matching data types between primary and foreign keys prevents the need for data type
                conversions during query
                execution. This avoids potential inefficiencies caused by converting data types and ensures that indexes
                can be effectively
                utilized.
            </>
        ),
        remediation: <>Change the data types of the Foreign Key to be exactly as the Primary Key's data types.</>
    }
}
export const RULE_04_SCHEMA_PK_AI = (assertion: any, tableItem: any) => ({
  investigation: (
    <SafeInnerHtml
      unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
    />
  ),
  impact: (
    <>
      A high number of columns in the primary key can negatively impact query performance, especially if the key is used as a
      foreign key in other tables.
    </>
  ),
  remediation: (
    <>
      To avoid these issues, it's recommended to use the minimum number of columns required to uniquely identify each row in the
      table. Additionally, it's important to ensure that the primary key is optimized for the query workload of the database.
    </>
  )
});

export const RULE_SCHEMA_STAT_03 = (assertion: any, tableItem: any) => ({
  investigation: (
    <SafeInnerHtml
      unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
    />
  ),
  impact: (
    <>
      <ul>
        <li>
          {' '}
          <b>Performance - Dead rows can make queries slower </b>. When a query is executed, the database must scan all of the
          rows in the table, including the dead rows. This can significantly slow down the query, especially if the table is
          large.
        </li>

        <li>
          {' '}
          <b>Storage - Dead rows take up space</b>. Dead rows are rows that have been deleted from a table, but the space they
          occupied has not been reused. This can lead to wasted space on disk, which can make the database slower and less
          efficient.
        </li>

        <li>
          <b>Storage - Dead rows can make backups larger</b>. When a backup of a PostgreSQL database is created, all of the rows
          in the table, including the dead rows, are copied to the backup file. This can make the backup file larger and take
          longer to create.
        </li>
      </ul>
    </>
  ),
  remediation: (
    <>
      <ul>
        <li>
          <b>Use the VACUUM command to clean up dead rows.</b> The VACUUM command will remove dead rows from the table and reclaim
          the space they occupied.
        </li>

        <li>
          <b> Set the AUTOVACUUM configuration parameter to a value that will regularly clean up dead rows.</b> The AUTOVACUUM
          parameter tells the database how often to run the VACUUM command automatically.
        </li>
      </ul>
    </>
  )
});

export const RULE_SCHEMA_STAT_01 = (assertion: any, tableItem: any, tableName?: string, schemaName?: string) => {
    const schemaTableName = (!tableName || !schemaName) ? 'table-name' : `${schemaName}.${tableName}`;

    const lastAnalyzeData = tableItem?.table_insights?.metrics.find((metric: any) => metric?.metric_name === 'last_analyze_date')?.value || null;
    const lastAutoAnalyzeData = tableItem?.table_insights?.metrics.find((metric: any) => metric?.metric_name === 'last_autoanalyze_date')?.value || null;

    return {
      investigation: (
        <>
          {lastAnalyzeData !== null ||
            (lastAutoAnalyzeData !== null && (
              <>
                The table has statistics. The last time the statistics run was{' '}
                {lastAnalyzeData || lastAutoAnalyzeData}. The statistics were
                created {lastAutoAnalyzeData ? 'automatically' : 'manually'}
              </>
            ))}
          {lastAnalyzeData === null && lastAutoAnalyzeData === null && (
            <>The table {schemaTableName} does not have any statistics.
                Normally, the database server manages statistics using the auto analyze configuration.
                However, in the case of large tables, statistics may need to be created manually to ensure optimal query performance.
            </>
          )}
        </>
      ),
      impact: (
          <>
              <ul>
              <li><b>Suboptimal Query Performance:</b> Without statistics, the query planner cannot make informed decisions,
                  leading to inefficient query plans and slower query performance.</li>
              <li><b>Increased Resource Usage:</b> Poor query plans can result in higher CPU, memory, and I/O usage,
                  affecting overall database performance and potentially impacting other operations.</li>
              <li><b>Inconsistent Query Results:</b> In the absence of accurate statistics, the database may produce varying
                  query performance, causing unpredictability in application response times.</li>
              </ul>
          </>
      ),
        remediation: (
        <>
            <ul>
                <li>
                    <b>Verify Missing Statistics:</b> Confirm that the table table_name indeed lacks statistics by
                    checking the system catalog.
                    <div style={{
                        backgroundColor: '#f4f4f4',
                        padding: '10px',
                        borderRadius: '5px',
                        border: '1px solid #ddd'
                    }}>
                        <code>SELECT schemaname, relname, last_autoanalyze, last_analyze
                            FROM pg_stat_all_tables
                            WHERE schemaname NOT IN ('pg_toast', 'pg_catalog', 'information_schema');</code></div>
                </li>
                <li>
                    <b>Manually Analyze the Table:</b> Use the ANALYZE command to manually generate statistics for the table.
                        checking the system catalog.
                    <div style={{
                        backgroundColor: '#f4f4f4',
                        padding: '10px',
                        borderRadius: '5px',
                        border: '1px solid #ddd'
                    }}>
                        <code>ANALYZE {schemaTableName};</code></div>
                </li>
            </ul>
        </>
        )
    };
};


export const RULE_SCHEMA_STRUCTURE_01 = (assertion: any, tableItem: any) => ({
    investigation: (
        <SafeInnerHtml
            unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
        />
    ),
    impact: (
        <>
            The use of JSON/JSONB columns alongside regular columns can increase complexity, limit indexing
            capabilities, and compromise
            data integrity due to the lack of strict schema enforcement.
    </>
  ),
  remediation: (
    <>
      Consider normalizing the schema by extracting relevant attributes into regular columns, validate JSON data to ensure
      integrity, optimize indexing strategies, and carefully evaluate the necessity of using JSON/JSONB columns in the first
      place.
    </>
  )
});
