import { Container, Content, HeaderContainer, Title } from './InsightDetailsCard.styled';
import { SeverityLabel } from '../SeverityLabel/SeverityLabel';
import { Assertion } from '../../types/Assertion.type';
import MTooltip from '../../core/Tooltip/Tooltip';
import Typography from '../../core/Typography';

interface Props {
  assertionDetails: Assertion | any;
  callback?: any;
  index?: number;
  choosen: boolean;
  isSidebar?: boolean;
}

const InsightDetailsCard = ({ assertionDetails, callback, choosen, index, isSidebar }: Props) => {
  return (
    <Container
      onClick={() => callback({ idx: index, ...assertionDetails })}
      align={'start'}
      choosen={choosen}
      justify={'space-evenly'}
      direction={'column'}
      key={assertionDetails?.title}
    >
      <HeaderContainer justify="start">
        <SeverityLabel priority={assertionDetails?.condition?.event?.priority || assertionDetails?.priority} />
        <MTooltip disable={assertionDetails.title?.length < 30} title={<Typography>{assertionDetails?.title}</Typography>}>
          <Title weight={500} isSidebar={isSidebar}>
            {assertionDetails?.title}
          </Title>
        </MTooltip>
      </HeaderContainer>
      {!assertionDetails?.ruleId?.includes('SQL') && <Content weight={400}>{assertionDetails?.ruleResult}</Content>}
    </Container>
  );
};

export default InsightDetailsCard;
