import { Skeleton } from '@mui/material';
import Flex from '../../core/Flex';
import CopyToClipBoard from '../Buttons/CopyToClipBoard';
import { Label } from '../Ruler/Ruler.styled';
import {
  Details,
  DetailsWrapper,
  FlexFullWrapper,
  IconWrapper,
  InvestigationDetailContainer
} from './InvestigationDetail.styled';
import Typography from '../../core/Typography';

export interface InvestigationDetailProps {
  title: string;
  details: any;
  icon: any;
  hideLine?: boolean;
  copyDetails?: string[];
  isLoading?: boolean;
}

export const InvestigationDetail = ({
  title,
  details,
  icon,
  hideLine = false,
  copyDetails = [],
  isLoading
}: InvestigationDetailProps) => {
  return (
    <InvestigationDetailContainer justify={'flex-start'} hideLine={hideLine}>
      <FlexFullWrapper justify="start" align="start" margin="8px 0">
        <IconWrapper>{icon}</IconWrapper>
        <FlexFullWrapper direction="column" align="start">
          <Label weight={600} h4>
            {title}
          </Label>

          {details ? (
            <DetailsWrapper>
              <Details>{details}</Details>
            </DetailsWrapper>
          ) : isLoading ? (
            <Skeleton animation="wave" variant="rectangular" width={'100%'} height={'12vh'} />
          ) : (
            <Flex justify={'start'} margin="0 0 0 24px">
              <Typography weight={600}>No data to show</Typography>
            </Flex>
          )}
          {!!copyDetails.length &&
            copyDetails.map((d: string, i: number) => (
              <Flex key={d}>
                <Details> {i + 1 + ' : '}</Details>
                <code>
                  <Details>{d}</Details>
                </code>
                <CopyToClipBoard data={d} />
              </Flex>
            ))}
        </FlexFullWrapper>
      </FlexFullWrapper>
    </InvestigationDetailContainer>
  );
};
