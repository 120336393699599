import Drawer from '@mui/material/Drawer';

export type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface DrawerI {
  anchor: Anchor;
  open: boolean;
  onClose?: any;
  modalProps?: any;
  variant?: 'permanent' | 'persistent' | 'temporary' | undefined;
  children?: React.ReactNode;
}

const MDrawer = ({ anchor, open, children, onClose, modalProps, variant }: DrawerI) => {
  return (
    <Drawer
      ModalProps={modalProps}
      variant={variant}
      anchor={anchor}
      open={open}
      onClose={onClose ? toggleDrawer(onClose) : undefined}
    >
      {children}
    </Drawer>
  );
};

export default MDrawer;

const toggleDrawer = (cb: () => void) => (event: React.KeyboardEvent | React.MouseEvent) => {
  if (
    event &&
    event.type === 'keydown' &&
    ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
  ) {
    return;
  }
  cb();
};
