import {useQuery} from 'react-query';
import {ColDef} from 'ag-grid-community';
import Breadcrumbs from '../../core/Breadcrumbs/Breadcrumbs';
import { useSegmentPage } from '../../utils/segment-analytics';
import Select from "../../components/Select/Select";
import { ReactComponent as DBI } from '@icons/db.svg';
import { RestClientContext } from "../../auth/RestClientAuthProvider";
import {Button, ListItemText, SpeedDialIcon, Tab, Tabs} from "@mui/material";
import { ReactComponent as CPUIcon } from '@icons/cpu.svg';
import {
  getMonitoringHostAPI,
  getConfigCompareAPI,
  getDbSizeCompareApi,
  getHardwareCompareAPI,
  getIndexesCompareAPI,
  getQueriesCompareAPI,
  getTablesCompareAPI
} from "../Monitoring/MonitoringAPI";
import { userSettingsAtom } from "../../atoms/user.settings.atom";
import { useRecoilValue } from "recoil";
import { BarIcon, DataGridUI, HistoryIcon, SettingsIcon } from "../Reports/Reports.styled";
import {FilterContainer, FilterItem, InfoContainer, TabContainer, TableContainer} from "./ComparePage.styled";
import {DescriptionIcon} from "../Alerts/Alerts.styled";
import {CardIcon} from "../Boarding/Boarding.styled";
import {numberWithCommas} from "../../utils/utils";
import {useContext, useState} from "react";

interface ConfigComparison {
  type: string;
  instructions: {
    title: string;
    requiredKeys: {
      property: string;
      host1: string;
      host2: string;
    };
    data: {
      property: string;
      host1: string;
      host2: string;
    }[];
  };
}

const ComparePage = () => {
  useSegmentPage('Metis Compare');
  const EmptyIcon = () => <></>;
  const restClient = useContext(RestClientContext);
  const [baseHost, setBaseHost] = useState<string>('');
  const [baseHostId, setBaseHostId] = useState<number>(-1);
  const [comparedHost, setComparedHost] = useState<string>('');
  const [comparedHostId, setComparedHostId] = useState<number>(-1);
  const [configComparison, setConfigComparison] = useState<ConfigComparison | null>(null);
  const [hwComparison, setHwComparison] = useState<ConfigComparison | null>(null);
  const [dbComparison, setDbComparison] = useState<ConfigComparison | null>(null);
  const [queriesComparison, setQueriesComparison] = useState<any | null>(null);
  const [indexesComparison, setIndexesComparison] = useState<any | null>(null);
  const [tablesComparison, setTablesComparison] = useState<any | null>(null);
  const [configColumnDef, setConfigColumnDef] = useState<ColDef[]>([]);
  const [hwColumnDef, setHwColumnDef] = useState<ColDef[]>([]);
  const [dbColumnDef, setDbColumnDef] = useState<ColDef[]>([]);
  const [queriesColumnDef, setQueriesColumnDef] = useState<ColDef[]>([]);
  const [indexesColumnDef, setIndexesColumnDef] = useState<ColDef[]>([]);
  const [tablesColumnDef, setTablesColumnDef] = useState<ColDef[]>([]);
  const [tabState, setTabState] = useState<number>(0);
  const settings = useRecoilValue(userSettingsAtom);
  const { data: resources } = useQuery(['resources'], () => getMonitoringHostAPI(restClient, settings.defaultApiKey), {
    refetchOnWindowFocus: true
  });

  const getLatestValue = (metrics: any) => {
    return metrics && metrics.length > 0 ? metrics[metrics.length - 1].value : 'Not Found';
  };

  const manipulateQueriesData = (data: any) => {
    const host1Queries = data.instructions.data[0].host1;
    const host2Queries = data.instructions.data[0].host2;

    const queriesMap: any = {};

    const host1Databases = new Set();
    const host2Databases = new Set();

    // Collect databases from host1 and host2
    host1Queries.forEach((query: { db: any; }) => {
      host1Databases.add(query.db);
    });

    host2Queries.forEach((query: { db: any; }) => {
      host2Databases.add(query.db);
    });

    // Identify common databases
    const commonDatabases = new Set([...host1Databases].filter(db => host2Databases.has(db)));

    // Populate queriesMap for host1
    host1Queries.forEach((query: { query: string, queryid: string | number; db: any; total_calls: any; total_exec: any; }) => {
      if (!commonDatabases.has(query.db)) return;

      const key = `${query.queryid}-${query.db}`;
      if (!queriesMap[key]) {
        queriesMap[key] = { db: query.db, queryid: query.queryid , query: query.query };
      }
      queriesMap[key].total_calls_host1 = query.total_calls;
      queriesMap[key].total_exec_host1 = query.total_exec;
    });

    // Populate queriesMap for host2
    host2Queries.forEach((query: { query: string, queryid: string | number; db: any; total_calls: any; total_exec: any; }) => {
      if (!commonDatabases.has(query.db)) return;

      const key = `${query.queryid}-${query.db}`;
      if (!queriesMap[key]) {
        queriesMap[key] = { db: query.db, queryid: query.queryid , query: query.query };
      }
      queriesMap[key].total_calls_host2 = query.total_calls;
      queriesMap[key].total_exec_host2 = query.total_exec;
    });

    // Ensure missing values are set to 'Missing'
    Object.keys(queriesMap).forEach(key => {
      if (queriesMap[key].total_calls_host1 === undefined) {
        queriesMap[key].total_calls_host1 = 'Not Found';
        queriesMap[key].total_exec_host1 = 'Not Found';
      }
      if (queriesMap[key].total_calls_host2 === undefined) {
        queriesMap[key].total_calls_host2 = 'Not Found';
        queriesMap[key].total_exec_host2 = 'Not Found';
      }
    });
    return {
      instructions: {
        data: Object.values(queriesMap)
      }
    };
  };

  const manipulateIndexesData = (data: any) => {
    const host1Indexes = data.instructions.data[0].host1;
    const host2Indexes = data.instructions.data[0].host2;

    const indexesMap: any = {};

    const host1Databases = new Set();
    const host2Databases = new Set();

    for (const key in host1Indexes) {
      const { context } = host1Indexes[key];
      host1Databases.add(context.db);
    }

    for (const key in host2Indexes) {
      const { context } = host2Indexes[key];
      host2Databases.add(context.db);
    }

    const commonDatabases = new Set([...host1Databases].filter(db => host2Databases.has(db)));

    for (const key in host1Indexes) {
      const { context, values } = host1Indexes[key];
      const { db, table_name, index_name } = context;

      if (!commonDatabases.has(db)) continue;

      const totalCalls = values.reduce((sum: any, entry: any) => sum + entry.value, 0);
      const compositeKey = `${db}|${table_name}|${index_name}`;

      if (!indexesMap[compositeKey]) {
        indexesMap[compositeKey] = {
          db,
          table_name,
          index_name,
          total_calls_host1: totalCalls,
          total_calls_host2: 'Not Found'
        };
      } else {
        indexesMap[compositeKey].total_calls_host1 = totalCalls;
      }
    }

    for (const key in host2Indexes) {
      const { context, values } = host2Indexes[key];
      const { db, table_name, index_name } = context;

      if (!commonDatabases.has(db)) continue;

      const totalCalls = values.reduce((sum: any, entry: any) => sum + entry.value, 0);
      const compositeKey = `${db}|${table_name}|${index_name}`;

      if (!indexesMap[compositeKey]) {
        indexesMap[compositeKey] = {
          db,
          table_name,
          index_name,
          total_calls_host1: 'No',
          total_calls_host2: totalCalls
        };
      } else {
        indexesMap[compositeKey].total_calls_host2 = totalCalls;
      }
    }

    return {
      instructions: {
        data: Object.values(indexesMap)
      }
    };
  };

  const manipulateTablesData = (data: any) => {
    const host1Tables = data.instructions.data[0].host1;
    const host2Tables = data.instructions.data[0].host2;

    const tablesMap: any = {};

    const getLatestValue = (metrics: any) => {
      return metrics.length > 0 ? metrics[metrics.length - 1].value : 0;
    };

    const calculateRowsReads = (summary: any) => {
      const { idx_rows_read, seq_rows_read } = summary || {};
      return (parseInt(idx_rows_read, 10) || 0) + (parseInt(seq_rows_read, 10) || 0);
    };

    const calculateRowsWrites = (summary: any) => {
      const { rows_updated, rows_inserted, rows_deleted } = summary || {};
      return (
          (parseInt(rows_updated, 10) || 0) +
          (parseInt(rows_inserted, 10) || 0) +
          (parseInt(rows_deleted, 10) || 0)
      );
    };

    // Collect databases and tables from host1 and host2
    const host1DatabasesTables = new Set(Object.keys(host1Tables));
    const host2DatabasesTables = new Set(Object.keys(host2Tables));

    // Identify all unique databases and tables across both hosts
    const allDatabasesTables = new Set([...host1DatabasesTables, ...host2DatabasesTables]);

    // Populate tablesMap for host1
    for (const key in host1Tables) {
      const { context, rows, schema, summary } = host1Tables[key];
      const { db, table_name } = context;

      const compositeKey = `${db}|${schema}.${table_name}`;
      tablesMap[compositeKey] = {
        db,
        schema_name: schema,
        table_name: `${schema}.${table_name}`,
        table_size_host1: getLatestValue(rows),
        rows_reads_host1: calculateRowsReads(summary),
        rows_writes_host1: calculateRowsWrites(summary),
        table_size_host2: 'Not Found',
        rows_reads_host2: 'Not Found',
        rows_writes_host2: 'Not Found'
      };
    }

    // Populate tablesMap for host2 and update if already exists in host1
    for (const key in host2Tables) {
      const { context, rows, schema, summary } = host2Tables[key];
      const { db, table_name } = context;

      const compositeKey = `${db}|${schema}.${table_name}`;
      if (tablesMap[compositeKey]) {
        tablesMap[compositeKey].table_size_host2 = getLatestValue(rows);
        tablesMap[compositeKey].rows_reads_host2 = calculateRowsReads(summary);
        tablesMap[compositeKey].rows_writes_host2 = calculateRowsWrites(summary);
      } else {
        tablesMap[compositeKey] = {
          db,
          schema_name: schema,
          table_name: `${schema}.${table_name}`,
          table_size_host1: 'Not Found',
          rows_reads_host1: 'Not Found',
          rows_writes_host1: 'Not Found',
          table_size_host2: getLatestValue(rows),
          rows_reads_host2: calculateRowsReads(summary),
          rows_writes_host2: calculateRowsWrites(summary)
        };
      }
    }

    return {
      instructions: {
        data: Object.values(tablesMap)
      }
    };
  };

  const customCellRenderer = (params: any) => {
    return params.value.toString();
  };

  const customValueFormatter = (params: any) => {
    if (params.value == null) {
      return '';
    }
    if (typeof params.value !== 'number' || isNaN(params.value)) {
      // Handle specific cases for 'Not Found' and other non-numeric values
      return params.value === 'Not Found' ? 'Not Found' : 'Invalid Number';
    }
    return String(params.value);
  };


  const missingCellStyle = (params: any) => {
    return params.value === 'Not Found' +
    '' ? { color: 'orange' } : null;
  };

  const handleCompareClicked = async (host1: number, host2: number) => {
    const configData = await getConfigCompareAPI(restClient, settings.defaultApiKey, host1, host2);
    setConfigComparison(configData);
    const columnDefs: ColDef[] = [
      { headerName: 'Property', field: 'property', sortable: true, flex: 1 },
      { headerName: "H1", field: 'host1', sortable: true, flex: 1 },
      { headerName: "H2", field: 'host2', sortable: true, flex: 1 }
    ];
    setConfigColumnDef(columnDefs);

    const hardwareData = await getHardwareCompareAPI(restClient, settings.defaultApiKey, host1, host2);
    setHwComparison(hardwareData);
    const hwColumnDefs: ColDef[] = [
      { headerName: 'Property', field: 'property', sortable: true, flex: 1 },
      { headerName: "H1", field: 'host1', sortable: true, flex: 1, cellRenderer: customCellRenderer, valueFormatter: customValueFormatter },
      { headerName: "H2", field: 'host2', sortable: false, flex: 1, cellRenderer: customCellRenderer, valueFormatter: customValueFormatter }
    ];
    setHwColumnDef(hwColumnDefs);

    const dbData = await getDbSizeCompareApi(restClient, settings.defaultApiKey, host1, host2);
    setDbComparison(dbData);
    const dbColumnDefs: ColDef[] = [
      { headerName: 'Property', field: 'property', sortable: true, flex: 1 },
      {
        headerName: "H1",
        field: 'host1',
        sortable: true,
        flex: 1,
        cellRenderer: (params: any) => {
          const value = params.value;
          return value && !isNaN(value) ? numberWithCommas(parseInt(value)) + ' KB' : 'Not Found';
        },
        cellStyle: missingCellStyle
      },
      {
        headerName: "H2",
        field: 'host2',
        sortable: false,
        flex: 1,
        cellRenderer: (params: any) => {
          const value = params.value;
          return value && !isNaN(value) ? numberWithCommas(parseInt(value)) + ' KB' : 'Not Found';
        },
        cellStyle: missingCellStyle
      }
    ];
    setDbColumnDef(dbColumnDefs);

    const queriesData = await getQueriesCompareAPI(restClient, settings.defaultApiKey, host1, host2);
    setQueriesComparison(manipulateQueriesData(queriesData));
    const queriesColumnDefs: ColDef[] = [
      { headerName: 'Database', field: 'db', sortable: true, flex: 1.3, filter: true },
      { headerName: 'Query Text', field: 'query', sortable: true, flex: 2.6, filter: true },
      {
        headerName: 'H1: Calls',
        field: 'total_calls_host1',
        sortable: true,
        flex: 1,
        cellRenderer: (params: any) => isNaN(params.value) ? 'Not Found' : numberWithCommas(params.value),
        cellStyle: (params) => params.value === 'Not Found' ? { color: 'orange' } : null,
        valueFormatter: customValueFormatter
      },
      {
        headerName: 'H2: Calls',
        field: 'total_calls_host2',
        sortable: true,
        flex: 1,
        cellRenderer: (params: any) => isNaN(params.value) ? 'Not Found' : numberWithCommas(params.value),
        cellStyle: (params) => params.value === 'Not Found' ? { color: 'orange' } : null,
        valueFormatter: customValueFormatter
      },
      {
        headerName: 'H1: Total Exec Time',
        field: 'total_exec_host1',
        sortable: true,
        flex: 1.5,
        cellRenderer: (params: any) => isNaN(params.value) ? 'Not Found' : params.value.toFixed(3),
        cellStyle: (params) => params.value === 'Not Found' ? { color: 'orange' } : null,
        sort: 'desc',
        valueFormatter: customValueFormatter
      },
      {
        headerName: 'H2: Total Exec Time',
        field: 'total_exec_host2',
        sortable: true,
        flex: 1.5,
        cellRenderer: (params: any) => isNaN(params.value) ? 'Not Found' : params.value.toFixed(3),
        cellStyle: (params) => params.value === 'Not Found' ? { color: 'orange' } : null,
        valueFormatter: customValueFormatter
      }
    ];

    setQueriesColumnDef(queriesColumnDefs);

    const indexesData = await getIndexesCompareAPI(restClient, settings.defaultApiKey, host1, host2);
    setIndexesComparison(manipulateIndexesData(indexesData));
    const indexesColumnDefs: ColDef[] = [
      { headerName: 'Database', field: 'db', sortable: true, flex: 1.5, filter: true },
      { headerName: 'Table Name', field: 'table_name', sortable: true, flex: 1.5, filter: true },
      { headerName: 'Index Name', field: 'index_name', sortable: true, flex: 2, filter: true },
      {
        headerName: 'H1: Total Calls',
        field: 'total_calls_host1',
        sortable: true,
        flex: 1,
        cellRenderer: (params: any) => isNaN(params.value) ? 'Not Found' : numberWithCommas(params.value),
        cellStyle: (params) => {
          return params.value === 'No' ? {color: 'orange'} : null
        },
        valueFormatter: customValueFormatter
      },
      {
        headerName: 'H2: Total Calls',
        field: 'total_calls_host2',
        sortable: true,
        flex: 1,
        cellRenderer: (params: any) => isNaN(params.value) ? 'Not Found' : numberWithCommas(params.value),
        cellStyle: (params) => params.value === 'Not Found' ? { color: 'orange' } : null,
        valueFormatter: customValueFormatter
      },
    ];

    setIndexesColumnDef(indexesColumnDefs);

    const tablesData = await getTablesCompareAPI(restClient, settings.defaultApiKey, host1, host2);
    setTablesComparison(manipulateTablesData(tablesData));
    const tablesColumnDefs: ColDef[] = [
      { headerName: 'Database', field: 'db', sortable: true, flex: 0.8, filter: true },
      { headerName: 'Table Name', field: 'table_name', sortable: true, flex: 1.4, filter: true },
      {
        headerName: 'H1: Rows Num.',
        field: 'table_size_host1',
        sortable: true,
        flex: 1.1,
        cellRenderer: (params: any) => isNaN(params.value) ? 'Not Found' :  numberWithCommas(params.value),
        cellStyle: (params) => params.value === 'Not Found' ? { color: 'orange' } : null,
        valueFormatter: customValueFormatter
      },
      {
        headerName: 'H2: Rows Num.',
        field: 'table_size_host2',
        sortable: true,
        flex: 1.1,
        cellRenderer: (params: any) => isNaN(params.value) ? 'Not Found' :  numberWithCommas(params.value),
        cellStyle: (params) => params.value === 'Not Found' ? { color: 'orange' } : null,
        valueFormatter: customValueFormatter
      },
      {
        headerName: 'H1: Rows Reads',
        field: 'rows_reads_host1',
        sortable: true,
        flex: 1.1,
        cellRenderer: (params: any) => isNaN(params.value) ? 'Not Found' :  numberWithCommas(params.value),
        cellStyle: (params) => params.value === 'Not Found' ? { color: 'orange' } : null,
        valueFormatter: customValueFormatter
      },
      {
        headerName: 'H2: Rows Reads',
        field: 'rows_reads_host2',
        sortable: true,
        flex: 1.1,
        cellRenderer: (params: any) => isNaN(params.value) ? 'Not Found' :  numberWithCommas(params.value),
        cellStyle: (params) => params.value === 'Not Found' ? { color: 'orange' } : null,
        valueFormatter: customValueFormatter
      },
      {
        headerName: 'H1: Rows Writes',
        field: 'rows_writes_host1',
        sortable: true,
        flex: 1.1,
        cellRenderer: (params: any) => isNaN(params.value) ? 'Not Found' :  numberWithCommas(params.value),
        cellStyle: (params) => params.value === 'Not Found' ? { color: 'orange' } : null,
        valueFormatter: customValueFormatter
      },
      {
        headerName: 'H1: Rows Writes',
        field: 'rows_writes_host2',
        sortable: true,
        flex: 1.1,
        cellRenderer: (params: any) => isNaN(params.value) ? 'Not Found' :  numberWithCommas(params.value),
        cellStyle: (params) => params.value === 'Not Found' ? { color: 'orange' } : null,
        valueFormatter: customValueFormatter
      }
    ];

    setTablesColumnDef(tablesColumnDefs);

  };

  return (
      <>
        <Breadcrumbs breadcrumbsList={[{ name: 'Compare', isActive: true }]} isGlobalPage GlobalImg={EmptyIcon} />
        {resources && (
            <>
              <h3></h3>
              <FilterContainer>
                <FilterItem>
              <Select
                  name={'Host1'}
                  minWidth="450px"
                  list={resources.map((host) => ({
                    id: host.host_id,
                    name: host.host_name,
                    val: host.host_name
                  }))}
                  value={baseHost}
                  onSelect={(value: string) => {
                    setBaseHostId(resources?.find((host) => host.host_name === value)?.host_id ?? -1);
                    setBaseHost(value);
                  }}
              />
                </FilterItem>
                <FilterItem>
              <Select
                  name={'Host2'}
                  minWidth="450px"
                  list={resources.map((host) => ({
                    id: host.host_id,
                    name: host.host_name,
                    val: host.host_name
                  }))}
                  value={comparedHost}
                  onSelect={(value: string) => {
                    setComparedHostId(resources?.find((host) => host.host_name === value)?.host_id ?? -1);
                    setComparedHost(value);
                  }}
              />
                </FilterItem>
              <Button disabled={(baseHost === "" || comparedHost === "") || baseHost === comparedHost} onClick={() => handleCompareClicked(baseHostId, comparedHostId)}>Compare</Button>
              </FilterContainer>
            </>

        )}
        <TabContainer>
        <Tabs
            tabIndex={tabState}
            value={tabState}
            onChange={(_: any, value: number) => setTabState(value)}
            sx={{ width: 'inherit', mt: 1 }}
        >
          {CompareTabs.map((tabItem): any => (
              <Tab
                  key={tabItem.name}
                  id={`schema-tab-${tabItem.name}`}
                  icon={tabItem.icon}
                  iconPosition="start"
                  label={tabItem.name}
              />
          ))}
        </Tabs>
          {tabState > 2  && <InfoContainer>Last 7 days</InfoContainer>}
        {tabState === 0 && configComparison && (
            <TableContainer className="ag-theme-material">
              <DataGridUI
                  defaultColDef={{
                    sortable: true,
                  }}
                  rowData={configComparison.instructions.data}
                  suppressCellFocus={true}
                  onRowClicked={() => {}}
                  animateRows={true}
                  rowHeight={40}
                  columnDefs={configColumnDef}
              />
            </TableContainer>
        )}
        {tabState === 1 && hwComparison && (
            <TableContainer className="ag-theme-material">
              <DataGridUI
                  defaultColDef={{
                    sortable: true,
                  }}
                  rowData={hwComparison.instructions.data}
                  suppressCellFocus={true}
                  onRowClicked={() => {}}
                  animateRows={true}
                  rowHeight={40}
                  columnDefs={hwColumnDef}
              />
            </TableContainer>
        )}
        {tabState === 2 && dbComparison && (
            <TableContainer className="ag-theme-material">
              <DataGridUI
                  defaultColDef={{
                    sortable: true,
                  }}
                  rowData={dbComparison.instructions.data}
                  suppressCellFocus={true}
                  onRowClicked={() => {}}
                  animateRows={true}
                  rowHeight={40}
                  columnDefs={dbColumnDef}
              />
            </TableContainer>
        )}
        {tabState === 3 && queriesComparison && (
            <TableContainer className="ag-theme-material">
              <DataGridUI
                  defaultColDef={{
                    sortable: true,
                  }}
                  rowData={queriesComparison.instructions.data.map((query: { db: any; query: string; total_calls_host1: any; total_calls_host2: any; total_exec_host1: any; total_exec_host2: any; }) => ({
                    db: query.db,
                    query: query.query,
                    total_calls_host1: query.total_calls_host1,
                    total_calls_host2: query.total_calls_host2,
                    total_exec_host1: query.total_exec_host1,
                    total_exec_host2: query.total_exec_host2,
                  }))}
                  suppressCellFocus={true}
                  onRowClicked={() => {}}
                  animateRows={true}
                  rowHeight={40}
                  columnDefs={queriesColumnDef}
              />
            </TableContainer>
        )}
        {tabState === 4 && indexesComparison && (
            <TableContainer className="ag-theme-material">
              <DataGridUI
                  defaultColDef={{
                    sortable: true,
                  }}
                  rowData={indexesComparison.instructions.data}
                  suppressCellFocus={true}
                  onRowClicked={() => {}}
                  animateRows={true}
                  rowHeight={40}
                  columnDefs={indexesColumnDef}
              />
            </TableContainer>
        )}
        {tabState === 5 && tablesComparison && (
            <TableContainer className="ag-theme-material">
              <DataGridUI
                  defaultColDef={{
                    sortable: true,
                  }}
                  rowData={tablesComparison.instructions.data}
                  suppressCellFocus={true}
                  onRowClicked={() => {}}
                  animateRows={true}
                  rowHeight={40}
                  columnDefs={tablesColumnDef}
              />
            </TableContainer>
        )}
        </TabContainer>
      </>
  );
};

export default ComparePage;

const CompareTabs = [
  { name: 'Configurations', icon: <SettingsIcon /> },
  { name: 'Hardware', icon: <CPUIcon width="18px" height="18px" /> },
  { name: 'Databases size', icon: <DBI width="18px" height="18px"/> },
  { name: 'Queries', icon: <BarIcon /> },
  { name: 'Indexes', icon: <HistoryIcon /> },
  { name: 'Tables', icon: <SpeedDialIcon /> }
];
