import { atom, selector } from 'recoil';
import { ResponseInsights, ResponseMethods, ResponseStatusCodes } from '../utils/constants';
import { userSettingsAtom } from './user.settings.atom';

export interface filterStateI {
  open: boolean;
  toggleCounter: number;
  searchHttpUrl: string;
  exactURLHttp: string;
  selectMethod: string;
  selectInsights: string;
  selectedApiKey: any;
  selectRelativeTime: string;
  selectCode: string;
  selectedTags?: string;
  performanceDetailsData?: any;
}

export const filterState = atom<filterStateI>({
  key: 'filterStateI',
  default: {
    open: false,
    toggleCounter: 0,
    searchHttpUrl: '',
    exactURLHttp: '',
    selectMethod: ResponseMethods[0],
    selectInsights: ResponseInsights[0],
    selectedApiKey: undefined,
    selectRelativeTime: '',
    selectCode: ResponseStatusCodes[0],
    selectedTags: '',
    performanceDetailsData: {}
  }
});

export const filterStateSelector = selector({
  key: 'filterStateSelector',
  get: ({ get }: any) => {
    const userSettings = get(userSettingsAtom);

    const state = get(filterState);
    return { ...state, selectedApiKey: state.selectedApiKey ?? userSettings?.defaultApiKey };
  }
});
