import styled, { css } from 'styled-components';

interface ITypography {
  h4?: string;
  h3?: string;
  h2?: string;
  ht?: string;
  h1?: string;
  s?: string;
  large?: string;
  lineHeight?: string;
  weight?: number;
  color?: string;
}

const StyledTypography = styled.div<ITypography>`
  font-size: 14px;
  ${({ color }) => color && `color: ${color}`};
  line-height: ${(props) => props?.lineHeight || '22px'};
  font-weight: ${(props) => props?.weight || 400};
  ${({ h1 }) =>
    h1 &&
    css`
      font-size: 28px;
      line-height: 32px;
    `}
  ${({ h2 }) =>
    h2 &&
    css`
      font-size: 25px;
      line-height: 32px;
    `}
    ${({ ht }) =>
    ht &&
    css`
      font-size: 20px;
      line-height: 22px;
    `}
  ${({ h3 }) =>
    h3 &&
    css`
      font-size: 18px;
      line-height: 25px;
    `}
  ${({ h4 }) =>
    h4 &&
    css`
      font-size: 16px;
      line-height: 24px;
    `}

  ${({ s }) =>
    s &&
    css`
      font-size: 12px;
      line-height: 15px;
    `}
  ${({ large }) =>
    large &&
    css`
      font-size: 40px;
      line-height: 32px;
    `}
`;

function Typography(props: any) {
  return <StyledTypography {...props} />;
}

export default Typography;
