import { atom } from "recoil";


export const changeLogAtom = atom<any>({
  key: 'changeLogAtom',
  default: {
    changeLog: [],
    date: 'Current'
  }
});
