import { ReactComponent as PGIcon } from '@icons/brand/pg-icon.svg';
import { ReactComponent as MySQLIcon } from '@icons/brand/mysql.svg';
import { ReactComponent as DBI } from '@icons/db.svg';
import { RDBMS } from '../utils/constants';

interface RdbmsIconI {
  type?: string;
}

const RdbmsIcon = ({ type }: RdbmsIconI) => {
  switch (type) {
    case RDBMS.postgres:
      return <PGIcon width="20px" height="20px" />;
    case RDBMS.mysql:
      return <MySQLIcon width="20px" height="20px" />;
    default:
      return <DBI width="20px" height="20px" />;
  }
};
export default RdbmsIcon;
