import React from 'react';
import styled, { css, ThemedStyledProps } from 'styled-components';
import Flex from '../Flex';
import Typography from '../Typography';

interface ILabel {
    type: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'empty';
    title: string;
    icon?: React.ReactNode;
    boldText?: string;
    noBorder?: boolean;
    transparentBackground?: boolean;
    customPadding?: string;
    customHeight?: string;
}

interface TypographyProps {
    bold?: boolean;
}

const IconTagLabel = ({
                          type,
                          title,
                          icon,
                          boldText,
                          noBorder = false,
                          transparentBackground = false,
                          customPadding = '2px 12px',
                          customHeight = '24px'
}: ILabel) => {
    return (
        <LabelWrapper
            type={type}
            noBorder={noBorder}
            transparentBackground={transparentBackground}
            customPadding={customPadding}
            customHeight={customHeight}
        >
            {icon}
            <LabelText>
                {title}
                {boldText && <BoldText>{boldText}</BoldText>}
            </LabelText>
        </LabelWrapper>
    );
};

export default IconTagLabel;

const LabelWrapper = styled(Flex)<ThemedStyledProps<{
    type: ILabel['type'];
    noBorder: boolean;
    transparentBackground: boolean;
    customPadding: string;
    customHeight: string;
}, any>>`
  text-align: center;
  border-radius: 6px;
  padding: ${({ customPadding }) => customPadding};
  height: ${({ customHeight }) => customHeight};
  border: ${({ noBorder, theme }) => (noBorder ? 'none' : `1px solid ${theme.colors.new.primary[125]}`)};
  background: ${({ transparentBackground, theme }) => (transparentBackground ? 'transparent' : theme.colors.new.primary[150])};
  align-items: center; 
  justify-content: start;

  ${({ type, noBorder, transparentBackground, theme }) =>
    type === 'success' &&
    css`
        border: ${noBorder ? 'none' : `1px solid ${theme.colors.new.success[50]}`};
        background: ${transparentBackground ? 'transparent' : theme.colors.new.success[150]};
        div {
            color: ${theme.colors.new.success[50]};
        }
        svg {
            stroke: ${theme.colors.new.success[50]};
        }
    `}

  ${({ type, noBorder, transparentBackground, theme }) =>
    type === 'info' &&
    css`
        border: ${noBorder ? 'none' : `1px solid ${theme.colors.new.info[100]}`};
        background: ${transparentBackground ? 'transparent' : theme.colors.new.info[150]};
        div {
            color: ${theme.colors.new.info[50]};
        }
    `}

  ${({ type, noBorder, transparentBackground, theme }) =>
    type === 'secondary' &&
    css`
      border: ${noBorder ? 'none' : `1px solid ${theme.colors.new.secondary[100]}`};
      background: ${transparentBackground ? 'transparent' : theme.colors.new.secondary[150]};
      div {
        color: ${theme.colors.new.secondary[50]};
      }
    `}

  ${({ type, noBorder, transparentBackground, theme }) =>
    type === 'warning' &&
    css`
      border: ${noBorder ? 'none' : `1px solid ${theme.colors.new.warning[100]}`};
      background: ${transparentBackground ? 'transparent' : theme.colors.new.warning[150]};
      div {
        color: ${theme.colors.error.critical};
      }
      svg {
        stroke: ${theme.colors.error.critical};
      }
    `}

  ${({ type, noBorder, transparentBackground, theme }) =>
    type === 'empty' &&
    css`
      border: ${noBorder ? 'none' : `1px solid ${theme.colors.grey[600]}`};
      background: ${transparentBackground ? 'transparent' : theme.colors.grey[600]};
      div {
        color: ${theme.colors.grey[500]};
      }
      svg {
        stroke: ${theme.colors.grey[500]};
      }
    `}
`;

const LabelText = styled(Typography)<TypographyProps>`
    text-align: left;
    padding-left: 4px;
    padding-top: 1px;
    color: ${({ theme }) => theme.colors.new.primary[50]};
    ${({ bold }) => bold && css`
        font-weight: bold;
    `}
    font-size: 0.9rem;
`;

const BoldText = styled.span`
    font-weight: bold;
    margin-left: 4px;
`;
