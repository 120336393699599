import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { snackbarState } from '../atoms/snackbar.atom';
import { DYNAMIC_IMPORT_MODLE_ERROR_MESSAGE } from '../common/consts';
import useNavigateBack from '../hooks/useNavigateBack';

export default function ErrorFallback({ error, resetError }: { error?: Error; resetError: any }) {
  const [snackbarValue, setSnackbarS] = useRecoilState(snackbarState);
  const navigateBack: any = useNavigateBack();
  const { state }: any = useLocation();

  useEffect(() => {
    if (error?.message) {
      if (typeof error.message === 'string' && error.message.includes(DYNAMIC_IMPORT_MODLE_ERROR_MESSAGE)) {
        window.location.reload();
      } else {
        Sentry.captureException(error);
        setSnackbarS((old) => ({
          ...old,
          cb: () => navigateBack(state),
          open: true,
          severity: 'error',
          text: 'We encountered an issue, we will be redirected to previous page.',
          descriptions: error?.message
        }));
      }
    }
  }, [error]);
  useEffect(() => {
    if (snackbarValue.cb) {
      const timer = setTimeout(() => {
        snackbarValue.cb();
        setSnackbarS((old) => ({ ...old, open: false, cb: undefined, descriptions: '' }));
      }, 3000);
      return () => {
        clearTimeout(timer);
        resetError();
      };
    }
  }, [snackbarValue.open, snackbarValue.cb]);

  return <></>;
}
