import { useMemo, useState } from 'react';
import { ValueFormatterParams } from '@ag-grid-community/core';

import { ProductionGridUIContainer } from '../../ProductionTab.styled';
import { PriorityTextToNumber } from '../../../../enums';
import { BodyDrawer, CellTitle, Close, DataGridUI, HeaderDrawer, TitleDrawer } from '../../../Reports/Reports.styled';
import MTooltip from '../../../../core/Tooltip/Tooltip';
import Typography from '../../../../core/Typography';
import TableLoader from '../../../../components/Loader/TableLoader';
import MDrawer from '../../../../core/Drawer/Drawer';
import { AssertionTab } from '../../../../components/AssertionsTab/AssertionTab';
import { getAssertions } from '../../../Reports/utils';
import { SeverityLabel } from '../../../../components/SeverityLabel/SeverityLabel';
import Flex from '../../../../core/Flex';

const insightsFormatter = (params: ValueFormatterParams) => {
  return (
    params?.data?.insight && <SeverityLabel priority={Number.parseInt(PriorityTextToNumber[params?.data?.insight?.priority])} />
  );
};

export const ProductionConfiguration = (configuration: any[], isLoading: boolean) => {
  const [drawerState, setDrawerState] = useState<boolean>(false);
  const [rowData, setRowData]: any[] = useState([]);
  const [assertionIndex, setAssertionIndex] = useState(0);

  const [columnDefs] = useState([
    {
      field: 'db_host',
      headerName: 'Host',
      valueGetter: (params: any) => params?.data?.db_host
    },
    {
      field: 'db_name',
      flex: 0.6,
      headerName: 'Database',
      valueGetter: (params: any) => params?.data?.db_name
    },
    {
      field: 'name',
      headerName: 'Configuration Property',
      valueGetter: (params: any) => params?.data?.name?.toLowerCase?.()
    },
    {
      field: 'setting',
      flex: 0.5,
      sortable: false,
      headerName: 'Setting',
      valueGetter: (params: any) => params?.data?.setting
    },
    {
      headerName: 'Description',
      flex: 1,
      valueGetter: (params: any) => params?.data?.description,
      cellRenderer: ({ value }: ValueFormatterParams) => (
        <CellTitle>
          <MTooltip disable={value?.length < 90} title={<Typography>{value}</Typography>}>
            <Typography>{value}</Typography>
          </MTooltip>
        </CellTitle>
      )
    },
    {
      headerName: 'Insights',
      sortable: true,
      sort: 'desc',
      cellRenderer: insightsFormatter,
      flex: 0.5,
      cellStyle: { justifyContent: 'left', alignItems: 'center', display: 'flex' },
      valueGetter: (params: any) => params?.data?.context?.insight,
      comparator: (valueA: number, valueB: number, nodeA: any, nodeB: any) => {
        const priorityA = PriorityTextToNumber[nodeA?.data?.insight?.priority || 'INFO'];
        const priorityB = PriorityTextToNumber[nodeB?.data?.insight?.priority || 'INFO'];

        if (priorityA === priorityB) return 0;
        return priorityA > priorityB ? -1 : 1;
      }
    }
  ]);
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
      alwaysMultiSort: true
    }),
    []
  );

  return (
    <>
      <ProductionGridUIContainer className="ag-theme-material">
        {isLoading ? (
          <TableLoader size={6} />
        ) : (
          <DataGridUI
            rowData={configuration}
            animateRows={true}
            columnDefs={columnDefs}
            suppressCellFocus={true}
            multiSortKey={'ctrl'}
            defaultColDef={defaultColDef}
            onRowClicked={(row: any) => {
              setDrawerState(row?.data?.insight !== undefined);
              setRowData([row?.data?.insight]);
            }}
          />
        )}
      </ProductionGridUIContainer>
      <MDrawer onClose={() => setDrawerState(false)} anchor="right" open={drawerState}>
        <HeaderDrawer justify="space-between">
          <TitleDrawer h3 weight="600">
            Configuration Details
          </TitleDrawer>
          <Flex margin="8px 16px">
            <Close title="close-icon" onClick={() => setDrawerState(false)} />
          </Flex>
        </HeaderDrawer>
        <BodyDrawer>
          <AssertionTab
            assertions={getAssertions(rowData)}
            headHeight="100px"
            facts={{}}
            assertionCurrentIndex={assertionIndex}
            configurationsReportAssertions={true}
            setAssertionIndexCallback={setAssertionIndex}
            tableInfos={[]}
            changeTabActivity={0}
            isLoading={isLoading}
            selectedQuery={0}
            extensionsReportAssertions={true}
            isSidebar={true}
          />
        </BodyDrawer>
      </MDrawer>
    </>
  );
};
