import styled, { css } from 'styled-components';
import Flex from '../../core/Flex';
import { SvgImg } from '../../core/SvgImg';
import Typography from '../../core/Typography';
import { SideMenuWidth } from '../../theme';

export const Wrapper = styled(Flex)`
  width: 250px;
  position: relative;
  justify-content: start;
`;

export const LogoSideMenuImg = styled.img`
  height: 28px;
  margin: 0 0 25px 15px;
`;
export const ListWrapper = styled(Flex)`
  height: 100%;
  overflow: auto;
`;
export const WrapperOutlet = styled(Flex)`
  width: 100%;
  padding-left: 34px;
  height: calc(100vh - 105px);
`;
export const Container = styled(Flex)`
  position: fixed;
  top: 35px;
  left: ${SideMenuWidth};
  height: calc(100vh - 35px);
  padding: 8px 0;
  background-color: ${({ theme }) => theme.colors.secondary.dark};
  background-color: ${({ theme }) => theme.colors.grey[350]};
`;
export const ApiKeySpan = styled.div`
  text-overflow: ellipsis;
  width: 85px;
  overflow: hidden;
  whitespace: nowrap;
  color: ${({ theme }) => theme.colors.info.main};
`;
export const Footer = styled.div`
  padding: 10px 0;
`;

export const Divider = styled.hr`
  border: 1px solid ${({ theme }) => theme.colors.grey[200]};
  width: 205px;
`;

export const ListItem = styled(Flex)`
  height: 20px;
  margin: 1px 10px;
  cursor: pointer;
  justify-content: start;
  padding: 10px 5px;
  width: 205px;
  color: ${({ theme }) => theme.colors.text.main};
  svg {
    stroke: ${({ theme }) => theme.colors.text.main};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[150]};
    border-radius: 2px;
  }
  ${({ active }) =>
    active &&
    css`
      color: ${({ theme }) => theme.colors.text.title};
      background-color: ${({ theme }) => theme.colors.grey[150]};
      svg {
        stroke: ${({ theme }) => theme.colors.primary.border};
      }
    `}
`;

export const UserImg = styled.img`
  height: 28px;
  border-radius: 50%;
  margin: 0 10px;
  border: ${({ theme }) => `1px solid ${theme.colors.info.main}`};
`;
export const Icon = styled(SvgImg)`
  height: 24px;
  width: 24px;
  stroke: ${({ theme }) => theme.colors.white};
  stroke: transparent;
  margin: 0 10px 0 5px;
`;
export const IconLink = styled(Icon)`
  stroke: transparent;
`;
export const SideMenuListIcon = styled(SvgImg)`
  height: 24px;
  width: 24px;
  fill: ${({ theme }) => theme.colors.info.main};
  stroke: none;
`;

export const DebugAnimationContainer = styled.div`
  position: absolute;
  left: -25px;
`;
export const Text = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
export const SubTitle = styled(Typography)`
  padding-right: 3px;
  color: ${({ theme }) => theme.colors.text.main};
`;
