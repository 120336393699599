import { type NodeProps } from '@xyflow/react';
import { type SQLExplainNode } from './types';
import {SubQueryContainer, SubQueryLabel} from './SQLSubQueryStyles'

export const SQLSubQueryComponent = ({ data }: NodeProps<SQLExplainNode>) => {
    const { label = 'N/A', width = 300, height = 200 } = data;

    return (
        <SubQueryContainer width={Number(width)} height={Number(height)}>
            <SubQueryLabel>{label}</SubQueryLabel>
        </SubQueryContainer>
    );
};
