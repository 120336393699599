import { atom, useRecoilState } from 'recoil';

export interface apiKeyStateI {
  apiKeys: ApiKeyType[];
}
export interface ApiKeyType {
  id: string;
  api_key: string;
  user_id: string;
  description: string | null;
  label: string | null;
  is_personal: boolean;
  created_at: Date;
  updated_at: Date;
  isDemo?: boolean;
}

export const apiKeysState = atom<apiKeyStateI>({
  key: 'apiKeysState',
  default: {
    apiKeys: []
  }
});

const defaultApiKey: ApiKeyType = {
  id: '',
  api_key: '',
  user_id: '',
  label: '',
  is_personal: false,
  created_at: new Date(),
  updated_at: new Date(),
  description: null
};

export const useActiveAPIKey = (key: string): ApiKeyType => {
  const [apiKeys] = useRecoilState(apiKeysState);
  const selectedAPIKey: ApiKeyType = defaultApiKey;
  if (apiKeys.apiKeys.length) {
    const resAPIKey = apiKeys.apiKeys.find((api: ApiKeyType) => api.api_key === key);
    return resAPIKey || selectedAPIKey;
  }
  return selectedAPIKey;
};

export const convertApiKeyToApiId = (apiKey: string, apiKeys: ApiKeyType[]): string => {
    if (apiKeys.length) {
        const resAPIKey = apiKeys.find((api: ApiKeyType) => api.api_key === apiKey);
        return resAPIKey?.id || '';
    }
    return '';
}

export const convertApiIdToApiKey = (apiId: string | undefined, apiKeys: ApiKeyType[]): string => {
    if (apiKeys.length) {
        const resAPIKey = apiKeys.find((api: ApiKeyType) => api.id === apiId);
        return resAPIKey?.api_key || '';
    }
    return '';
}
