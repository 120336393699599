import { useState } from 'react';

import { Tab, Tabs } from '@mui/material';
import { ValueFormatterParams } from '@ag-grid-community/core';
import { SeverityLabel } from '../../../../components/SeverityLabel/SeverityLabel';
import { TrendDataGridCell } from '../../../Reports/common/TrendDataGridCell';
import TableLoader from '../../../../components/Loader/TableLoader';
import { BodyDrawer, DataGridUI, TitleDrawer } from '../../../Reports/Reports.styled';
import { ProductionGridUIContainer } from '../../ProductionTab.styled';
import MDrawer from '../../../../core/Drawer/Drawer';
import { Close, HeaderDrawer } from '../../ObservabilityDashboard.styled';
import Flex from '../../../../core/Flex';
import { ReportTabState } from '../../../Reports/utils';
import { AssertionTab } from '../../../../components/AssertionsTab/AssertionTab';

export const ProductionIndexes = (indexes: any[], isLoading: boolean) => {
  const [insight, setInsight] = useState<any>([]);
  const [assertionIndex, setAssertionIndex] = useState(0);

  const insightsFormatter = (params: ValueFormatterParams) => {
    return params?.value?.length && <SeverityLabel priority={params.value[0]?.priority} />;
  };
  const [columnDefs] = useState([
    {
      field: 'db_host',
      flex: 0.8,
      cellStyle: { justifyContent: 'start', alignItems: 'center', display: 'flex' },
      headerName: 'Host',
      valueGetter: (params: any) => params?.data?.context?.db_host
    },
    {
      field: 'db_name',
      flex: 0.8,
      cellStyle: { justifyContent: 'start', alignItems: 'center', display: 'flex' },
      headerName: 'Database',
      valueGetter: (params: any) => params?.data?.context?.db_name
    },
    {
      sortable: true,
      flex: 0.8,
      headerName: 'Schema Name',
      cellStyle: { justifyContent: 'start', alignItems: 'center', display: 'flex' },
      valueGetter: (params: any) => params.data?.context?.schema
    },
    {
      headerName: 'Table Name',
      sortable: true,
      flex: 0.8,
      cellStyle: { justifyContent: 'start', alignItems: 'center', display: 'flex' },
      valueGetter: (params: any) => params.data?.context?.table_name
    },
    {
      headerName: 'Index Name',
      sortable: true,
      flex: 0.8,
      cellStyle: { justifyContent: 'start', alignItems: 'center', display: 'flex' },
      valueGetter: (params: any) => params.data?.context?.index_name
    },
    {
      field: 'history',
      headerName: 'Daily Usage',
      sortable: false,
      flex: 1,
      valueGetter: (params: any) => params.data?.values,
      cellRenderer: ({ value }: ValueFormatterParams) => <TrendDataGridCell data={value} />
    },
    {
      headerName: 'Insights',
      sortable: true,
      sort: 'desc',
      flex: 0.7,
      cellStyle: { justifyContent: 'start', alignItems: 'center', display: 'flex' },
      cellRenderer: insightsFormatter,
      valueGetter: (params: ValueFormatterParams) => params?.data?.context?.insights,
      comparator: (valueA: number, valueB: number, nodeA: any, nodeB: any) => {
        const priorityA = nodeA?.data?.context?.insights?.[0]?.priority || 0;
        const priorityB = nodeB?.data?.context?.insights?.[0]?.priority || 0;
        if (priorityA === priorityB) return 0;
        return priorityA > priorityB ? 1 : -1;
      }
    }
  ]);

  return (
    <>
      <ProductionGridUIContainer className="ag-theme-material">
        {isLoading ? (
          <TableLoader size={6} />
        ) : (
          <DataGridUI
            rowData={indexes}
            animateRows={true}
            columnDefs={columnDefs}
            rowHeight={75}
            suppressCellFocus={true}
            onRowClicked={(row: any) => {
              row?.data?.context?.insights && setInsight(row.data.context.insights);
            }}
          />
        )}
      </ProductionGridUIContainer>
      <MDrawer onClose={() => setInsight([])} anchor="right" open={!!insight.length}>
        <HeaderDrawer justify="space-between">
          <TitleDrawer h3 weight="600">
            Index Usage Details
          </TitleDrawer>
          <Flex margin="8px 16px">
            <Close title="close-icon" onClick={() => setInsight([])} />
          </Flex>
        </HeaderDrawer>
        <BodyDrawer>
          <Tabs sx={{ minHeight: '35px', ml: 1 }} value={0}>
            {ReportTabState.map((tabItem: string) => (
              <Tab key={tabItem} sx={{ textTransform: 'none', fontWeight: 600 }} id={`report-tab-${tabItem}`} label={tabItem} />
            ))}
          </Tabs>
          <AssertionTab
            assertions={insight}
            headHeight="100px"
            facts={{}}
            assertionCurrentIndex={assertionIndex}
            setAssertionIndexCallback={setAssertionIndex}
            tableInfos={[]}
            changeTabActivity={0}
            isLoading={isLoading}
            selectedQuery={0}
            extensionsReportAssertions={true}
            isSidebar={true}
          />
        </BodyDrawer>
      </MDrawer>
    </>
  );
};
