import { useEffect, useMemo, useState } from 'react';
import { KyInstance } from 'ky';
import { ReactComponent as InfoI } from '@icons/info.svg';
import { ReactComponent as CheckI } from '@icons/severity-check.svg';
import { ReactComponent as CodeI } from '@icons/code.svg';
import { useQuery } from 'react-query';
import { Skeleton } from '@mui/material';

import Flex from '../../../core/Flex';
import CodeEditor from '../../../core/MonacoEditor/CodeEditor';
import Typography from '../../../core/Typography';
import {
  AdviceContainer,
  DescriptionInfoWrapper,
  Divider,
  DividerTitle,
  InfoIcon,
  MoreIcon,
  MoreWrapper,
  SubTitle,
  SuggestionWrapper,
  TagLabelWrapper,
  Title,
  TitleTooltip,
  LabelWrapper,
  QueryCardWrapper
} from '../Reports.styled';
import { QueryStats, SuggestionResults } from '../reports/types';
import { getQueryOptimizerAPI, getQuerySuggestionAPI } from '../utils/ReportAPI';
import { formatSQL, numberWithCommas } from '../../../utils/utils';
import TagLabel from '../../../core/Label/Label';
import { DiffEditor } from '../../../core/Monaco/monaco';
import Modal from '../../../components/Modal/Modal';
import Button from '../../../core/Button/Button';
import { useParams } from 'react-router-dom';
import { snackbarState } from '../../../atoms/snackbar.atom';
import { useSetRecoilState } from 'recoil';

interface AI_AdviceI {
  restClient: KyInstance;
  apiKey: string;
  query?: QueryStats;
  isQueryPlan: boolean;
  originalCost?: number;
}
export const AI_Advice = ({ query, restClient, apiKey, isQueryPlan, originalCost }: AI_AdviceI) => {
  const { databaseId = '' } = useParams();
  const setSnackbarS = useSetRecoilState(snackbarState);
  const [showMoreSuggestions, setShowMoreSuggestions] = useState(false);
  const [isDisableOptimizer, setDisableOptimizer] = useState(false);
  const [isConfigurationsTab, setIsConfigurationsTab] = useState(false);
  const [showDiff, setShowDiff] = useState<any>();
  const [activeTab, setActiveTab] = useState(0);
  const originQueryHeight = formatSQL(query?.query.replace(/`/g,'') || '').split('\n').length;
  const OriginQuery = useMemo(
    () => () => (
      <CodeEditor
        isCopyValue
        language="sql"
        readonly={true}
        height={`${originQueryHeight > 30 ? 250 : originQueryHeight < 20 ? 150 : originQueryHeight * 10}px`}
        width="100%"
        value={formatSQL(query?.query.replace(/`/g,'') || '')}
      />
    ),
    [query?.query]
  );

  const { data = { queryResults: [], schemaResults: [], isMySQL: false }, isLoading } = useQuery(
    ['query suggestion', query?.query_id],
    () => getQuerySuggestionAPI(restClient, apiKey, query?.query_id || ''),
    {
      refetchOnWindowFocus: false,
      enabled: !!query?.query_id
    }
  );
  const getResults = (data: any): SuggestionResults[] => {
    return isConfigurationsTab ? data?.schemaResults : data?.queryResults;
  };

  const originalQuery = data?.queryResults?.find((s: SuggestionResults) => s?.isOriginal);
  const EmptySuggestionC = createTypingEffectComponent("Our AI Agent couldn't find any better suggestions.");

  const shouldShowMoreSuggestions = () => {
    return isConfigurationsTab ? false : data.queryResults?.length! > 2;
  };

  const shouldShowNoSuggestions = () => {
    if (data?.isMySQL) {
      return isConfigurationsTab ? data?.schemaResults?.length === 0 : data?.queryResults?.length === 0;
    }
    return isConfigurationsTab ? data?.schemaResults?.length === 0 : data?.queryResults?.length === 1 && originalQuery?.cost;
  };


  const handleOptimizeQuery = async () => {
    const resOptimizer: boolean = await getQueryOptimizerAPI(restClient, apiKey, query?.query_id || '', databaseId, setSnackbarS);
    setDisableOptimizer(resOptimizer);
  };
  return (
    <AdviceContainer direction="column" align="start">
      <Flex margin="0 0 12px 0">
        <Title h4 weight="600">
          Original Query
        </Title>
        <LabelWrapper>
         <TagLabel
             title={originalQuery?.cost ? `Cost ${numberWithCommas(parseFloat(originalQuery?.cost?.toString()), 2)}` : 'N/A'}
             type={originalQuery?.cost ? 'success' : 'info'}
          />
        </LabelWrapper>
        {isQueryPlan && (
          <Button sx={{ ml: 2 }} disabled={isDisableOptimizer} variant="secondary" onClick={handleOptimizeQuery}>
            <Typography weight="500">{isDisableOptimizer ? 'Optimizing ...' : 'Optimize'}</Typography>
          </Button>
        )}
      </Flex>
      <OriginQuery />
      <Flex fw>
        <QueryCardWrapper
          isActive={activeTab === 0}
          margin="12px 8px 0 0"
          onClick={() => {
            setIsConfigurationsTab(false);
            setActiveTab(0);
          }}
        >
          <Typography weight="600">Query Rewrites</Typography>
        </QueryCardWrapper>
        <QueryCardWrapper
          margin="12px 0 0 8px"
          isActive={activeTab === 1}
          onClick={() => {
            setIsConfigurationsTab(true);
            setActiveTab(1);
          }}
        >
          <Typography weight="600">Schema Suggestions</Typography>
        </QueryCardWrapper>
      </Flex>
      {isLoading ? (
        <>
          <Flex fw direction="column" margin="16px 0">
            <Flex fw justify="space-between">
              <Skeleton variant="text" animation="wave" width="40%" sx={{ margin: '12px 0' }} />
              <Skeleton variant="text" animation="wave" width="10%" sx={{ margin: '12px 0' }} />
              <Skeleton variant="text" animation="wave" width="40%" sx={{ margin: '12px 0' }} />
            </Flex>
            <Skeleton variant="rectangular" animation="wave" width="100%" sx={{ margin: '12px 0' }} height={160} />
            <Skeleton variant="rectangular" animation="wave" width="100%" sx={{ margin: '12px 0' }} height={40} />
          </Flex>
          <Flex fw direction="column" margin="16px 0">
            <Flex fw justify="space-between">
              <Skeleton variant="text" animation="wave" width="40%" sx={{ margin: '12px 0' }} />
              <Skeleton variant="text" animation="wave" width="10%" sx={{ margin: '12px 0' }} />
              <Skeleton variant="text" animation="wave" width="40%" sx={{ margin: '12px 0' }} />
            </Flex>
            <Skeleton variant="rectangular" animation="wave" width="100%" sx={{ margin: '12px 0' }} height={160} />
            <Skeleton variant="rectangular" animation="wave" width="100%" sx={{ margin: '12px 0' }} height={40} />
          </Flex>
        </>
      ) : getResults(data)?.length ? (
        <>
          {getResults(data)
            ?.filter((f) => !f?.isOriginal)
            .map((suggestion: SuggestionResults, i: number) => {
              let SuggestionC: any;
              if(suggestion?.description) {
                SuggestionC = createTypingEffectComponent(suggestion?.description || '');
              }
              else if(suggestion?.cost && originalQuery?.cost) {
                const percentage = Math.round(((originalQuery?.cost - suggestion?.cost) / originalQuery?.cost) * 100);
                SuggestionC = createTypingEffectComponent(`Add the following indexes to improve the performance of the query by ${percentage}%`);
              }
              if (!showMoreSuggestions && i && !isConfigurationsTab) return null;
              const codeEditorHeight = formatSQL(suggestion?.codeBlock).split('\n').length;
              return (
                <SuggestionWrapper fw key={i} direction="column">

                  <Flex fw margin="0 0 12px 0">
                    <Divider />
                    <Flex>
                      <DividerTitle weight={600}>Suggestion {i + 1}</DividerTitle>
                        <TagLabelWrapper>
                          <TagLabel
                            title={suggestion?.cost ? `Cost ${numberWithCommas(parseFloat(suggestion?.cost?.toString()), 2)}` : 'N/A'}
                            type={suggestion?.cost ? 'success' : 'info'}
                          />
                        </TagLabelWrapper>
                    </Flex>
                    <Divider />
                  </Flex>
                  <Flex align="start" direction="column" fw>
                    {!isConfigurationsTab && (
                      <Button
                        sx={{ mb: 1 }}
                        variant="secondary"
                        onClick={() => setShowDiff(suggestion)}
                        endIcon={
                          <Flex margin="3px 0 0 0">
                            <CodeI width={15} height={15} />
                          </Flex>
                        }
                      >
                        <TitleTooltip weight="500">Diff View</TitleTooltip>
                      </Button>
                    )}
                    <CodeEditor
                      isCopyValue
                      language="sql"
                      readonly={true}
                      height={`${codeEditorHeight > 30 ? 240 : codeEditorHeight < 20 ? 170 : codeEditorHeight * 10}px`}
                      width="100%"
                      value={formatSQL(suggestion?.codeBlock || '')}
                    />

                    <DescriptionInfoWrapper fw margin="12px 0 0 0">
                      <InfoIcon>
                        <InfoI />
                      </InfoIcon>
                      <SuggestionC />
                    </DescriptionInfoWrapper>
                  </Flex>
                </SuggestionWrapper>
              );
            })}
          {shouldShowMoreSuggestions() && (
            <Flex fw margin="18px 0 0 0">
              <MoreWrapper onClick={() => setShowMoreSuggestions(!showMoreSuggestions)}>
                <SubTitle>{showMoreSuggestions ? 'Hide Suggestions' : 'Show Suggestions'}</SubTitle>
                <MoreIcon />
              </MoreWrapper>
            </Flex>
          )}
          {shouldShowNoSuggestions() && (
            <DescriptionInfoWrapper fw margin="12px 0 0 0">
              <CheckI />
              <EmptySuggestionC />
            </DescriptionInfoWrapper>
          )}
        </>
      ) : (
          <DescriptionInfoWrapper fw margin="12px 0 0 0">
            <CheckI />
            <EmptySuggestionC />
          </DescriptionInfoWrapper>
      )}
      <Modal
        maxWidth="lg"
        isOpen={!!showDiff}
        handleClickModal={() => setShowDiff(null)}
        title={'Diff view'}
        content={() => (
          <Flex direction="column" fw margin="24px 0">
            <DiffEditor
              original={formatSQL(originalQuery?.codeBlock || '')}
              modified={formatSQL(showDiff?.codeBlock || '')}
              options={{ renderSideBySide: true, readOnly: true }}
              language={'sql'}
              height="77vh"
              width="100%"
            />
          </Flex>
        )}
      />
    </AdviceContainer>
  );
};

const createTypingEffectComponent = (text: string) => {
  return () => {
    const [displayedText, setDisplayedText] = useState('');

    useEffect(() => {
      if (text.length > displayedText.length) {
        const timeoutId = setTimeout(() => {
          setDisplayedText(text.slice(0, displayedText.length + 1));
        }, 20); // Adjust the delay to control the speed of typing effect

        return () => clearTimeout(timeoutId);
      }
    }, [text, displayedText]);

    return <Typography>{displayedText} </Typography>;
  };
};
