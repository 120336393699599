import { FunctionComponent, SVGProps } from 'react';
import { EmptySectionText, SectionEmpty } from '../ProjectItem/Project.styled';
import Button from '../../../core/Button/Button';
import { analyticsTrack } from '../../../utils/segment-analytics';
import Typography from '../../../core/Typography';

interface EmptyProjectItemI {
  title: string;
  isLoading?: boolean;
  EmptyIcon: FunctionComponent<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  type: 'TEST' | 'STAGE' | 'PROD';
}

const LINK = {
  TEST: 'https://docs.metisdata.io/SetupMetis/Add%20Metis%E2%80%99s%20Git%20actions%20to%20your%20repository/',
  STAGE: 'https://docs.metisdata.io/',
  PROD: 'https://docs.metisdata.io/'
};

const EmptyProjectItem = ({ title, EmptyIcon, type, isLoading }: EmptyProjectItemI) =>
  isLoading ? null : (
    <SectionEmpty direction="column" align="center">
      <EmptyIcon />
      <EmptySectionText weight={600}>{title}</EmptySectionText>

      <Button
        variant="link"
        target="_blank"
        href={LINK[type]}
        onClick={() => {
          analyticsTrack('Learn More', { name: type, link: LINK[type] });
        }}
      >
        <Typography h4 weight={600}>
          Learn More
        </Typography>
      </Button>
    </SectionEmpty>
  );

export default EmptyProjectItem;
