import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { QueryStats } from '../../../Reports/reports/types';
import TableLoader from '../../../../components/Loader/TableLoader';
import { ProductionGridUIContainer } from '../../ProductionTab.styled';
import { DataGridUI } from '../../../Reports/Reports.styled';
import { ValueFormatterParams } from 'ag-grid-community';
import SeveritiesSummary from '../../../../components/SeveritiesSummary/SeveritiesSummary';
import { numberWithCommas } from '../../../../utils/utils';

const insightsFormatter = (params: ValueFormatterParams) => {
  return (
    params?.data?.severities && (
      <SeveritiesSummary
        severity_critical={params?.data?.severities?.severity_critical || null}
        severity_high={params?.data?.severities?.severity_high || null}
        severity_medium={params?.data?.severities?.severity_medium || null}
        severity_low={params?.data?.severities?.severity_low || null}
      />
    )
  );
};

export const ProductionQueries = (queries: any[], isLoading: boolean) => {
  const { projectApiKey = '' } = useParams();

  const navigate = useNavigate();
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1
    }),
    []
  );
  const getQueryStatisticById = async (item: QueryStats) => {
    if (item?.trace_id) {
      navigate(`/projects/${projectApiKey}/dashboard/${item.trace_id}`);
    }
  };
  const [columnDefs] = useState([
    {
      field: 'host',
      headerName: 'Host',
      valueGetter: (params: any) => params?.data?.host
    },
    {
      field: 'database_name',
      flex: 0.6,
      headerName: 'Database',
      valueGetter: (params: any) => params?.data?.database_name
    },
    {
      field: 'query',
      headerName: 'Query',
      valueGetter: (params: any) => params?.data?.query
    },
    {
      field: 'setting',
      headerName: 'Calls',
      flex: 0.5,
      valueGetter: (params: any) => numberWithCommas(params?.data?.calls)
    },
    {
      headerName: 'Avg Duration (ms)',
      flex: 0.5,
      valueGetter: (params: any) => numberWithCommas(params?.data?.mean_exec_time, 3)
    },
    {
      headerName: 'Insights',
      sortable: false,
      cellRenderer: insightsFormatter,
      flex: 1,
      cellStyle: { justifyContent: 'start', alignItems: 'center', display: 'flex' },
      valueGetter: (params: ValueFormatterParams) => params?.data?.severities
    }
  ]);

  if (isLoading) return <TableLoader size={6} />;
  return (
    <ProductionGridUIContainer className="ag-theme-material">
      <DataGridUI
        rowData={queries}
        animateRows={true}
        columnDefs={columnDefs}
        multiSortKey={'ctrl'}
        suppressCellFocus={true}
        defaultColDef={defaultColDef}
        onRowClicked={(row: any) => {
          getQueryStatisticById(row.data);
        }}
      />
    </ProductionGridUIContainer>
  );
};
