import { useCallback, useEffect, useRef } from 'react';

declare global {
  interface Window {
    setPlanData: any; // this method is declared globally in pev2 app
  }
}

export const PGPlanViewer = ({ query, plan }: { query: string; plan: string }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const name = 'metisPlan';
  const planObj: any = JSON.parse(plan || '{}');
  const longIndexCond =
    planObj?.Plan && planObj?.Plan?.['Index Cond'] && planObj?.Plan?.['Index Cond'].length > 3000
      ? { ...planObj, Plan: { ...planObj?.Plan, 'Index Cond': planObj?.Plan['Index Cond'].substring(0, 3000) } }
      : planObj;

  const longQuery = query.length > 2000 ? query.substring(0, 2000) : query;
  useEffect(() => {
    if (iframeRef.current?.contentWindow?.setPlanData) {
      iframeRef.current?.contentWindow?.setPlanData?.(name, JSON.stringify(longIndexCond, null, 2), longQuery);
    }
  }, [longQuery, longIndexCond]);

  const onLoad = useCallback(() => {
    if (iframeRef.current?.contentWindow?.setPlanData) {
      iframeRef.current?.contentWindow?.setPlanData?.(name, JSON.stringify(longIndexCond, null, 2), longQuery);
    }
  }, [iframeRef]);

  return (
    <iframe
      style={{ width: '100%', height: 'inherit', border: 0 }}
      onLoad={onLoad}
      ref={iframeRef}
      src={`${window.location.origin}/pev2/index.html`}
    />
  );
};
