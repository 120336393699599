import React, {useContext, useEffect, useState} from "react";
import Breadcrumbs from "../../core/Breadcrumbs/Breadcrumbs"
import { TitleMain } from "../../core/Breadcrumbs/Breadcrumbs.styled";
import Flex from "../../core/Flex";
import { useSegmentPage } from "../../utils/segment-analytics";
import { RestClientContext } from "../../auth/RestClientAuthProvider";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { userSettingsAtom } from "../../atoms/user.settings.atom";
import Accordion from '../../core/Accordion/Accordion';
import Typography from "../../core/Typography";
import TagLabel from "../../core/Label/Label";
import {
    Arrow,
    ButtonContainer,
    Container,
    DatabaseGridListContainer,
    DividerTitle,
    HostGridListContainer,
    IndexSuggestionContainer,
    TagContainer,
    TagContainerWrapper,
    TagLabelWrapper
} from "./IndexAdvisor.styled";
import {formatSQL, numberWithCommas} from "../../utils/utils";
import { HostIndexAdvisor } from "./types";
import {GridContainer} from "../LogAnalysis/LogAnalyzer.styled";
import {Grid, Tab, Tabs} from "@mui/material";
import MDrawer from "../../core/Drawer/Drawer";
import {
    BodyDrawer,
    Close, Divider,
    DrawerItemWrapper,
    HeaderDrawer, MoreIcon, MoreWrapper,
    SubTitle,
    TabWrapper,
    TitleDrawer
} from "../Reports/Reports.styled";
import {TabPanel} from "../../core/Tabs/Tabs";
import CodeEditor from "../../core/MonacoEditor/CodeEditor";
import {normalizeSizeFromKb} from "./utils";
import {useNavigate} from "react-router-dom";

export const IndexAdvisor = () => {
    const restClient = useContext(RestClientContext);
    const settings = useRecoilValue(userSettingsAtom);
    const [hostsData, setHostsData] = useState<HostIndexAdvisor[]>([])
    const [expanded, setExpanded] = useState<any[]>([]);
    const [drawerState, setDrawerState] = useState<boolean>(false);
    const [selectedDatabaseIndexData, setSelectedDatabaseIndexData] = useState([]);
    const [selectedDatabase, setSelectedDatabase] = useState<{hostId: string; databaseId: string}>({});
    const [visibleRelatedQueriesByIndexId, setVisibleRelatedQueriesByIndexId] = useState({});
    const navigate = useNavigate();

    const {data, error, isLoading} = useQuery(['fetchText', settings.defaultApiKey], async () => {
        const res: any = await restClient.get('index-advisor', {
            headers: {
                'x-api-key': settings.defaultApiKey
            }
        }).json();

        return res
    }, {
        refetchOnWindowFocus: false,
    });


    const handleChangeAccordion = (accordionItem: any, shouldRefetchData?: boolean) => async () => {
        if (expanded.find((e) => e === accordionItem)) {
            setExpanded((prevE: any) => prevE.filter((p: any) => p !== accordionItem));
        } else {
            setExpanded((prevE: any) => [...prevE, accordionItem]);
        }
    };

    const shouldShowRelatedQueries = (indexId: number) => {
        return visibleRelatedQueriesByIndexId[indexId] || false;
    }

    const changeShowRelatedQueriesByIndexId = (indexId: number) => {
        setVisibleRelatedQueriesByIndexId((prev) => {
            return {
                ...prev,
                [indexId]: !prev[indexId]
            }
        })
    }

    const handleDatabaseSelected = (hostId: string, databaseId: string, indexData: any) => {
        setSelectedDatabase({hostId, databaseId});
        setSelectedDatabaseIndexData(indexData);
        setDrawerState(true);
    }
    useSegmentPage('Metis Index Advisor Page');

    if (error) {
        <div>error</div>
    }

    useEffect(() => {
        const sortedHostsData: HostIndexAdvisor[] = [];
        if (data) {

            Object.keys(data)?.map((hostId) => {
                const hostInfo = data[hostId];
                const hostName = hostInfo.name;
                const databasesData = Object.values(hostInfo.databases).map(databaseData => databaseData).flat();
                const indexData = databasesData.flat().map(tableData => Object.values(tableData.indexes).map(indexData => indexData)).flat()
                const relevantQueries = indexData.map(index => Object.values(index.queries)).flat().filter(query => query.calls > 0);
                const calls = relevantQueries.reduce((acc, query) => acc + query.calls, 0);
                const queriesSuggestionsCount = relevantQueries.length
                const totalOldCost = relevantQueries.reduce((acc,curr) => acc += curr.old_cost, 0)
                const totalNewCost = relevantQueries.reduce((acc,curr) => acc += curr.new_cost, 0)
                const costSaved = totalOldCost - totalNewCost;
                const improvement = ((totalOldCost - totalNewCost) / totalOldCost * 100);

                sortedHostsData.push({
                    hostId,
                    hostName,
                    calls,
                    hostInfo,
                    queriesSuggestionsCount,
                    costSaved,
                    improvement
                })

                sortedHostsData.sort((a, b) => {
                    return b.queriesSuggestionsCount - a.queriesSuggestionsCount
                });

                setHostsData(sortedHostsData)
            });
        }
    }, [data]);

    const jumpToQuery = (hostId: string, databaseId: string, queryId: string) => {
        const url = `/monitoring/reports/${hostId}/${databaseId}/query-statistics?queryId=${queryId}`;
        window.open(url, '_blank');
    }

    return isLoading ? <></> : (
        <>
            <Breadcrumbs breadcrumbsList={[{name: 'Index Advisor', isActive: true}]} isGlobalPage/>
            <Flex justify="space-between">
                <TitleMain weight={600} ht>
                    Index Advisor
                </TitleMain>
            </Flex>
            <GridContainer container alignItems="center" open={false}>
                <Grid item xs={6} sx={{pl: 1}}>
                    <Typography weight="500">Host Name</Typography>
                </Grid>
                <Grid item xs={2} sx={{pl: 1}} style={{display: 'flex', justifyContent: 'center'}}>
                    <Typography weight="500">Weekly Calls</Typography>
                </Grid>
                <Grid item xs={2} sx={{pl: 1}} style={{display: 'flex', justifyContent: 'center'}}>
                    <Typography weight="500">Optimized Queries</Typography>
                </Grid>
                <Grid item xs={2} sx={{pl: 1}} style={{display: 'flex', justifyContent: 'center'}}>
                    <Typography weight="500">Improvement</Typography>
                </Grid>
            </GridContainer>
            <Container open={false}>
                {hostsData.filter((data) => data.costSaved >= 0).map((hostData) => {
                        return (
                            <Accordion
                                handleChange={handleChangeAccordion}
                                selectedItem={hostData.hostId}
                                expand={expanded.find((e) => e === hostData.hostId)}
                                id={hostData.hostId}
                                title={
                                    <HostGridListContainer container alignItems="center">
                                        <Grid item xs={6}>
                                            <Typography>{hostData.hostName}</Typography>
                                        </Grid>
                                        <Grid item xs={2} style={{display: 'flex', justifyContent: 'center'}}>
                                            <TagContainer>
                                                <TagLabel
                                                    type={hostData.calls > 0 ? 'info' : 'empty'}
                                                    title={numberWithCommas(hostData.calls)}/>
                                            </TagContainer>
                                        </Grid>
                                        <Grid item xs={2} style={{display: 'flex', justifyContent: 'center'}}>
                                            <TagContainer>
                                                <TagLabel type={hostData.queriesSuggestionsCount > 0 ? 'info' : 'empty'}
                                                          title={numberWithCommas(hostData.queriesSuggestionsCount)}/>
                                            </TagContainer>
                                        </Grid>
                                        <Grid item xs={2} style={{display: 'flex', justifyContent: 'center', paddingLeft: '20px'}}>
                                            <TagContainer>
                                                <TagLabel type={hostData.improvement > 0 ? 'success' : 'empty'}
                                                          title={hostData.improvement ? `${numberWithCommas(hostData.improvement)}%` : '-'}/>
                                            </TagContainer>
                                        </Grid>
                                    </HostGridListContainer>
                                }
                            >
                                {expanded && (
                                    <>
                                        <GridContainer container alignItems="center">
                                            <Grid item xs={4}>
                                                <Typography weight="500">Database Name</Typography>
                                            </Grid>
                                            <Grid item xs={2} style={{display: 'flex', justifyContent: 'center'}}>
                                                <Typography weight="500">DB Size</Typography>
                                            </Grid>
                                            <Grid item xs={2} style={{display: 'flex', justifyContent: 'center'}}>
                                                <Typography weight="500">Weekly Calls</Typography>
                                            </Grid>
                                            <Grid item xs={2} style={{display: 'flex', justifyContent: 'center'}}>
                                                <Typography weight="500">Optimized Queries</Typography>
                                            </Grid>
                                            <Grid item xs={2} style={{display: 'flex', justifyContent: 'center'}}>
                                                <Typography weight="500">Improvement</Typography>
                                            </Grid>
                                        </GridContainer>
                                        {Object.keys(hostData.hostInfo.databases).sort((databaseAId, databaseBId) => {
                                            const databaseDataA = hostData.hostInfo.databases[databaseAId];
                                            const indexDataA = Object.values(databaseDataA.indexes).map(indexData => indexData).flat()
                                            const relevantQueriesA = indexDataA.map(index => Object.values(index.queries)).flat().filter(query => query.calls > 0);
                                            const queriesCallsA = relevantQueriesA.reduce((acc, query) => acc + query.calls, 0);

                                            const databaseDataB = hostData.hostInfo.databases[databaseBId];
                                            const indexDataB = Object.values(databaseDataB.indexes).map(indexData => indexData).flat()
                                            const relevantQueriesB = indexDataB.map(index => Object.values(index.queries)).flat().filter(query => query.calls > 0);
                                            const queriesCallsB = relevantQueriesB.reduce((acc, query) => acc + query.calls, 0);

                                            if ((queriesCallsB && !queriesCallsA) || (queriesCallsB && queriesCallsA && queriesCallsB > queriesCallsA)) {
                                                return 1
                                            }
                                            return -1;
                                        }).map(databaseId => {
                                            const databaseData = hostData.hostInfo.databases[databaseId];
                                            const indexData = Object.values(databaseData.indexes).map(indexData => indexData).flat()
                                            const relevantQueries = indexData.map(index => Object.values(index.queries)).flat().filter(query => query.calls > 0);

                                            const queriesCalls = relevantQueries.reduce((acc, query) => acc + query.calls, 0);
                                            const isEmptyData = !queriesCalls || parseInt(queriesCalls) <= 0;
                                            const queriesSuggestionsCount = isEmptyData ? 0 : relevantQueries.length
                                            const databaseName = databaseData.name;
                                            const dbSize = databaseData?.dbSize;
                                            const totalOldCost = relevantQueries.reduce((acc,curr) => acc += curr.old_cost, 0);
                                            const totalNewCost = relevantQueries.reduce((acc,curr) => acc += curr.new_cost, 0);
                                            const costSaved = isEmptyData ? 0 : totalOldCost - totalNewCost;
                                            const isClickable = !!costSaved || !!queriesSuggestionsCount || !isEmptyData;
                                            const improvement = isEmptyData ? '-' : ((totalOldCost - totalNewCost) / totalOldCost * 100);
                                            return (
                                                <DatabaseGridListContainer isClickable={isClickable} container alignItems="center" onClick={() => isClickable && handleDatabaseSelected(hostData.hostId, databaseId, indexData)}>
                                                    <Grid item xs={4}>
                                                        <Typography>{databaseName}</Typography>
                                                    </Grid>
                                                    <Grid item xs={2} style={{display: 'flex', justifyContent: 'center'}}>
                                                        <Typography>{dbSize > 0 ? normalizeSizeFromKb(dbSize) : '0 KB'}</Typography>
                                                    </Grid>
                                                    <Grid item xs={2} style={{display: 'flex', justifyContent: 'center'}}>
                                                        <TagContainer>
                                                            <TagLabel
                                                                type={queriesCalls > 0 ? 'info' : 'empty'}
                                                                title={numberWithCommas(queriesCalls)}/>
                                                        </TagContainer>
                                                    </Grid>
                                                    <Grid item xs={2} style={{display: 'flex', justifyContent: 'center'}}>
                                                        <TagContainer>
                                                            <TagLabel
                                                                type={queriesSuggestionsCount > 0 ? 'info' : 'empty'}
                                                                title={numberWithCommas(queriesSuggestionsCount)}/>
                                                        </TagContainer>
                                                    </Grid>
                                                    <Grid item xs={2} style={{display: 'flex', justifyContent: 'center'}}>
                                                        <TagContainer>
                                                            <TagLabel type={improvement > 0 ? 'success' : 'empty'}
                                                                      title={isEmptyData ? '-' : improvement >= 0 ? `${numberWithCommas(improvement)}%` : 0}/>
                                                        </TagContainer>
                                                    </Grid>
                                                </DatabaseGridListContainer>
                                            )
                                        })}
                                    </>
                                )}
                            </Accordion>
                        )
                    }
                )}

                <MDrawer
                    onClose={() => setDrawerState(false)}
                    anchor="right"
                    open={drawerState}
                    variant={drawerState ? 'persistent' : undefined}
                >
                    <DrawerItemWrapper justify="start" align="start" direction="column">
                        <HeaderDrawer justify="space-between">
                            <TitleDrawer h3 weight="600">
                                Index Suggestions
                            </TitleDrawer>
                            <Flex margin="8px 16px">
                                <Close title="close-icon" onClick={() => setDrawerState(false)} />
                            </Flex>
                        </HeaderDrawer>
                        <BodyDrawer width="914px" justify="start" align="start" direction="column">
                            <Tabs sx={{ width: '100%', mt: 1 }} value={0}>
                                <Tab
                                    key={'index_suggestions'}
                                    sx={{ textTransform: 'none', fontWeight: 600, color: '#A80221FF' }}
                                    id={`query-tab-index_suggestions`}
                                    label={'Suggestions'}
                                />
                            </Tabs>
                            <TabPanel value={0} index={0}>
                                <TabWrapper>
                                    <Flex margin="12px 24px" direction="column" align="start">
                                        {selectedDatabaseIndexData.filter(indexSuggestion => {
                                            const isQueriesRelevant = Object.values(indexSuggestion?.queries).some(query => query?.calls > 0);
                                            return isQueriesRelevant && indexSuggestion.name
                                        }).map((indexSuggestion, i) => {

                                            const queryCount = Object.values(indexSuggestion.queries).filter(query => query?.calls > 0)?.length
                                            return (
                                                <IndexSuggestionContainer key={i}>
                                                    <Flex fw margin="0 0 12px 0">
                                                        <Divider />
                                                        <Flex>
                                                            <DividerTitle weight={600}>Index Suggestion {i + 1}</DividerTitle>
                                                        </Flex>
                                                        <Divider />
                                                    </Flex>

                                                    <CodeEditor
                                                        isCopyValue
                                                        language="sql"
                                                        readonly={true}
                                                        height="8vh"
                                                        width="100%"
                                                        value={formatSQL(indexSuggestion?.name?.replace(/`/g,'') || '')}
                                                    />

                                                    <TagLabelWrapper>
                                                            <TagLabel
                                                                title={`${queryCount} Queries Optimized`}
                                                                type={`info`}
                                                            />
                                                        <TagContainerWrapper>
                                                            <TagLabel
                                                                title={`Cost Saved: ${numberWithCommas(indexSuggestion?.total_old_cost - indexSuggestion.total_new_cost)}`}
                                                                type={`success`}
                                                            />
                                                        </TagContainerWrapper>
                                                    </TagLabelWrapper>

                                                    {shouldShowRelatedQueries(i) && (
                                                        <>
                                                    <Flex margin="8px 0">
                                                        <TitleDrawer weight="600">Related Queries</TitleDrawer>
                                                    </Flex>
                                                    {Object.keys(indexSuggestion?.queries).filter((queryId) => {
                                                        const queryData = indexSuggestion?.queries[queryId];
                                                        return queryData?.calls > 0 && queryData?.old_cost > queryData?.new_cost
                                                    }).map((queryId, j) => {
                                                        const queryData = indexSuggestion?.queries[queryId];
                                                        return (
                                                            <>
                                                                <CodeEditor
                                                                    isCopyValue
                                                                    language="sql"
                                                                    readonly={true}
                                                                    height="28vh"
                                                                    width="100%"
                                                                    value={formatSQL(queryData.name.replace(/`/g,''))}
                                                                />
                                                                <TagLabelWrapper>
                                                                <TagLabel
                                                                    title={`Weekly calls: ${numberWithCommas(queryData?.calls)}`}
                                                                    type={'info'}
                                                                />
                                                                <TagContainerWrapper>
                                                                <TagLabel
                                                                    title={`Cost Before: ${numberWithCommas(queryData?.old_cost)}`}
                                                                    type={'info'}
                                                                />
                                                                </TagContainerWrapper>
                                                                    <TagContainerWrapper>
                                                                        <TagLabel
                                                                            title={`Cost After: ${numberWithCommas(queryData?.new_cost)}`}
                                                                            type={'info'}
                                                                        />
                                                                    </TagContainerWrapper>
                                                                    <TagContainerWrapper>
                                                                        <TagLabel
                                                                            title={`Improvement: ${numberWithCommas((queryData?.old_cost - queryData?.new_cost) / queryData?.old_cost * 100)}%`}
                                                                            type={'success'}
                                                                        />
                                                                    </TagContainerWrapper>
                                                                    <TagContainerWrapper>
                                                                        <ButtonContainer
                                                                            onClick={() => jumpToQuery(selectedDatabase.hostId, selectedDatabase.databaseId, queryId)}
                                                                        >
                                                                            <SubTitle>Jump to Query</SubTitle>
                                                                            <Arrow />
                                                                        </ButtonContainer>
                                                                    </TagContainerWrapper>
                                                                </TagLabelWrapper>
                                                            </>
                                                        )
                                                    })}
                                                </>
                                        )}
                                            <Flex fw margin="-10px 0 15px -14px">
                                                <MoreWrapper onClick={() => changeShowRelatedQueriesByIndexId(i)}>
                                                    <SubTitle>{shouldShowRelatedQueries(i) ? 'Hide Related Queries' : 'Show Related Queries'}</SubTitle>
                                                    <MoreIcon />
                                                </MoreWrapper>
                                            </Flex>
                                            </IndexSuggestionContainer>
                                            )
                                        })}
                                    </Flex>
                                </TabWrapper>
                            </TabPanel>
                        </BodyDrawer>
                    </DrawerItemWrapper>
                </MDrawer>

            </Container>
        </>
    )
}
