import { atom } from 'recoil';

interface UserRoleType {
  key: string;
  level: number;
  description: string;
}

export type HostsApiKeyT = {
  [key: string]: {
    apiKey: string;
    apiKeyId: string;
  }
};

export interface UserSettings {
  defaultApiKey?: string;
  userRole?: UserRoleType;
  tenantId?: string;
  id?: string;
  hostsApiKey: HostsApiKeyT
}

export enum USER_ROLE {
  READER = 'ReadOnly',
  MEMBER = 'Member',
  ADMIN = 'Admin'
}

export const userSettingsAtom = atom<UserSettings>({
  key: 'userSettingsAtom',
  default: {}
});
