import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  Brush, Label,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import styled from 'styled-components';
import { ReactComponent as NoRecentActivityData } from '@icons/no-data-found-traces.svg';

import Flex from '../../../../core/Flex';
import { TextDrawer, TooltipContainer } from '../../../Reports/Reports.styled';
import { IdleIcon } from '../../ObservabilityDashboard.styled';
import { ShortDateFormat, getDuration, getFormattedDate, numberWithCommas } from '../../../../utils/utils';
import BasicSpinnerLoader from '../../../../components/Loader/BasicSpinnerLoader';
import Typography from '../../../../core/Typography';
import { filterOutDuplicateMinute } from '../../utils/data-format.util'
interface HChart {
  data?: any[];
  valueName?: string[];
  formatFunc?: (value: number) => void;
  isLoading: boolean;
  height?: number;
  reduceMargin?: boolean;
  dateFormatType?: ShortDateFormat;
  type?: 'bar' | 'area';
  showHeader?: boolean;
  threshold?: Threshold | null;
  yAxisFormatter?: (value: number) => string;
  yAxisProps?: {
    tickCount?: number;
    tickInterval?: number;
  }
}

interface Threshold {
    type: string;
    value: number;
}
const AreaColorList = ['#608EDC', '#00CB6C', '#03D0FF', '#ff790b'];
const BarColorList = ['#8C8FF5', '#F7836B', '#8FE5BD', '#ff790b99'];


const HChart = ({
                  data = [],
                  formatFunc,
                  isLoading = true,
                  valueName = ['Value'],
                  height = 150,
                  type = 'area',
                  reduceMargin = true,
                  dateFormatType = 'y',
                  showHeader = true,
                  threshold = null,
                  yAxisFormatter,
                  yAxisProps = {}
}: HChart) => {
  const colorList: string[] = type === 'bar' ? BarColorList : AreaColorList;
  const dataMax = Math.max(...data.map((v) => Number(v?.value || 0)));
  data = filterOutDuplicateMinute(data);
  const CustomTooltip = (res: any) => {
    const { active, payload } = res;
    if (active && payload && payload.length) {
      const tooltipData = payload[0]?.payload;
      const timeFrame = getDuration(data[0]?.time, data[1]?.time);
      return (
        <TooltipContainer>
          <DateDrawer>
            <TimeFrameContainer>
              <Title>{timeFrame}</Title>
            </TimeFrameContainer>
            <Title>{getFormattedDate(tooltipData?.time)}</Title>
          </DateDrawer>
          {valueName.map((v: string, i: number) => (
            <TooltipItem justify="start" key={v}>
              <IdleIcon circleColor={colorList[i]} />
              <Title weight="600">{v}</Title>
              <TextDrawer>
                {tooltipData?.[`tooltipValue${i ? i : ''}`] === 'N/A'
                  ? tooltipData?.[`tooltipValue${i ? i : ''}`]
                  : formatFunc
                    ? formatFunc(+tooltipData?.[`value${i ? i : ''}`] || 0)
                    : numberWithCommas(+tooltipData?.[`value${i ? i : ''}`], 1)}
              </TextDrawer>
            </TooltipItem>
          ))}
        </TooltipContainer>
      );
    }
    return null;
  };
  const Header = () => {
    if (!showHeader) return null;
    return (
      <ResponsiveTitle justify="start" isSmall={height < 200}>
        {valueName.map((value: string, i: number) => (
          <Flex key={i} margin={height < 200 ? '0' : '0 12px 0 0'}>
            <IdleIcon circleColor={colorList[i]} />
            <Typography>{value}</Typography>
          </Flex>
        ))}
      </ResponsiveTitle>
    );
  };
  if (type === 'bar') {
    return (
      <>
        <ResponsiveContainer width="99%" height={height}>
          {isLoading ? (
            <ChartWrapper>
              <BasicSpinnerLoader />
            </ChartWrapper>
          ) : data.length ? (
            <BarChart
              width={200}
              height={60}
              data={data}
              barSize={7}
              margin={{
                top: 16,
                right: 12,
                left: reduceMargin ? -10 : 5,
                bottom: 5
              }}
            >
              <XAxis
                dataKey="time"
                tickMargin={5}
                minTickGap={20}
                tickFormatter={(x) => getFormattedDate(x, dateFormatType)}
                interval="preserveEnd"
                tick={{ fill: '#B0B0CC', fontSize: 12 }}
                scale={undefined}
              />
              {height > 300 && <Brush height={10} stroke="#A7A8D7" startIndex={+(data?.length / 2).toFixed()} />}
              <Tooltip
                cursor={{ fill: data?.length > 10 ? '#F2F4F7' : 'transparent' }}
                wrapperStyle={{ outline: 'none' }}
                content={CustomTooltip}
              />
              <YAxis
                domain={[0, valueName[0] === 'Percent' ? 100 : 'auto']}
                minTickGap={dataMax === 1 ? 100 : undefined}
                tick={{ fill: '#B0B0CC', fontSize: 12 }}
                tickFormatter={(y) => (formatFunc ? formatFunc(y) : y)}
                {...yAxisProps}
              />
              {valueName.map((value: string, i: number) => (
                <Bar stackId="a" key={value} dataKey={i ? `value${i}` : 'value'} fill={BarColorList[i]} />
              ))}
            </BarChart>
          ) : (
            <ChartWrapper>
              <NoRecentActivityData height={24} width={34} />
              <EmptyChartText>No Data</EmptyChartText>
            </ChartWrapper>
          )}
        </ResponsiveContainer>
        <Header />
      </>
    );
  }
  return (
    <>
      <ResponsiveContainer width="99%" height={height}>
        {isLoading ? (
          <ChartWrapper>
            <BasicSpinnerLoader />
          </ChartWrapper>
        ) : data.length ? (
          <AreaChart
            width={200}
            height={60}
            data={data}
            margin={{
              top: 16,
              right: 12,
              left: reduceMargin ? -10 : 5,
              bottom: 5
            }}
          >
            <defs>
              <linearGradient id="colorUv0" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#0023DD" stopOpacity={0.7} />
                <stop offset="95%" stopColor="#0023DD" stopOpacity={0.1} />
              </linearGradient>
            </defs>
            <defs>
              <linearGradient id="colorUv1" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#00CB6C" stopOpacity={0.7} />
                <stop offset="95%" stopColor="#00CB6C" stopOpacity={0.1} />
              </linearGradient>
            </defs>
            <defs>
              <linearGradient id="colorUv2" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#03D0FF" stopOpacity={0.7} />
                <stop offset="95%" stopColor="#03D0FF" stopOpacity={0.1} />
              </linearGradient>
            </defs>
            <defs>
              <linearGradient id="colorUv3" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#FF790B" stopOpacity={1} />
                <stop offset="95%" stopColor="#FF790B" stopOpacity={0.4} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="time"
              tickMargin={5}
              minTickGap={20}
              tickFormatter={(x) => getFormattedDate(x, dateFormatType)}
              interval="preserveEnd"
              tick={{ fill: '#B0B0CC', fontSize: 12 }}
              scale={undefined}
            />
            {height > 300 && <Brush height={10} stroke="#A7A8D7" startIndex={+(data?.length / 2).toFixed()} />}
            <Tooltip wrapperStyle={{ outline: 'none' }} content={CustomTooltip} />
            <YAxis
              domain={[0, valueName[0] === 'Percent' ? 100 : 'auto']}
              minTickGap={dataMax === 1 ? 100 : undefined}
              tick={{ fill: '#B0B0CC', fontSize: 12 }}
              tickFormatter={(y) => yAxisFormatter? yAxisFormatter(y) : (formatFunc ? formatFunc(y) : y)}
            />
            {valueName.map((value: string, i: number) => (
              <Area
                key={value}
                type="monotone"
                // dot={<CustomizedDot />}
                dataKey={i ? `value${i}` : 'value'}
                stroke={AreaColorList[i]}
                fill={`url(#colorUv${i})`}
              />
            ))}
            <Area type="monotone" dataKey="na" stroke="red" />
            {threshold && <ReferenceLine y={threshold.value} stroke="red" strokeDasharray="3 3">
              <Label
                  value={`threshold ${numberWithCommas(threshold.value)} ${threshold.type}`}
                  position={'insideBottomLeft'}
                  style={{fontWeight: 'bold', fontSize: '12px'}}
              />
            </ReferenceLine>}
          </AreaChart>
        ) : (
          <ChartWrapper>
            <NoRecentActivityData height={24} width={34} />
            <EmptyChartText>No Data</EmptyChartText>
          </ChartWrapper>
        )}
      </ResponsiveContainer>
      <Header />
    </>
  );
};

export default HChart;

const EmptyChartText = styled(Typography)`
  text-align: center;
  color: ${({ theme }) => theme.colors.text.main};
`;
const ResponsiveTitle = styled(Flex)<{ isSmall: boolean }>`
  border-top: 1px solid ${({ theme }) => theme.colors.new.border};
  height: 28px;
  width: calc(100% - 12px);
  padding-left: 12px;
  padding-top: ${({ isSmall }) => (isSmall ? '0' : '12px')};
  margin-top: ${({ isSmall }) => (isSmall ? '-8px' : '0')};
`;
const ChartWrapper = styled(Flex)`
  height: inherit;
  justify-content: center;
  align-items: center;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.main};
`;

export const TimeFrameContainer = styled(Flex)`
  border-right: 2px solid ${({ theme }) => theme.colors.new.border};
  padding-right: 8px;
  margin-right: 8px;
`;
export const TooltipItem = styled(Flex)`
  padding: 2px 8px 4px;
`;
export const DateDrawer = styled(Flex)`
  padding: 4px 8px;
  justify-content: start;
  border-radius: 5px 5px 0 0;
  background-color: ${({ theme }) => theme.colors.grey[100]};
`;
