import styled from 'styled-components';
import { useMemo, useState } from 'react';
import { DataGridUI } from '../../../Reports/Reports.styled';
import TableLoader from '../../../../components/Loader/TableLoader';
import Typography from '../../../../core/Typography';
import { ValueFormatterParams } from 'ag-grid-community';
import SeveritiesSummary from '../../../../components/SeveritiesSummary/SeveritiesSummary';
import Search from '../../../../core/Search';
import Flex from '../../../../core/Flex';
import { SeveritiesI } from '../../../../types/Severity.type';
import HealthSlider from '../../../../core/Slider/HealthSlider';
import { numberWithCommas } from '../../../../utils/utils';
import { DBName, DBNameContainer, DatabaseContainer, FullAutoFlex } from '../../ObservabilityDashboard.styled';
import RdbmsIcon from '../../../../components/RdbmsIcon';
import useDebounce from '../../../../hooks/useDebounce';
import {RDBMS} from "../../../../utils/constants";

interface DatabaseTable {
  title: string;
  dbList?: DBHostTableItemT[];
  isLoading: boolean;
  cb: (data: DBHostTableItemT) => void;
  rdbms: RDBMS
}

export type DBHostTableItemT = {
  database_name?: string;
  name?: string;
  database_size_pretty: string;
  time?: string;
  database_id: number;
  value: number;
  insightsSummary?: SeveritiesI;
};

const healthFormatter = (params: ValueFormatterParams) => {
  return params?.data?.score && <HealthSlider isMinimize value={params?.data?.score} label={''} />;
};

const insightsFormatter = (params: ValueFormatterParams) => {
  return (
    params?.data?.insightsSummary && (
      <SeveritiesSummary
        severity_critical={params?.data?.insightsSummary?.severity_critical}
        severity_high={params?.data?.insightsSummary?.severity_high}
        severity_medium={params?.data?.insightsSummary?.severity_medium}
        severity_low={params?.data?.insightsSummary?.severity_low}
      />
    )
  );
};

const customComparator = (valueA: any, valueB: any, nodeA: any, nodeB: any) => {
  // Check if both rows have a score
  const scoreAExists = nodeA.data.score !== undefined;
  const scoreBExists = nodeB.data.score !== undefined;
  // If one row has a score and the other doesn't, prioritize the row with a score
  if (scoreAExists && !scoreBExists) {
    return -1;
  } else if (!scoreAExists && scoreBExists) {
    return 1;
  }

  // If both rows have a score or both don't, use default sorting
  return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
};

const DatabaseTable = ({ dbList = [], title, isLoading, cb, rdbms }: DatabaseTable) => {
  const [filterSearchParams, setFilterSearchParams] = useState<string>('');
  const debouncedSearchTerm = useDebounce(filterSearchParams, 500);
  const [columnDefs] = useState([
    {
      field: 'database_name',
      headerName: 'Name',
      sort: 'asc',
      flex: 1,
      cellRenderer: (params: ValueFormatterParams) => (
        <DBNameContainer>
          <Flex margin="0 12px 0 0 ">
            <RdbmsIcon />
          </Flex>
          <DBName>{params.data?.name}</DBName>
        </DBNameContainer>
      ),
      comparator: customComparator
    },
    {
      field: 'value',
      headerName: 'Size',
      sortable: true,
      valueFormatter: (params: any) => (params?.data?.value ? numberWithCommas(params?.data?.value) + ' KB' : '-'),
      flex: 0.7
    },
    {
      hide: rdbms !== RDBMS.postgres,
      headerName: 'Health',
      sortable: true,
      cellRenderer: healthFormatter,
      flex: 1,
      cellStyle: { justifyContent: 'start', alignItems: 'center', display: 'flex' },
      valueGetter: (params: ValueFormatterParams) => params?.data?.score,
      comparator: customComparator // Apply custom sorting
      // Apply custom sorting
    },
    {
      headerName: 'Insights',
      cellRenderer: insightsFormatter,
      flex: 0.8,
      cellStyle: { justifyContent: 'start', alignItems: 'center', display: 'flex' },
      valueGetter: (params: ValueFormatterParams) => params?.data?.insightsSummary
    }
  ]);

  const handleSelectDatabase = (row: any) => {
    if (row.data.score !== undefined) {
      cb(row.data);
    }
  };

  const filteredDBList = useMemo(
    () => dbList.filter((i) => i.name?.includes?.(debouncedSearchTerm)),
    [dbList.length, debouncedSearchTerm]
  );
  return (
    <DatabaseContainer>
      <HeaderContainer justify="space-between">
        <Title weight="600">{title}</Title>
        <FullAutoFlex margin="8px 0" justify="end" flex="0.24">
          <Search
            fullWidth
            width="100%"
            minWidth="200px"
            placeholder="Search Database"
            value={filterSearchParams}
            onSubmit={setFilterSearchParams}
            onChangeCallback={setFilterSearchParams}
          />
        </FullAutoFlex>
      </HeaderContainer>

      {isLoading ? (
        <TableLoader size={3} />
      ) : (
        <GridContainer className="ag-theme-material">
          <DataGridUI
            rowClassRules={{
              'disabled-row': (params: any) => !params?.data?.score
            }}
            rowData={filteredDBList}
            animateRows={true}
            columnDefs={columnDefs}
            suppressCellFocus={true}
            onRowClicked={handleSelectDatabase}
          />
        </GridContainer>
      )}
    </DatabaseContainer>
  );
};

export default DatabaseTable;

const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.main};
`;
const HeaderContainer = styled(Flex)`
  width: calc(100% - 32px);
`;
const GridContainer = styled.div`
  min-height: 140px;
  height: calc(100vh - 640px);
  min-height: 200px;
  width: calc(100% - 1px);
  border: 1px solid ${({ theme }) => theme.colors.grey[100]};
  overflow-y: auto;
`;
