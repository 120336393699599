export const insightTitleConversion = (title: string, allInsights: any): string => {
    if (title === 'Statistics Exist') {
        return 'Missing Table Statistics'
    }
    if (title === 'Primary key - exists') {
        return 'Missing a Primary Key'
    }
    if (title === 'Number of indexes') {
        const numberOfIndexes = allInsights?.metrics.find((metric: any) => metric.metric_name === 'num-of-indexes')?.value;
        if (numberOfIndexes === 0) {
            return 'No Indexes'
        }
    }
    if (title === 'Primary key - number of columns') {
        const numberOfPkColumns = allInsights?.metrics.find((metric: any) => metric.metric_name === 'pk-num-of-columns')?.value;
        if (numberOfPkColumns >= 3) {
            return 'The Primary Key has a large number of columns'
        }
    }


    return title;
}