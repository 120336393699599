import Analytics from 'analytics';
import segmentPlugin from '@analytics/segment';
import { useEffect } from 'react';
const analytics = false //import.meta.env.VITE_SEGMENT_KEY
  ? Analytics({
      app: 'metis-platform-web-app',
      plugins: [
        segmentPlugin({
          writeKey: import.meta.env.VITE_SEGMENT_KEY
        })
      ]
    })
  : undefined;

export const analyticsIdentity = (title: string, payload: any) => {
  analytics?.identify(title, payload);
};

export const analyticsTrack = (title: string, payload?: any) => {
  analytics?.track(title, payload);
};

export const useSegmentPage = (pageName: string) => {
  useEffect(() => {
    if (analytics) {
      analytics.page({ name: pageName });
    }
  }, []);
};
