import styled from 'styled-components';
import CountUp from 'react-countup';

import Flex from '../../../../core/Flex';
import Typography from '../../../../core/Typography';
import SeveritiesSummary from '../../../../components/SeveritiesSummary/SeveritiesSummary';

interface MCountDetailsIP {
  title?: string;
  count?: number;
  isLoading?: boolean;
  showCount?: boolean;
  data?: any;
}

const MCountDetailsIP = ({ title, count = 0, isLoading = false, data, showCount = true }: MCountDetailsIP) => {
  return (
    <Container direction="column" justify={title && showCount ? 'space-between' : 'center'}>
      {title && (
        <Flex direction="column" justify="start">
          <TitleText weight="600">{title}</TitleText>
          {!isLoading && showCount && (
            <TitleText large weight="600">
              <CountUp duration={0.5} end={count} />
            </TitleText>
          )}
        </Flex>
      )}

      <Flex direction="column" justify="start">
        {data && (
          <SeveritiesSummary
            severity_critical={data?.severity_critical}
            severity_high={data?.severity_high}
            severity_medium={data?.severity_medium}
            severity_low={data?.severity_low}
            isLoading={isLoading}
          />
        )}
      </Flex>
    </Container>
  );
};

export default MCountDetailsIP;

export const TitleText = styled(Typography)`
  padding: 10px;
  color: ${({ theme }) => theme.colors.text.main};
`;
export const Container = styled(Flex)`
  min-height: 152px;
  padding: 24px 16px;
`;
