import dayjs from 'dayjs';
import { PriorityTextToNumber } from '../../../enums';
import { Assertion } from '../../../types/Assertion.type';
import { SeveritiesI } from '../../../types/Severity.type';

export const getAssertions = (insight: any): Assertion[] => {
  return insight?.length
    ? insight?.map((i: any) => ({
        ...i,
        ruleId: i?.insight_id,
        priority: PriorityTextToNumber[i?.priority]
      }))
    : [];
};

export const getSSAssertions = (showAll: boolean, selected: any) => {
  const getUpdatedAssertionDetails = (insight: any): Assertion => ({
    ...insight,
    title: insight?.title || insight?.rule?.title,
    ruleId: insight?.ruleId || insight?.rule?.id,
    priority: insight?.priority || insight?.rule?.priority || insight?.condition?.event?.priority
  });

  if (showAll && selected?.assertions) {
    const allAssertions = [
      ...selected.assertions.fail,
      ...selected.assertions.success,
      ...selected.assertions.skipped,
      ...selected.assertions.errors
    ];
    return allAssertions.map((a: Assertion) => getUpdatedAssertionDetails(a));
  }
  return selected?.assertions?.fail?.map((a: Assertion) => getUpdatedAssertionDetails(a)) || [];
};
export const ReportTabState = ['Columns', 'Size', 'Activity', 'Insights'];
export const TableTabState = ['Columns', 'Indexes', 'Size', 'Activity', 'Insights'];
export const PostgreIndexTabState = ['Schema','Size','Activity', 'Insights'];
export const MysqlIndexTabState = ['Schema','Activity', 'Insights'];
export const ReportIndexState = ['Insights'];

export const QueryTabState = ['SQL', 'Insights', 'AI Advice', 'Execution Plan', 'Plan JSON'];

export const populateZeros = (DAILY_BAR_LENGTH: number, values: any) => {
  const restDays: number = DAILY_BAR_LENGTH - values.length;
  for (let i = 1; i <= restDays; i++) {
    values.unshift({
      time: dayjs(values[0].time).subtract(1, 'days').format(),
      value: 0
    });
  }
};
export const sortSeverityTable = (insightsA: SeveritiesI, insightsB: SeveritiesI): number => {
  const sortSeverityItem = (a: any, b: any, item: string) => {
    if (a?.[item] === b?.[item]) return 0;
    return a?.[item] > b?.[item] ? 1 : -1;
  };
  if (insightsA?.severity_critical > 0 || insightsB?.severity_critical > 0)
    return sortSeverityItem(insightsA, insightsB, 'severity_critical');
  if (insightsA?.severity_high > 0 || insightsB?.severity_high > 0)
    return sortSeverityItem(insightsA, insightsB, 'severity_high');
  if (insightsA?.severity_medium > 0 || insightsB?.severity_medium > 0)
    return sortSeverityItem(insightsA, insightsB, 'severity_medium');
  return sortSeverityItem(insightsA, insightsB, 'severity_low');
};



export * from './dbParserTypes'
export * from './parseExplainData'
export * from './mySQLNodeBuilder'

