import React from 'react';

import { Error } from '../components/Error/Error';

const Home = React.lazy(() => import('./Home'));
const ErrorPage = React.lazy(() => import('./ErrorPage/ErrorPage'));
const RecentActivity = React.lazy(() => import('./LogAnalysis/LogAnalyzer'));
const TestPage = React.lazy(() => import('./TestPage/TestPage'));
const PerformanceDetails = React.lazy(() => import('./PerformanceDetails/PerformanceDetails'));
const Login = React.lazy(() => import('./Login/Login'));
const Boarding = React.lazy(() => import('./Boarding/Boarding'));
const General = React.lazy(() => import('./Settings/General'));
const Logs = React.lazy(() => import('./Settings/Logs'));
const Enrichment = React.lazy(() => import('./Settings/Enrichment'));
const Reports = React.lazy(() => import('./Reports/Reports'));
const QueryAnalyzer = React.lazy(() => import('./QueryAnalyzer/QueryAnalyzer'));
const Projects = React.lazy(() => import('./Projects/Projects'));
// const ProjectItem = React.lazy(() => import('./Projects/ProjectItem/Project'));
const ProjectItem = React.lazy(() => import('./Projects/ProjectItem/ProjectItem'));
const SchemaAnalysis = React.lazy(() => import('./SchemaAnalysis/SchemaAnalysis'));
const TableAnalysis = React.lazy(() => import('./SchemaAnalysis/TableAnalysis/TableAnalysis'));
const SchemaMigration = React.lazy(() => import('./SchemaMigration/SchemaMigration'));
const MigrationAnalysis = React.lazy(() => import('./SchemaMigration/MigrationAnalysis/MigrationAnalysis'));
const ProductionIssues = React.lazy(() => import('./ProductionIssues/ProductionIssues'));
const ObservabilityDashboard = React.lazy(() => import('./ObservabilityDashboard/ObservabilityDashboard'));
const HostDashboard = React.lazy(() => import('./ObservabilityDashboard/HostDashboard'));
const Monitoring = React.lazy(() => import('./Monitoring/Monitoring'));
const Integrations = React.lazy(() => import('./Integrations/Integrations'));
const Alerts = React.lazy(() => import('./Alerts/Alerts'));
const Rules = React.lazy(() => import('./Rules/Rules'));
const NewRule = React.lazy(() => import('./Rules/NewRule'));
const ComposedRules = React.lazy(() => import('./Rules/ComposedRules'));
const WelcomePage = React.lazy(() => import('./WelcomePage/WelcomePage'));
const MetisCompare = React.lazy(() => import('./WelcomePage/WelcomePage'));

export {
  PerformanceDetails,
  ErrorPage,
  General,
  Login,
  Home,
  Error,
  Enrichment,
  Reports,
  RecentActivity,
  Boarding,
  QueryAnalyzer,
  Projects,
  ProjectItem,
  SchemaAnalysis,
  TableAnalysis,
  SchemaMigration,
  MigrationAnalysis,
  ObservabilityDashboard,
  HostDashboard,
  TestPage,
  Logs,
  Monitoring,
  ProductionIssues,
  Integrations,
  Alerts,
  Rules,
  ComposedRules,
  NewRule,
  WelcomePage,
  MetisCompare
};
