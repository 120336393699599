import { atom, selector } from 'recoil';
import { Config, DataIndex, DataTable, Extension, QueryStats, ReportAtomState } from '../routes/Reports/reports/types';
import { RDBMS } from '../utils/constants';

export interface reportsStateI {
  selectedType: ReportAtomState;
  databaseId: string;
  rdbms: RDBMS | '';
  dataTable: {
    lastUpdateIsoDate: string;
    rows: DataTable[];
  };
  dataIndex: {
    lastUpdateIsoDate: string;
    rows: DataIndex[];
  };
  dataQueryStats: {
    lastUpdateIsoDate: string;
    rows: QueryStats[];
    offset: number;
    sql?: string;
    sortBy?: string;
    sortOrder?: string;
  };
  dataExtension: {
    lastUpdateIsoDate: string;
    rows: Extension[];
  };
  dataDBConfig: {
    lastUpdateIsoDate: string;
    rows: Config[];
  };
}

export const reportsStateAtom = atom<reportsStateI>({
  key: 'reportsState',
  default: {
    selectedType: 'dataTable',
    databaseId: '',
    rdbms: '',
    dataTable: {
      lastUpdateIsoDate: '',
      rows: []
    },
    dataIndex: {
      lastUpdateIsoDate: '',
      rows: []
    },
    dataQueryStats: {
      lastUpdateIsoDate: '',
      rows: [],
      offset: 0,
      sortBy: 'insights',
      sortOrder: 'desc',
      sql: undefined
    },
    dataExtension: {
      lastUpdateIsoDate: '',
      rows: []
    },
    dataDBConfig: {
      lastUpdateIsoDate: '',
      rows: []
    }
  }
});

export const reportsStateSelector = selector<any>({
  key: 'reportsStateSelector',
  get: ({ get }: any): reportsStateI[ReportAtomState] => {
    const state = get(reportsStateAtom);
    return state[state.selectedType];
  }
});
