import styled, { css } from 'styled-components';
import Flex from '../../core/Flex';
import Typography from '../../core/Typography';
import { pulseAnimation } from '../../utils/animation';

export const RulerContainer = styled.div`
  position: relative;
  width: inherit;
  background: linear-gradient(#F5FBF 20%, #fff 20%, #F5FBF 20%, #fff 20%);
  box-shadow:
    0px 1px 1px rgba(9, 18, 53, 0.18),
    0px 4px 4px rgba(34, 36, 94, 0.07);
  border-radius: 4px;
  border: 1px solid #d7d7e3;
`;

export const LinesLabelsContainer = styled(Flex)`
  z-index: 2;
  height: 35px;
  width: 100%;
  border-bottom: 1px solid #d7d7e3;
  position: relative;
  border-radius: 4px 4px 0 0;
`;
export const Loader = styled(Flex)`
  z-index: 2;
  width: 100%;
  padding: 5px 0;
`;
//'#F5FBFF'
export const ColoredBG = styled.div<any>`
  background: ${({ colored }) => (colored ? '#FFF' : '#FFF')};
  z-index: 1;
  position: absolute;
  left: ${({ per }: any) => css`
    calc(100% - ${per})
  `};
  transform: translateX(-100%);
  width: ${({ per }: any) => css`
  calc(100% - ${per})
`};
  height: 100%;
  border-radius: 3px;
`;

export const LabelH = styled.div<any>`
  position: absolute;
  left: ${({ per }: any) => css`
    calc(100% - ${per})
  `};
  transform: translateX(-100%);
  width: ${({ per }: any) => css`
  calc(100% - ${per})
`};
  height: 100%;
`;

export const Line = styled.div<any>`
  background: ${({ theme }) => theme.colors.grey[200]};
  opacity: 0.5;
  z-index: 1;
  position: absolute;
  width: 1px;
  height: 100%;
  left: ${({ per }: any) => css`
    calc(100% - ${per})
  `};
`;

export const LongTypography = styled(Typography)`
  max-width: 210px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ color, isFailed, theme }) => (isFailed ? theme.colors.error.critical : color ? color : '#000')};
`;

export const SpansContainer = styled.div<{ isScrolled: boolean }>`
  width: ${({ isScrolled }) => (isScrolled ? 'calc(100% + 4px)' : '100%')};
  margin: ${({ isScrolled }) => (isScrolled ? '0 0 0 4px' : 0)};
  height: 100px;
  overflow-y: auto;
  overflow-x: hide;
  z-index: 2;
  border-radius: 0 0 3px 3px;
`;

export const SpanLabel = styled.div`
  margin-left: 5%;
`;

export const Label = styled(Typography)`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  margin-right: ${({ marginRight }) => `${marginRight || 0}`}px;
  margin-left: ${({ marginLeft }) => `${marginLeft || 0}`}px;
  margin-top: ${({ marginTop }) => `${marginTop || 0}`}px;
  color: ${({ theme, color }) => color || theme.colors.new.secondary.main};
`;
export const RouteLabel = styled(Typography)`
  margin-right: ${({ marginRight }) => `${marginRight || 0}`}px;
  margin-left: ${({ marginLeft }) => `${marginLeft || 0}`}px;
  color: ${({ theme }) => theme.colors.text.title};
`;

export const SpansContext = styled.div<any>`
  width: ${({ contextLine }) => (contextLine > 0 ? contextLine : 1)}%;
  margin-left: ${({ contextLeftShifting }) => contextLeftShifting}%;
  background: ${({ routeSpan, theme, active }) => (active ? '#66C8FF' : routeSpan ? theme.colors.grey[200] : '#7285f0')};
  height: 20px;
  border-radius: 15px;
  color: ${({ theme, routeSpan }) => (routeSpan ? '#000' : theme.colors.white)};
  ${({ active }) =>
    active &&
    css`
      animation-name: ${pulseAnimation};
      animation-duration: 2s;
      animation-iteration-count: infinite;
      border: 1px solid #383975;
      color: #383975;
      box-shadow:
        0px 1px 6px rgba(0, 0, 0, 0.3),
        0px 1px 1px rgba(9, 18, 53, 0.25),
        0px 4px 4px rgba(34, 36, 94, 0.2);
    `}
`;

export const SpanContainer = styled(Flex)<any>`
  width: 100%;
  cursor: ${({ routeSpan }) => (routeSpan ? 'default' : 'pointer')};
  margin-top: ${({ queryIndex, queriesAmount }) => (queryIndex === 0 && queriesAmount > 3 ? 0 : 0)}px;
  min-height: 20px;
  align-items: center;
  border-bottom: 1px solid #d7d7e3;
  z-index: 2;
  background: ${({ active, theme }) => active && theme.colors.grey[100]};
  &:hover {
    background: ${({ theme, routeSpan }) => !routeSpan && theme.colors.grey[100]};
  }
`;

// ${({ active }) =>
// active &&
// css`
//   animation-name: ${breatheAnimation};
//   animation-duration: 1s;
//   animation-iteration-count: infinite;
//   background: ${({ theme }) => theme.colors.grey[100]};
// `}
