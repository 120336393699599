import Flex from '../../core/Flex';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { analyticsTrack, useSegmentPage } from '../../utils/segment-analytics';

import { ApiKeyType, useActiveAPIKey } from '../../atoms/api-keys.atom';
import { reportsStateAtom, reportsStateI } from '../../atoms/reports.atom';
import {useSetRecoilState} from 'recoil';
import DbEnrichmentSetting from '../../components/DbEnrichmentSettings/DbEnrichmentSettings';
import { Title } from '../Boarding/Boarding.styled';

const ProductionTab = () => {
  useSegmentPage('ProductionTab');
  const { projectApiKey = '' } = useParams();
  const { pathname }: any = useLocation();
  const navigate = useNavigate();
  const selectedStateApiKey: ApiKeyType = useActiveAPIKey(projectApiKey);
  const setReportsState = useSetRecoilState(reportsStateAtom);

  const handleSelectedDb = (hostId: number, databaseId: number) => {
    analyticsTrack('Select DB', { hostId, databaseId });

    const navigateTo = `${hostId}/${databaseId}`;
    setReportsState((oldReports: reportsStateI) => ({
      ...oldReports,
      dataQueryStats: oldReports.dataQueryStats
    }));
    navigate(navigateTo, {
      state: {
        DBhost: hostId,
        name: selectedStateApiKey.label,
        navigateTo: `${pathname}/`
      }
    });
  };

  return (
    <>
      <Flex justify="start" margin="0 0 8px 12px">
        <Title h4 weight="600">
          Production Enrichment
        </Title>
      </Flex>
      <DbEnrichmentSetting selectedApiKey={projectApiKey} handleSelectedDb={handleSelectedDb} />
    </>
  );
};

export default ProductionTab;
