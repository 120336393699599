import styled from 'styled-components';
import Typography from "../../../core/Typography";

interface StyledCodeBlockProps {
    color?: string;
    paddingLeft?: string;
}


export const ConsistentDiv = styled(Typography)`
    margin: 14px 0;
`;

export const StyledCodeBlock = styled.code<StyledCodeBlockProps>`
    background-color: #e0e0e0;
    border-radius: 3px;
    padding: 2px 4px 2px ${({paddingLeft}) => paddingLeft || '4px'};
`;
