import type { NodeTypes } from '@xyflow/react';
import { SQLExplainNodeComponent } from './SQLExplainNodeComponent';
import {SQLSubQueryComponent} from './SQLSubQueryComponent'
import { AppNode } from './types';

export const initialNodes: AppNode[] = [];

export const nodeTypes = {
  'sql-explain-node': SQLExplainNodeComponent,
  'sql-sub-query-node': SQLSubQueryComponent,
} satisfies NodeTypes;
