import { ReactComponent as ArrowIcon } from '@icons/arrow-down.svg';
import styled from 'styled-components';
import { ReactComponent as ProjectI } from '@icons/project.svg';

import Typography from '../Typography';
import Flex from '../Flex';
import { SideMenuWidth } from '../../theme';

export const TitleMain = styled(Typography)`
  color: ${({ theme }) => theme.colors.new.secondary.main};
  line-height: 38px;
`;

export const ProjectIcon = styled(ProjectI)`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  stroke: ${({ theme }) => theme.colors.text.main};
`;

export const GlobalImgWrapper = styled(Flex)`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  stroke: ${({ theme }) => theme.colors.text.main};
`;

export const Container = styled(Flex)`
  width: calc(100% - ${SideMenuWidth} - 12px);
  top: 0;
  height: 36px;
  left: ${SideMenuWidth};
  position: fixed;
  z-index: 2;
  padding-left: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[150]};
  background-color: ${({ theme }) => theme.colors.white};
`;
export const BreadcrumbsImg = styled(ArrowIcon)`
  height: 16px;
  width: 16px;
  cursor: auto;
  transform: rotate(-90deg);
  fill: ${({ theme }) => theme.colors.text.main};
  stroke: ${({ theme }) => theme.colors.text.main};
`;

export const BreadcrumbsText = styled(Typography)<{ isActive: boolean }>`
  cursor: pointer;
  max-width: 420px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 8px;
  font-weight: ${({ isActive }) => (isActive ? 500 : 600)};
  color: ${({ theme, isActive }) => (isActive ? theme.colors.info.main : theme.colors.grey[500])};
  &:hover {
    color: ${({ theme }) => theme.colors.new.primary.main};
    background: ${({ theme }) => theme.colors.grey[50]};
    border-radius: 6px;
  }
`;
