import styled from 'styled-components';
import CountUp from 'react-countup';
import { Grid } from '@mui/material';
import { Skeleton } from '@mui/material';

import Flex from '../../../../core/Flex';
import { TrendDataGridCell } from '../../../Reports/common/TrendDataGridCell';
import MTooltip from '../../../../core/Tooltip/Tooltip';
import Typography from '../../../../core/Typography';
import SeveritiesSummary from '../../../../components/SeveritiesSummary/SeveritiesSummary';
import { EmptyText } from '../ODTables/ODTables';

interface MIndexesI {
  unusedIndexes?: number;
  isLoading: boolean;
  mostUsedIndexes?: {
    mostUsedIndexesDailyGrowth: any;
  };
}

const MIndexes = ({ unusedIndexes = 0, mostUsedIndexes, isLoading }: MIndexesI) => {
  return (
    <Container align="start">
      <Grid container>
        <Grid item xs={3.5}>
          <GridIndexItem justify="space-between">
            <Flex direction="column">
              <TitleText weight="600">Unused Indexes</TitleText>
              <TitleText large weight="600">
                {isLoading ? <Skeleton variant="text" width={40} height={55} /> : <CountUp duration={0.5} end={unusedIndexes} />}
              </TitleText>
            </Flex>

            {isLoading ? (
              <Flex justify="space-between">
                <Skeleton variant="text" width={25} height={22} />
                <Skeleton sx={{ ml: 1.5 }} variant="text" width={25} height={22} />
                <Skeleton sx={{ ml: 1.5 }} variant="text" width={25} height={22} />
                <Skeleton sx={{ ml: 1.5 }} variant="text" width={25} height={22} />
              </Flex>
            ) : (
              <Flex direction="column" justify="start" margin="0 15px 0 0">
                <SeveritiesSummary severity_critical={0} severity_high={0} severity_medium={unusedIndexes} severity_low={0} />
              </Flex>
            )}
          </GridIndexItem>
        </Grid>
        <Grid item xs={8.5}>
          <GridItem>
            <Flex>
              <TitleText weight="600">Most Used Indexes</TitleText>
              <TitleTextPd> within the last 7 days</TitleTextPd>
            </Flex>
            <Wrapper margin="20px 0 0 0">
              {isLoading ? (
                <Wrapper direction="column">
                  <Wrapper>
                    <Skeleton variant="text" width={150} height={20} sx={{ ml: '24px' }} />
                    <Skeleton variant="text" width={150} height={20} sx={{ ml: '24px' }} />
                    <Skeleton variant="text" width={150} height={20} sx={{ ml: '24px' }} />
                  </Wrapper>
                  <Wrapper>
                    <Skeleton variant="text" width={150} height={70} sx={{ ml: '24px' }} />
                    <Skeleton variant="text" width={150} height={70} sx={{ ml: '24px' }} />
                    <Skeleton variant="text" width={150} height={70} sx={{ ml: '24px' }} />
                  </Wrapper>
                </Wrapper>
              ) : mostUsedIndexes?.mostUsedIndexesDailyGrowth?.length ? (
                mostUsedIndexes?.mostUsedIndexesDailyGrowth?.map((item: any, inx: number) => (
                  <Flex key={item?.index_name + inx} direction="column" align="start" margin="0 16px">
                    <MTooltip disable={item?.index_name?.length < 25} title={item?.index_name}>
                      <MTitle weight="600">{item?.index_name}</MTitle>
                    </MTooltip>
                    <CellWrapper>
                      <TrendDataGridCell
                        width={170}
                        payloadName="calls"
                        data={item?.data?.map((i: { date: string; value: string }) => ({
                          ...i,
                          value: +i.value || 0,
                          time: i.date
                        }))}
                        title="Max in 7 days: "
                      />
                    </CellWrapper>
                  </Flex>
                ))
              ) : (
                <EmptyText />
              )}
            </Wrapper>
          </GridItem>
        </Grid>
      </Grid>
    </Container>
  );
};

export const Container = styled(Flex)`
  min-height: 200px;
  width: 100%;
  overflow: auto;
`;

export const TitleText = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.main};
  padding: 8px 0;
`;

export const TitleTextPd = styled(Typography)`
  padding-left: 16px;
`;

export const MTitle = styled(Typography)`
  padding: 4px 0 8px;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.text.main};
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;
`;
export const Wrapper = styled(Flex)`
  width: 100%;
`;
export const CellWrapper = styled(Flex)`
  margin-top: 4px;
`;
export const GridItem = styled(Flex)`
  padding: 12px 16px;
  align-items: start;
  flex-direction: column;
`;
export const GridIndexItem = styled(GridItem)`
  align-items: center;
  min-height: 152px;
  padding: 0 10px;
  margin: 12px 16px;
  border-right: 2px solid ${({ theme }) => theme.colors.grey[150]};
`;

export const SeverityImg = styled(Flex)`
  padding: 0 12px 0 5px;
`;

export default MIndexes;
