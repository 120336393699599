import styled from 'styled-components';
import { DialogTitle } from '@mui/material';
import { ReactComponent as CloseIcon } from '@icons/close.svg';

export const TitleDialog = styled(DialogTitle)`
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.secondary.hover};
`;

export const Close = styled(CloseIcon)`
  height: 15px;
  cursor: pointer;
  stroke: ${({ theme }) => theme.colors.white};
`;

export const ActionsWrapper = styled.div`
  display: flex;
  padding: 0 16px;
  flex-direction: column;
  border-top: solid 1px ${({ theme }) => theme.colors.grey[100]};
`;
