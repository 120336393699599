import React from 'react';

import { AccordionItem, AccordionWrapper, Details, Header } from './Accordion.styled';
import { ReactComponent as Arrow } from '@icons/accordion-arrow.svg';
import styled from 'styled-components';
import { recentActivityListI } from '../../atoms/log-analyzer.atom';

interface AccordionProps {
  isDisabled?: boolean;
  children: React.ReactNode;
  title: React.ReactNode;
  expand?: string | boolean;
  id?: string | boolean;
  selectedItem?: any;
  handleChange?: (selectedLog: recentActivityListI | any) => any;
}

const Accordion = ({ isDisabled, children, title, expand = false, handleChange, id = '', selectedItem }: AccordionProps) => {
  return (
    <AccordionWrapper disableGutters disabled={isDisabled} expanded={expand == id} onChange={handleChange?.(selectedItem)}>
      <AccordionItem expandIcon={<ArrowIcon title="arrow" />} aria-controls="panel1a-content" id="panel1a-header">
        <AccordionItemIconWrapper />
        <Header container>{title}</Header>
      </AccordionItem>

      {expand == id && <Details>{children}</Details>}
    </AccordionWrapper>
  );
};

export default Accordion;

export const ArrowIcon = styled(Arrow)`
  stroke: ${({ theme }) => theme.colors.new.primary.main}};
  height: 24px;
  width: 24px;
  transform: rotate(90deg);
`;
export const AccordionItemIconWrapper = styled.div`
  position: absolute;
  top: 8px;
  left: 0;
`;
