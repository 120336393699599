import styled from 'styled-components';
import { Skeleton } from '@mui/material';

import Flex from '../../../../core/Flex';
import Typography from '../../../../core/Typography';
import { TrendDataGridCell } from '../../../Reports/common/TrendDataGridCell';
import { numberWithCommas } from '../../../../utils/utils';
import { SeveritiesI } from '../../../../types/Severity.type';
import SeveritiesSummary from '../../../../components/SeveritiesSummary/SeveritiesSummary';

interface ODQueriesI {
  countQueriesPerHour: any[];
  isLoading: boolean;
  severity?: SeveritiesI;
}

const NOT_ENOUGH_DATA_PHRASE = () => <Typography>Not enough data to show a trend.</Typography>;

const ODQueries = ({ countQueriesPerHour = [], isLoading, severity }: ODQueriesI) => {
  const maximumPerDay = countQueriesPerHour.reduce((partialSum: any, a: { value: any }) => Math.max(partialSum, +a.value), 0);

  return (
    <Container justify="center" align="center">
      {isLoading ? (
        <Flex direction="column" align="start" margin="24px 16px">
          <Skeleton variant="text" width={250} height={24} sx={{ mb: 1 }} />
          <Skeleton variant="text" width={250} height={50} />
        </Flex>
      ) : countQueriesPerHour.length ? (
        <Flex direction="column">
          <WContainer>
            <SeveritiesSummary
              severity_critical={severity?.severity_critical}
              severity_high={severity?.severity_high}
              severity_medium={severity?.severity_medium}
              severity_low={severity?.severity_low}
              isLoading={isLoading}
            />
          </WContainer>
          <Flex>
            <Title weight={600}>Calls per hour</Title>
            <Title pd>last 24 hours</Title>
          </Flex>
          <Flex justify="center" direction="column">
            <Typography s> Max on last day: {numberWithCommas(maximumPerDay)} calls</Typography>
            <TrendDataGridCell
              payloadName="calls"
              width={250}
              hideTitle
              data={countQueriesPerHour.map((query) => ({ ...query, value: +query?.value || 0 }))}
              dateFormat="s"
            />
          </Flex>
        </Flex>
      ) : (
        <NOT_ENOUGH_DATA_PHRASE />
      )}
    </Container>
  );
};

const Container = styled(Flex)`
  min-height: 200px;
  overflow: auto;
  flex-direction: column;
`;

const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.main};
  padding-left: ${({ pd }) => (pd ? '16px' : 0)};
`;
const WContainer = styled(Flex)`
  padding-bottom: 10px;
  margin-bottom: 12px;
  // border-bottom: 2px solid ${({ theme }) => theme.colors.grey[150]};
`;

export default ODQueries;
