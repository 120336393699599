import Flex from '../../core/Flex';
import styled from 'styled-components';
import Typography from '../../core/Typography';
import { toNumbersSuffix } from '../../utils/utils';
import { SeveritiesI } from '../../types/Severity.type';
import { Skeleton } from '@mui/material';
import { ReactComponent as Check } from '@icons/severity-check.svg';

interface SeveritiesSummaryPropsI {
  showAll?: boolean;
  isLoading?: boolean;
}

const SeveritiesSummary = ({
  severity_critical,
  severity_high,
  severity_medium,
  severity_low,
  showAll = true,
  isLoading = false
}: SeveritiesSummaryPropsI & SeveritiesI) => {
  if (isLoading) {
    return (
      <SeveritiesContainer direction="row">
        <Skeleton sx={{ mr: 2 }} animation="pulse" variant="rectangular" width={40} height={23} />
        <Skeleton sx={{ mr: 2 }} animation="pulse" variant="rectangular" width={40} height={23} />
        <Skeleton sx={{ mr: 2 }} animation="pulse" variant="rectangular" width={40} height={23} />
        <Skeleton sx={{ mr: 2 }} animation="pulse" variant="rectangular" width={40} height={23} />
      </SeveritiesContainer>
    );
  }
  if (severity_critical === 0 && severity_high === 0 && severity_medium === 0 && severity_low === 0) {
    return (
      <SeveritiesContainer>
        <SeveritiesItem>
          <CheckSeverity />
          <Check width="40px" />
        </SeveritiesItem>
      </SeveritiesContainer>
    );
  }
  return (
    <SeveritiesContainer direction="row">
      {severity_critical != undefined && (severity_critical > 0 || showAll) && (
        <SeveritiesItem>
          {severity_critical > 0 ? (
            <>
              <CriticalSeverity />
              <CriticalLabel s>C</CriticalLabel>
            </>
          ) : (
            <EmptySeverity />
          )}
          <TypographySecondary s isEmpty={!severity_critical}>
            {toNumbersSuffix(severity_critical, 0).num}
            {toNumbersSuffix(severity_critical, 0).suffix}
          </TypographySecondary>
        </SeveritiesItem>
      )}
      {severity_high != undefined && (severity_high > 0 || showAll) && (
        <SeveritiesItem>
          {severity_high > 0 ? (
            <>
              <HighSeverity />
              <HighLabel s>H</HighLabel>
            </>
          ) : (
            <EmptySeverity />
          )}
          <TypographySecondary s isEmpty={!severity_high}>
            {toNumbersSuffix(severity_high, 0).num}
            {toNumbersSuffix(severity_high, 0).suffix}
          </TypographySecondary>
        </SeveritiesItem>
      )}
      {severity_medium != undefined && (severity_medium > 0 || showAll) && (
        <SeveritiesItem>
          {severity_medium > 0 ? (
            <>
              <MediumSeverity />
              <MediumLabel s>M</MediumLabel>
            </>
          ) : (
            <EmptySeverity />
          )}
          <TypographySecondary s isEmpty={!severity_medium}>
            {toNumbersSuffix(severity_medium, 0).num}
            {toNumbersSuffix(severity_medium, 0).suffix}
          </TypographySecondary>
        </SeveritiesItem>
      )}
      {severity_low != undefined && (severity_low > 0 || showAll) && (
        <SeveritiesItem>
          {severity_low > 0 ? (
            <>
              <LowSeverity />
              <LowLabel s>L</LowLabel>
            </>
          ) : (
            <EmptySeverity />
          )}
          <TypographySecondary s isEmpty={!severity_low}>
            {toNumbersSuffix(severity_low, 0).num}
            {toNumbersSuffix(severity_low, 0).suffix}
          </TypographySecondary>
        </SeveritiesItem>
      )}
    </SeveritiesContainer>
  );
};

export default SeveritiesSummary;

const SeveritiesItem = styled(Flex)`
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.colors.grey[150]};
  background-color: ${({ theme }) => theme.colors.white};
  margin: 0 4px;
  width: 52px;
  height: inherit;
`;
const SeveritiesContainer = styled(Flex)`
  height: 30px;
`;

const TypographySecondary = styled(Typography)<{ isEmpty: boolean }>`
  color: ${({ theme, isEmpty }) => (isEmpty ? theme.colors.grey[150] : theme.colors.text.main)};
  width: 30px;
  text-align: center;
  font-weight: 600;
`;

const CriticalLabel = styled(Typography)`
  padding: 0 3px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.error.critical};
`;
const HighLabel = styled(CriticalLabel)`
  color: ${({ theme }) => theme.colors.error.high};
`;
const MediumLabel = styled(CriticalLabel)`
  color: ${({ theme }) => theme.colors.error.medium};
`;
const LowLabel = styled(CriticalLabel)`
  color: ${({ theme }) => theme.colors.error.low};
`;
const CriticalSeverity = styled.div`
  border-radius: 2px;
  background: ${({ theme }) => theme.colors.error.critical};
  width: 4px;
  height: 21px;
  margin: 4px 2px;
`;
const HighSeverity = styled(CriticalSeverity)`
  background: ${({ theme }) => theme.colors.error.high};
`;
const MediumSeverity = styled(CriticalSeverity)`
  background: ${({ theme }) => theme.colors.error.medium};
`;
const LowSeverity = styled(CriticalSeverity)`
  background: ${({ theme }) => theme.colors.error.low};
`;
const EmptySeverity = styled(CriticalSeverity)`
  background: ${({ theme }) => theme.colors.grey[150]};
`;
const CheckSeverity = styled(CriticalSeverity)`
  background: ${({ theme }) => theme.colors.assertions.check};
  margin: 4px 0 4px 7px;
`;
