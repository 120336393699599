export const roundToXDecimals = (num: number, pow: number): number => {
    const roundObject = Math.pow(10, pow);
    return Math.round(num * roundObject) / roundObject;
};

export const normalizeSizeFromKb = (kb: number): string => {
    if (kb < 0) {
        throw 'Size cannot be negative';
    }

    const units = ['KB', 'MB', 'GB'];
    let size = kb;
    let unitIndex = 0;

    // Normalize the size to the appropriate unit
    while (size >= 1024 && unitIndex < units.length - 1) {
        size /= 1024;
        unitIndex++;
    }

    return `${roundToXDecimals(size, 2)} ${units[unitIndex]}`;
};
