import Typography from '../../core/Typography';
import { theme } from '../../theme';
import { Link, RecentActivityNoDataContainer } from './NoData.styled';

interface NoDataProps {
  title: string | Function;
  subTitle: string;
  icon: any;
  link?: string;
}

const NoData = ({ title: Title, subTitle, icon, link }: NoDataProps) => {
  return (
    <RecentActivityNoDataContainer direction={'column'}>
      {icon}
      <Typography h3 color={theme.colors.text.title} weight={800}>
        {typeof Title === 'string' ? Title : <Title />}
      </Typography>
      {link ? (
        <Link href={link} target="_blank">
          {subTitle}
        </Link>
      ) : (
        <Typography color={theme.colors.text.main} weight={600}>
          {subTitle}
        </Typography>
      )}
    </RecentActivityNoDataContainer>
  );
};

export default NoData;
