export const bestPracticeApproach = [
  'itzikb@metisdata.io',
  'gideons@metisdata.io',
  'roee@metisdata.io',
  'issackr@metisdata.io',
  'itay@metisdata.io',
  'ilyar@metisdata.io',
  'e2e-test@metisdata.io',
  'demo4all@metisdata.io',
  'amitc@metisdata.io',
  'demo@metisdata.io',
  'oshrim@metisdata.io',
  'sahara@metisdata.io'
];

export const filterList = [
  { id: 0, name: 'None' },
  { id: 1, name: 'Severity' },
  { id: 2, name: 'Duration' },
  { id: 3, name: 'Date' },
  { id: 4, name: 'Command Type' }
];
export const sideMenuUserList = [
  { name: 'Settings', path: '/settings' }
  // { name: 'Management', path: '/management' }
];

import { ReactComponent as SettingsIcon } from '@icons/settings.svg';
import { ReactComponent as GeneralIcon } from '@icons/general.svg';
import { ReactComponent as UserManagementIcon } from '@icons/userManagement.svg';
import { ReactComponent as EnrichmentIcon } from '@icons/enrichment.svg';
import { ReactComponent as IndexAdisorIcon } from '@icons/index-advisor.svg';
import { ReactComponent as ActivityLogsIcon } from '@icons/activityLog.svg';
import { ReactComponent as MonitoringIcon } from '@icons/monitoring.svg';
import { ReactComponent as DocsI } from '@icons/link-to-docs.svg';
import { ReactComponent as ProjectI } from '@icons/project.svg';
import { ReactComponent as BalanceOutlinedI } from '@icons/balance.svg';
import { ReactComponent as QueryAnalyzerI } from '@icons/query-analyzer.svg';
import { ReactComponent as IntegrationsI } from '@icons/integrations.svg';
import { ReactComponent as RulesI } from '@icons/rules.svg';
import { USER_ROLE } from '../atoms/user.settings.atom';
import { ReactComponent as IndexIcon } from '@icons/index.svg';


export const menuUserList = [{ name: 'Settings', icon: SettingsIcon, path: '/settings' }];

export const sideMenuList = [
  { name: 'General Settings', path: '/settings', icon: GeneralIcon },
  { name: 'User management ', path: 'user-management', icon: UserManagementIcon },
  {
    name: 'Enrichment',
    icon: EnrichmentIcon,
    path: 'enrichment',
    isActive: (user: any) => bestPracticeApproach.includes(user.email ? user.email : '')
  },
  // { name: 'divider_1' },
  {
    name: 'Activity Log',
    icon: ActivityLogsIcon,
    path: 'activity',
    isActive: (user: any) => bestPracticeApproach.includes(user.email ? user.email : '')
  }
];
export const mainList = [
  { name: 'Projects', path: '/projects', icon: ProjectI },
  { name: 'Monitoring', path: '/monitoring', icon: MonitoringIcon },
  {
    name: 'Alerts',
    icon: SettingsIcon,
    path: '/alerts'
  }, 
  {
    name: 'Index Advisor',
    icon: IndexIcon,
    path: '/index-advisor'
  },
  {
    name: 'Host Comparison',
    icon: EnrichmentIcon,
    path: '/compare'
  },
  // { name: 'Integrations', path: '/projects', icon: SettingsIcon },
  { name: 'divider_2' },
  {
    name: 'Query Analyzer',
    icon: QueryAnalyzerI,
    path: '/query-analysis'
  },
  {
    name: 'Documentation',
    icon: DocsI,
    path: 'https://docs.metisdata.io/',
    link: true
  },

  { name: 'divider_3' },
  {
    name: 'Integrations',
    icon: IntegrationsI,
    path: '/integrations'
  },

  {
    name: 'Rules',
    icon: RulesI,
    path: '/rules'
  }
];

export const INSIGHT_STATUS = ['N/A', 'Critical', 'High', 'Medium', 'Low', 'Info', 'Skipped'];

export enum INSIGHT_STATUS_ENUM {
  N_A = 'N/A',
  CRITICAL = 'Critical',
  HIGH = 'High',
  MEDIUM = 'Medium',
  LOW = 'Low',
  INFO = 'Info',
  SKIPPED = 'Skipped'
}

export const SEVERITY_LIST = [
  INSIGHT_STATUS_ENUM.CRITICAL,
  INSIGHT_STATUS_ENUM.HIGH,
  INSIGHT_STATUS_ENUM.MEDIUM,
  INSIGHT_STATUS_ENUM.LOW
];

export enum RDBMS {
  postgres = 'postgres',
  mysql = 'mysql'
}

export const severity = {
  [INSIGHT_STATUS_ENUM.CRITICAL]: 0,
  [INSIGHT_STATUS_ENUM.HIGH]: 0,
  [INSIGHT_STATUS_ENUM.MEDIUM]: 0,
  [INSIGHT_STATUS_ENUM.LOW]: 0,
  [INSIGHT_STATUS_ENUM.INFO]: 0
};

export const summaryInit = {
  title: 'Recent Activity',
  description: '',
  totalRestCommand: 0,
  totalQueries: 0,
  totalFailedRules: 0,
  dateStart: '',
  dateEnd: '',
  severity,
  hideIcon: false
};

export const ResponseMethods = ['ALL', 'GET', 'POST', 'PUT', 'DELETE', 'HEAD', 'PUT'];
export const ResponseStatusCodes = ['ALL', '1XX', '2XX', '3XX', '4XX', '5XX'];
export const ResponseInsights = ['ALL', 'Insights only'];
export const ResponseRelativeTime = ['All', 'last-hour', 'last-day', 'last-week', 'last-month'];

export type SelectListItem = {
  id: number | string;
  name: string | any;
  val: string;
  timeFrame?: string;
};

export const listSelectedDates: SelectListItem[] = [
  {
    id: 1,
    name: '3 hours',
    val: '3'
  },
  {
    id: 2,
    name: '24 hours',
    val: '24'
  },
  {
    id: 3,
    name: '3 days',
    val: '72'
  },
  {
    id: 4,
    name: '7 day',
    val: '168'
  }
];

export const indexTrendListSelectedDates: SelectListItem[] = [
  {
    id: 1,
    name: '24 hours',
    val: '24 hours',
    timeFrame: '30 minutes'
  },
  {
    id: 2,
    name: '3 days',
    val: '72 hours',
    timeFrame: '1 hour'
  },
  {
    id: 3,
    name: '7 day',
    val: '168 hours',
    timeFrame: '24 hour'
  }
];




export const ProjectListType = {
  [USER_ROLE.READER]: {
    id: 1,
    name: 'Private project',
    val: 'Public'
  },
  [USER_ROLE.ADMIN]: {
    id: 2,
    name: 'Organization project',
    val: 'Admin'
  }
};

export interface INTEGRATION_T {
  id: number;
  name: string;
  description: string;
  iconUrl: string;
  isActive: boolean;
  installation: string;
  authenticationUrl: string;
}

export enum ItemType {
  Float =  'float'
}

export const transformationRules = {
  table_size: ItemType.Float,
  total_exec_time: ItemType.Float,
};
