export const filterOutDuplicateMinute = (data: { value: any, time: any}[]) => {
    if (data.length < 2) return data; // If less than 2 items, nothing to filter

    const lastIndex = data.length - 1;
    const secondLastIndex = lastIndex - 1;

    const lastItem = data[lastIndex];
    const secondLastItem = data[secondLastIndex];

    const lastDate = new Date(lastItem.time);
    const secondLastDate = new Date(secondLastItem.time);

    const areDatesEqual =
        lastDate.getFullYear() === secondLastDate.getFullYear() &&
        lastDate.getMonth() === secondLastDate.getMonth() &&
        lastDate.getDate() === secondLastDate.getDate() &&
        lastDate.getHours() === secondLastDate.getHours() &&
        lastDate.getMinutes() === secondLastDate.getMinutes();

    if (areDatesEqual) {
        return data.slice(0, lastIndex);
    }

    return data;
};