import { Breakpoint, Dialog } from '@mui/material';
import Flex from '../../core/Flex';
import Typography from '../../core/Typography';
import { TitleDialog, Close, ActionsWrapper } from './Modal.styled';
import { DialogActions, DialogContent } from '@mui/material';

interface ModalProps {
  content: any;
  actions?: any;
  isOpen: boolean;
  title: string;
  maxWidth?: Breakpoint;
  handleClickModal?: () => void;
}

const Modal = ({ content, actions, isOpen = false, title, handleClickModal, maxWidth = 'sm' }: ModalProps) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClickModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth={maxWidth}
    >
      <TitleDialog id="alert-dialog-title" sx={{ padding: '12px 16px' }}>
        <Flex justify="space-between">
          <Typography data-cy="modal-title" weight="600">
            {title}
          </Typography>
          <Close data-cy="close-modal" onClick={handleClickModal} title="close" />
        </Flex>
      </TitleDialog>
      <DialogContent>{content()}</DialogContent>
      {actions && (
        <ActionsWrapper>
          <DialogActions sx={{ justifyContent: 'space-between', width: '100%' }}>{actions()}</DialogActions>
        </ActionsWrapper>
      )}
    </Dialog>
  );
};

export default Modal;
