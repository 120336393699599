import styled from 'styled-components';

export const SubQueryContainer = styled.div<{ width: number; height: number }>`
  border: 3px solid gray; 
  border-radius: 15px; 
  background-color: rgba(255, 255, 255, 0.7); 
  position: relative; 
  width: ${(props) => props?.width}px; 
  height: ${(props) => props.height}px; 
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: flex-start; 
  padding-top: 20px; 
`;

export const SubQueryLabel = styled.div`
  color: blue; 
  padding: 15px;
  border-radius: 10px;
  border-color: lightgray;
  font-weight: bold;
  position: absolute; 
  top: -10px; 
  left: 50%;
  transform: translateX(-50%);
`;