import { ReactComponent as NoRecentActivityData } from '@icons/no-data-found-traces.svg';

import NoData from '../../../components/NoData/NoData';
import Flex from '../../../core/Flex';
import TagLabel from '../../../core/Label/Label';
import { CardWrapper, HistoryCardContainer, HistoryText, MainTitle, TextDrawer } from '../Reports.styled';
import MTooltip from '../../../core/Tooltip/Tooltip';
import Typography from '../../../core/Typography';

const HistoryCard = ({ history }: any) => {
  const historyTitle = Object.keys(history || {})?.reverse() || [];
  const historyItem = (itemList: any[] = []) => {
    const getTypeAction = (action: string) =>
      action.includes('removed') ? 'warning' : action.includes('added') ? 'success' : 'info';
    return (
      <Flex align="start" fw direction="column">
        {itemList.map((item: any, i: number) => (
          <CardWrapper key={item?.name + i} align="space-between">
            <Flex align="start" margin="0 16px 0 0">
              <TagLabel type={getTypeAction(item?.action || '')} title={item?.action || ''} />
            </Flex>
            <Flex justify="start" fw>
              <Flex>
                <MTooltip title={<Typography>{item?.name || '-'}</Typography>} disable={item?.name?.length < 100}>
                  <HistoryText weight={500}>{item?.name || '-'} </HistoryText>
                </MTooltip>
              </Flex>
              {item?.newValue && (
                <Flex margin="0 18px">
                  <MainTitle weight={500}>New: </MainTitle>
                  <TextDrawer>{item?.newValue || '-'}</TextDrawer>
                </Flex>
              )}
              {item?.oldValue && (
                <Flex margin="0 18px">
                  <MainTitle weight={500}>Old: </MainTitle>
                  <TextDrawer>{item?.oldValue || '-'}</TextDrawer>
                </Flex>
              )}
            </Flex>
          </CardWrapper>
        ))}
      </Flex>
    );
  };
  return (
    <Flex fw align="start" direction="column">
      {historyTitle?.length ? (
        historyTitle.map((key: string) => (
          <HistoryCardContainer key={key} justify="start" align="start">
            <Flex margin="26px 0 0 12px">
              <MainTitle style={{ minWidth: '120px' }} weight={600}>
                {key}
              </MainTitle>
            </Flex>
            {historyItem(history?.[key])}
          </HistoryCardContainer>
        ))
      ) : (
        <NoData title={''} subTitle={'Metis did not detect any change history.'} icon={<NoRecentActivityData />} />
      )}
    </Flex>
  );
};

export default HistoryCard;
