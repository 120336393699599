export enum ByteSize {
  Bytes = 'bytes',
  KB = 'KB',
  MB = 'MB',
  GB = 'GB'
}

const KB_SIZE = 1024;
const BYTE_UNITS: ByteSize[] = [ByteSize.Bytes, ByteSize.KB, ByteSize.MB, ByteSize.GB];

const isInt = (n: number): boolean => {
  return n % 1 === 0;
};

export const ByteSizeConvertor = (size: number, type: ByteSize = ByteSize.Bytes, isObject: boolean = false): string | number => {
  if (typeof size !== 'number') {
    return 0;
  }

  let index = BYTE_UNITS.indexOf(type);
  if (size > 0) {
    index += Math.min(Math.floor(Math.log(size) / Math.log(KB_SIZE)), BYTE_UNITS.length - 1 - index);
    size /= Math.pow(KB_SIZE, index - BYTE_UNITS.indexOf(type));
  }

  if (isObject) {
    return BYTE_UNITS[index];
  }

  const formattedSize = parseFloat(size.toFixed(2));
  return `${isInt(formattedSize) ? formattedSize : size.toFixed(2)} ${BYTE_UNITS[index]}`;
};


const BYTE_SIZE_TYPES = ['bytes', 'KB', 'MB', 'GB'];
export const BYTE_SIZE = { bytes: 0, KB: 1, MB: 2, GB: 3 };



export const byteMe = (size: number, type = BYTE_SIZE.bytes, isObject = false): string | 0 => {
  let i = type;
  if (typeof size !== 'number') {
    return 0;
  }
  while (size > 1024) {
    i++;
    size = size / 1024;
  }
  if (isObject) return BYTE_SIZE_TYPES[i];
  return `${isInt(parseFloat(Number(size).toFixed(2))) ? Number(size.toFixed(2)) : Number(size).toFixed(2)} ${
    BYTE_SIZE_TYPES[i]
  }`;
};
