import { useLocation, useParams } from 'react-router-dom';
import useNavigateBack from '../../hooks/useNavigateBack';
import Flex from '../Flex';
import { BreadcrumbsImg, BreadcrumbsText, Container, ProjectIcon, GlobalImgWrapper } from './Breadcrumbs.styled';
import MTooltip from '../Tooltip/Tooltip';
import {apiKeysState, ApiKeyType, convertApiIdToApiKey, useActiveAPIKey} from '../../atoms/api-keys.atom';
import { linkTo } from '../../utils/utils';
import {useRecoilValue} from "recoil";

type IBreadcrumbsList = {
  name: string;
  isActive?: boolean;
  navigateTo?: string;
};

interface BreadcrumbsI {
  projectName?: string;
  breadcrumbsList: IBreadcrumbsList[];
  isGlobalPage?: boolean;
  GlobalImg?: React.FC<React.SVGProps<SVGSVGElement>>;
}

const Breadcrumbs = ({ projectName, breadcrumbsList = [], isGlobalPage = false, GlobalImg }: BreadcrumbsI) => {
  const navigateBack = useNavigateBack();
  const { projectApiKey = '' } = useParams();
  const { state }: any = useLocation();
  const {apiKeys} = useRecoilValue(apiKeysState);

  const selectedApiKey: ApiKeyType = useActiveAPIKey(convertApiIdToApiKey(projectApiKey, apiKeys));
  const isQueryAnalyzerPage = projectName === 'Query Analyzer';
  return (
    <>
      <Container justify="start">
        {!isGlobalPage && (
          <Flex>
            <>
              <ProjectIcon />
              <a href="/projects" className="linkTo">
                <BreadcrumbsText
                  data-cy="title"
                  onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
                    linkTo(e, () => navigateBack({ navigateTo: '/projects' }))
                  }
                >
                  Projects
                </BreadcrumbsText>
              </a>

              <BreadcrumbsImg title="arrow-next" />
            </>
            <a href={isQueryAnalyzerPage ? `/query-analysis` : `/projects/${projectApiKey}`} className="linkTo">
              <BreadcrumbsText
                isActive={!breadcrumbsList.length}
                data-cy="title"
                onClick={() =>
                  isQueryAnalyzerPage
                    ? navigateBack({ navigateTo: `/query-analysis` })
                    : navigateBack({ navigateTo: `/projects/${projectApiKey}` })
                }
              >
                {projectName || selectedApiKey.label}
              </BreadcrumbsText>
            </a>
          </Flex>
        )}
        {breadcrumbsList.map((item: IBreadcrumbsList, i: number) => (
          <Flex key={i}>
            {GlobalImg && i == 0 ? (
              <GlobalImgWrapper>
                <GlobalImg />
              </GlobalImgWrapper>
            ) : (
              <BreadcrumbsImg title="arrow-next" />
            )}
            <MTooltip title={item.name} disable={item.name?.length < 50}>
              <a href={item?.navigateTo} className="linkTo">
                <BreadcrumbsText
                  isActive={item.isActive}
                  onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
                    linkTo(e, () => {
                      if (!item.isActive) {
                        item?.navigateTo ? navigateBack({ ...state, navigateTo: item.navigateTo }) : navigateBack(state);
                      }
                    })
                  }
                >
                  {item.name}
                </BreadcrumbsText>
              </a>
            </MTooltip>
          </Flex>
        ))}
      </Container>
    </>
  );
};

export default Breadcrumbs;
