import styled from 'styled-components';
import CountUp from 'react-countup';
import { Skeleton } from '@mui/material';

import Flex from '../../../../core/Flex';
import Typography from '../../../../core/Typography';
import SeveritiesSummary from '../../../../components/SeveritiesSummary/SeveritiesSummary';

interface HInsightDetails {
  title: string;
  count?: number;
  isLoading?: boolean;
  data?: any;
}

const HInsightDetails = ({ title, count = 0, isLoading = false, data }: HInsightDetails) => {
  return (
    <Container justify="start">
      <FlexItem justify="start">
        {isLoading ? (
          <Skeleton variant="text" width={40} height={30} sx={{ mr: '24px' }} />
        ) : (
          <TitleCounter large weight="600">
            <CountUp duration={0.5} end={count} />
          </TitleCounter>
        )}
        <TitleText h4 weight="600">
          {title}
        </TitleText>
      </FlexItem>

      <FlexItem direction="column" justify="start">
        {data && (
          <Flex>
            <Divider />
            <TitleText h4 weight="600">
              Insights Overview
            </TitleText>
            <Flex margin="0 0 0 16px">
              <SeveritiesSummary
                severity_critical={data?.severity_critical}
                severity_high={data?.severity_high}
                severity_medium={data?.severity_medium}
                severity_low={data?.severity_low}
              />
            </Flex>
          </Flex>
        )}
      </FlexItem>
    </Container>
  );
};

export default HInsightDetails;

export const TitleText = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.main};
`;
export const TitleCounter = styled(TitleText)`
  padding-right: 24px;
`;
export const FlexItem = styled(Flex)`
  flex: 0.5;
`;
export const Container = styled(Flex)`
  padding: 20px 16px;
  min-height: 48px;
`;

export const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.grey[150]};
  height: 32px;
  width: 1px;
  margin: 0 24px;
`;
