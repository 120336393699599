import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '@icons/close-bold.svg';

import Flex from '../../core/Flex';
import Typography from '../../core/Typography';
import { TitleMain } from '../../core/Breadcrumbs/Breadcrumbs.styled';

export const DBName = styled(Typography)`
  color: ${({ theme }) => theme.colors.new.secondary.main};
`;
export const MainTitle = styled(Typography)`
  padding-right: 8px;
  color: ${({ theme }) => theme.colors.new.primary.main};
`;

export const DatabaseContainer = styled(Flex)`
  background: ${({ theme }) => theme.colors.white};
  flex-direction: column;
  width: inherit;
  border: 1px solid ${({ theme }) => theme.colors.grey[150]};
  border-radius: 4px;
`;
export const DBNameContainer = styled(Flex)`
  height: inherit;
  justify-content: start;
`;
export const InsightsComponent = styled(Flex)`
  width: 100%;
`;
export const FullFlex = styled(Flex)`
  width: 100%;
  flex-wrap: wrap;
  align-items: start;
`;
export const FullAutoFlex = styled(Flex)<{ flex: string }>`
  width: auto;
  flex: ${({ flex }) => (flex ? flex : 1)};
`;
export const FullWFlex = styled(Flex)`
  width: calc(100% + 16px);
  margin-left: -8px;
  flex-wrap: wrap;
  align-items: start;
`;

export const HeaderDrawer = styled(Flex)`
  width: 100%;
  background: ${({ theme }) => theme.colors.grey[50]};
`;

export const Close = styled(CloseIcon)`
  cursor: pointer;
  height: 20px;
  width: 20px;
  stroke: ${({ theme }) => theme.colors.text.main};
`;

export const CopyDrawerContainer = styled(Flex)`
  position: relative;
  padding: 16px;
`;

export const IdleIcon = styled.div<{ circleColor?: string | undefined }>`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 0 8px;
  background: ${({ theme, circleColor }) => circleColor || theme.colors.new.primary[50]};
`;

export const HostTitle = styled(TitleMain)`
  max-width: 45vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 8px;
`;
