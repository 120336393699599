import { ReactComponent as CloseIcon } from '@icons/close-bold.svg';
import styled, { css } from 'styled-components';

import Flex from '../../core/Flex';
import Typography from '../../core/Typography';
import { selectBorderAnimation } from '../../utils/animation';

export const WrapperIframe = styled.div`
  overflow: hidden;
  width: 125%;
  height: 515px;
  margin-left: -20vw;
  margin-top: -62px;
`;

export const HeaderWrapper = styled(Flex)`
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.new.border};
  width: 100%;
`;
export const BoardingContentContainer = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.colors.new.border};
  background: #fcfcfd;
  height: 90vh;
  align-items: start;
`;
export const Header = styled(Typography)`
  padding: 16px 24px;
  color: ${({ theme }) => theme.colors.new.secondary.main};
`;

export const Close = styled(CloseIcon)`
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin-right: 24px;
  stroke: ${({ theme }) => theme.colors.text.main};
`;

export const Divider = styled(Flex)`
  width: 100%;
  height: 1px;
  padding: 0.5px 0;
  margin: 20px 0 8px;
  align-self: center;
  background: ${({ theme }) => theme.colors.grey[150]};
`;
export const Wrapper = styled(Flex)`
  overflow-x: scroll;
  height: 100%;
  padding: 24px;
  width: calc(100% - 48px);
  flex-direction: column;
  align-items: start;
  justify-content: start;
`;

export const NumberedIconTypography = styled(Typography)`
  z-index: 4;
  position: relative;
  top: -55px;
  left: 35px;
  color: #3e3446;
`;

export const TitleAlert = styled(Typography)`
  color: ${({ theme }) => theme.colors.error.critical};
`;
export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.new.primary.main};
`;

export const SubTitle = styled(Typography)`
  padding-top: 16px;
  color: ${({ theme }) => theme.colors.chart.hover};
`;

export const TextContainer = styled(Flex)`
  height: 44px;
  justify-content: stretch;
  align-items: start;
`;
export const StepContainer = styled(Flex)`
  padding-left: 16px;
  width: calc(100% - 58px);
  margin-bottom: 10px;
`;
export const CardContainer = styled(Flex)`
  width: 100%;
`;
export const Container = styled(Flex)`
  width: 100%;
  height: 100%;
`;
export const ButtonContainer = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.colors.grey[150]};
  width: calc(100% - 48px);
  padding: 16px 24px;
  justify-content: end;
`;

export const Card = styled(Flex)<{ selected: boolean; disable: boolean; animate: boolean }>`
  width: 224px;
  height: 160px;
  position: relative;
  gap: 24px;
  border-radius: 8px;
  cursor: ${({ disable }) => (disable ? 'not-allowed' : 'pointer')};
  margin: 16px 12px 16px 0;
  border: 1px solid ${({ theme, selected }) => (selected ? theme.colors.text.title : theme.colors.grey[150])};
  background: ${({ theme }) => theme.colors.white};
  &:hover {
    box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.14);
    backdrop-filter: blur(25.5px);
    border: 1px solid ${({ theme }) => theme.colors.text.title};
  }
  ${({ animate }) =>
    animate &&
    css`
      animation-name: ${selectBorderAnimation};
      animation-duration: 1s;
      animation-iteration-count: 2;
    `}
`;
export const DeployCard = styled(Card)`
  height: 74px;
  padding: 2px 4px;
`;

export const NoteWrapper = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.colors.new.border};
  background: ${({ theme }) => theme.colors.grey[20]};
  border-radius: 4px;
  padding: 16px;
  gap: 8px;
`;
export const FlexWrap = styled(Flex)`
  flex-wrap: wrap;
`;
export const FlexInputFull = styled(Flex)`
  width: 100%;
  height: 70px;
  margin-top: 12px;
  justify-content: start;
  align-items: start;
  position: relative;
`;
export const ComingSoon = styled(Flex)`
  position: absolute;
  top: 4px;
  right: 4px;
  border-radius: 4px;
  padding: 2px 8px;
  background: ${({ theme }) => theme.colors.new.secondary[125]};
  border: 1px solid ${({ theme }) => theme.colors.new.primary[100]};
  div {
    color: ${({ theme }) => theme.colors.new.secondary[50]};
  }
`;
export const CardIcon = styled(Flex)`
  border-radius: 4px;
  padding: 16px;
  height: 40px;
  width: 40px;
  background: ${({ theme }) => theme.colors.grey[50]};
`;

export const ErrorTitle = styled(Typography)`
  position: absolute;
  top: 30px;
  left: 32px;
  padding: 16px 0 2px;
  color: ${({ theme }) => theme.colors.error.critical};
`;
export const CardTitle = styled(Typography)`
  font-weight: 600;
  padding-top: 4px;
  color: ${({ theme }) => theme.colors.new.secondary.main};
`;
export const CardAgentTitle = styled(CardTitle)`
  padding: 0;
`;

export const HostInformationWrapper = styled(Flex)`
    width: 100%;
    gap: 16px;
    flex-wrap: wrap;
    justify-content: start;
    flex-direction: column;
  `;

export const SingleInformationWrapper = styled(Flex)`
    width: 100%;
    gap: 16px;
    flex-wrap: wrap;
    justify-content: start;
    flex-direction: row;
`;

export const TextBoxWrapper = styled(Flex)`
  width: 100%;
  flex: 5;
`;

export const InformationLabel = styled(Title)`
  width: 150px;
  justify-content: start;
`;

export const TitleWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: start;
  align-items: start;
`;
