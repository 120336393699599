import  {FC, ReactNode} from "react";
import CopyToClipBoard from '../../../components/Buttons/CopyToClipBoard';
import TableItem from '../../../components/Table/TableItem';
import Flex from '../../../core/Flex';
import { numberWithCommas } from '../../utils';
import { v4 as uuidv4 } from 'uuid';
import { byteMe } from '../../byteMe';
import Typography from '../../../core/Typography';
import { LogContainer } from '../automated-investigation.styled';
import SafeInnerHtml from '../../SafeInnerHtml';
import { wrapNumbersInBold } from '../utils';
import { ReactComponent as AlertIcon } from '@icons/warning.svg';
import {ConsistentDiv, StyledCodeBlock} from "./index.styled";


const ID: string = uuidv4();

export const RULE_SQL_01 = (facts: any, assertion: any, tableItem: any, isNeedNewIndex: boolean, table: any) => {
  let rowsRead: any;
  const isActual = facts['plan-type'] === 'actual';

  if (isActual) rowsRead = facts['actual-rows-read'];

  return {
    investigation: (
      <ConsistentDiv>
        {/* {isEstimated ? ( */}
       <div style={{display: 'flex', flexDirection: 'row'}}>
        <div style={{marginRight: 3}}>
        <SafeInnerHtml unsafeInnerHtmlText={wrapNumbersInBold(assertion?.ruleResult)} />
        </div>
        
         <div style={{marginRight: 3}}>{isActual && (<div><b style={{marginRight: 3}}>{numberWithCommas(facts['actual-rows-removed-by-filter'])}</b>rows were filtered out</div>)} </div>
         {isActual && !!facts['actual-rows-read'] && facts['actual-rows-read'] > 0 && (<b>({Math.floor((facts['actual-rows-removed-by-filter'] / facts['actual-rows-read']) * 100)}%)</b>
        )}
       </div>
        <Flex align={'start'}>
          <TableItem headHeight={'320px'} data={tableItem} />
          <Flex style={{ marginLeft: 5 }} justify={'flex-start'} align={'start'} direction={'column'}></Flex>
        </Flex>
      </ConsistentDiv>
    ),
    impact: (
      <ConsistentDiv>
        Reading a large number of rows requires many IO operations. That might resolve into:
        <ul style={{margin: 0}}>
            <li>Large row queries strain storage, causing slow response times and Disk I/O overload.</li>
          <li>Multi-user environments suffer from growing query sizes, impacting database performance.</li>
          <li>Extensive result sets consume memory, possibly resorting to disk-based temporary storage.</li>
          <li>Large queries may cause contention issues with shared locks and blocking.</li>
          <li>Remote database access encounters substantial network traffic, leading to delays.</li>
          <li>Resource-intensive queries impede database scalability.</li>
          <li>Extensive result sets hamper application performance, requiring query optimization.</li>
        </ul>
      </ConsistentDiv>
    ),
    remediation: isNeedNewIndex ? (
      <Flex align={'start'} justify={'flex-start'} direction={'column'}>
        {table
          ?.filter((i: any) => i?.['remedation'] && i?.['Filter'] && i?.['Filter'] !== 'N/A')
          ?.map((item: any, idx: number) => {
            const remidiationQuery = `CREATE INDEX IDX_${item?.['Table Name']?.replaceAll('.', '_') || ''}_${ID
              .substring(0, 12)
              ?.replaceAll('-', '')} ON ${item?.['Table Name']} (${item?.['remedation']?.index?.join(', ')});`;
            return (
              <Flex key={idx} align={'start'} justify={'flex-start'} direction={'column'}>
                <ul>
                  <li>
                    {`Add an Index to the table ${item?.['Table Name']}.`}
                    <br />
                    <Flex direction="row">
                      <code style={{ paddingBottom: 2 }}>{remidiationQuery}</code>
                      <div style={{ marginTop: 3 }}>
                        <CopyToClipBoard data={remidiationQuery}></CopyToClipBoard>
                      </div>
                    </Flex>
                  </li>
                  {
                    isActual && !!facts['actual-rows-read'] && facts['actual-rows-read'] > 50_000 &&
                    <li>
                        Consider limiting the number of returned rows.<br/>
                        For example: <StyledCodeBlock paddingLeft='5px'>SELECT … LIMIT 10000;</StyledCodeBlock>
                    </li>
                  }
                </ul>
              </Flex>
            );
          })}
      </Flex>
    ) : (
      <ol>
         <li>
             Consider limiting the number of returned rows.<br/>
             For example: <StyledCodeBlock paddingLeft='5px'>SELECT … LIMIT 10000;</StyledCodeBlock>
         </li>
        <li>
          Consider partitioning the table to distribute the data across multiple disks.<br/> This can help to reduce the amount of I/O
          required to read the data, which can improve performance.
        </li>
      </ol>
    )
  };
};

export const RULE_SQL_02 = (facts: any) => {
  const planType = facts['plan-type'];
  const rowsRead = planType === 'actual' ? numberWithCommas(facts['actual-rows-read']) : 'unknown';
  const rowsReturned =
    planType === 'actual' ? numberWithCommas(facts['actual-rows-returned']) : numberWithCommas(facts['estimated-rows-returned']);
  const rowSizeInBytes = facts['row-size-in-bytes'];
  const resultSetSize = facts['results-set-size'];
  return {
    investigation:
      planType === 'estimated' ? (
        <>
          The SQL command returned (estimated) <b>{rowsReturned}</b> rows.
        </>
      ) : (
        <>
          The SQL command returned <b>{rowsReturned}</b> rows.
        </>
      ),
    impact: <ConsistentDiv>
        <ul style={{ margin: 0 }}>
            <li><b>Query Performance:</b> Large result sets increase processing and I/O demands, slowing down queries.</li>
            <li><b>Network Latency:</b> Transferring large result sets can cause delays and slow application response times.</li>
            <li><b>Client-Side Handling:</b> Large result sets can overwhelm the client, causing performance issues or crashes.</li>
        </ul>
    </ConsistentDiv>,
    remediation: (
      <ul>
          <li><b>Limit the Result Set:</b>Use <StyledCodeBlock>LIMIT</StyledCodeBlock> to restrict the number of rows returned by the query.</li>
          <li>
            <b>Use Pagination:</b> Break down the result set into smaller, manageable chunks with <StyledCodeBlock>LIMIT</StyledCodeBlock>
              or <StyledCodeBlock>OFFSET</StyledCodeBlock>similar methods.
          </li>
          <li><b>Filter Data:</b> Apply more specific <StyledCodeBlock>WHERE</StyledCodeBlock> clauses to narrow down the result set to only the necessary data.</li>
      </ul>
    )
  };
};

export const RULE_SQL_03 = (facts: any) => {
  return {
    investigation: (
      <ol>
        <li>
          Number of tables: <b>{facts['num-of-tables']}</b>
        </li>
        <li>
          The table{facts?.['num-of-tables'] > 1 ? 's' : ''}: {facts['table-names-list']}
        </li>
      </ol>
    ),
    impact: <ConsistentDiv>Joining too many table might resolve in excessive IO usage.</ConsistentDiv>,
    remediation: (
      <ConsistentDiv>
        Review all the tables used by the SQL Command. Consider returning main data, from less tables, in one call, and more data
        only if needed.
      </ConsistentDiv>
    )
  };
};

export const RULE_SQL_E_05 = (facts: any, changeTabActivity: any) => {
  const OperationCostTable = [
    {
      Operation: 'Read',
      Cost: numberWithCommas(facts['total-physical-operations-cost'])
    },
    {
      Operation: 'Join',
      Cost: numberWithCommas(facts['total-join-operation-costs'])
    },
    {
      Operation: 'Sort',
      Cost: facts['total-sort-operations-cost'] || 0
    }
  ];
  return {
    investigation: (
      <ConsistentDiv>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <span style={{ marginRight: 5 }}>
            In PostgresSQL, &quot;cost&quot; refers to the estimated execution cost of a query plan. It is used by the query
            optimizer to determine the most efficient execution plan for a query
            <b style={{marginLeft: 3}}>{numberWithCommas(facts['plan-type'] === 'actual' ? facts['total-cost'] : facts['estimated-cost'])}</b>.
          </span>
        </div>
        <div style={{ width: '100%', paddingBottom: 5 }}>
          <TableItem headHeight={'320px'} data={OperationCostTable || []} />
        </div>
      </ConsistentDiv>
    ),
    impact: (
      <ConsistentDiv>
        High cost reading the rows usually result in excessive IO and Memory utilization. Joining many rows might result in high
        CPU. High cost of sorting also might result into high CPU and memory usage.
      </ConsistentDiv>
    ),
    remediation: (
      <ol>
        <li>
          <Flex>
            Use the tab
            <LogContainer onClick={() => changeTabActivity(3)}>Query Tale</LogContainer>
            to review each step in the execution plan and its cost
          </Flex>
        </li>
      </ol>
    )
  };
};

export const RULE_SQL_06 = (facts: any) => {
  return {
    investigation: (
      <>
        <div>
          {' '}
          The output of the SQL Commands returns <b>{facts['num-of-columns']}</b> columns.{' '}
        </div>
        <div>
          The output column(s): <b>{facts['output-columns']}</b>
        </div>
      </>
    ),
    impact: <ConsistentDiv>
      <ul>
          <li><b>Security / compliance:</b> always avoid returning unnecessary data.</li>
          <li><b>Network Latency:</b> slow response time due to transferring large amount of data to clients. The latency affects the UX and system health.</li>
          <li><b>DB latency:</b> Indexes inefficiency, it would be impossible to plan covering indexes.</li>
      </ul>
      </ConsistentDiv>,
    remediation: (
      <ConsistentDiv>
       Check the size of the result set returned by the query, focusing on large data types such as JSON. Since an estimated plan is used, the actual size of long strings and JSON text may be significantly different from the estimates. Ensuring accurate data size management can help optimize performance and resource usage.
      </ConsistentDiv>
    )
  };
};

export const RULE_SQL_07 = (facts: any) => {
  return {
    investigation: (
      <ConsistentDiv>
        <Typography>
          The SQL command sorted: <b>{numberWithCommas(facts['total-num-of-rows-sorted'])}</b> rows.
        </Typography>
        {facts['sorted_keys'] ? (
          <div>
            Sort keys: <b>{facts['sorted_keys']}</b>
          </div>
        ) : undefined}
      </ConsistentDiv>
    ),
    impact: (
      <ConsistentDiv>
        Sorting a large number of rows can slow down the query, requiring CPU and memory resources. It may lead to memory spills
        if the server needs to sort a very large dataset.
      </ConsistentDiv>
    ),
    remediation: (
      <ConsistentDiv>
        <ul>
          <li>Limit the result set by applying filters or using pagination.</li>
          <li>Optimize indexing for the sorting columns.</li>

          <li>Consider caching pre-sorted results for static data.</li>
        </ul>
      </ConsistentDiv>
    )
  };
};

export const RULE_SQL_08 = (facts: any) => {
  const sizeInBytes: number = facts['row-size-in-bytes'];
  const planMode: string = facts['accuration_level'];
  const isEstimated: boolean = planMode === 'estimated';
  const rowsReturned =
    facts['actual-rows-returned'] && typeof facts['actual-rows-returned'] === 'number'
      ? facts['actual-rows-returned']
      : facts['estimated-rows-returned'];
  return {
    investigation: (
      <ConsistentDiv>
        <div>
          Avg row size: <b>{byteMe(sizeInBytes)}</b>.
        </div>
        <div>
          Rows returned: <b>{rowsReturned.toLocaleString()}</b>.
        </div>
        <div>
          Total size of the results set: <b>{byteMe(facts['results-set-size'] || 1)}</b>.
        </div>
          <div>
              Notice! Execution plans may inaccurately estimate sizes for JSON and long strings; real-world testing is advised for accurate sizing.
          </div>
      </ConsistentDiv>
    ),
    impact: (
      <>
        <ul>
            <li><b>Query Performance:</b> Large result sets increase processing and I/O demands, slowing down queries.</li>
            <li><b>Network Latency:</b> Transferring large result sets can cause delays and slow application response times.</li>
            <li><b>Client-Side Handling:</b> Large result sets can overwhelm the client, causing performance issues or crashes.</li>
        </ul>
      </>
    ),
    remediation: (
          <>
              <div><b>1.Limit the Result Set:</b> Use <StyledCodeBlock>LIMIT</StyledCodeBlock> to restrict the number of rows returned by the query.</div>
              <div><b>2.Use Pagination:</b> Break down the result set into smaller, manageable chunks with <StyledCodeBlock>LIMIT</StyledCodeBlock>
                  and <StyledCodeBlock>OFFSET</StyledCodeBlock> or similar methods.</div>
              <div><b>3.Review Wide Columns:</b> Carefully review and avoid returning wide columns, such as JSON or images, unless absolutely necessary. Instead, retrieve only the essential data.</div>
              <div><b>4.Filter Data:</b> Apply more specific <StyledCodeBlock>WHERE</StyledCodeBlock> clauses to narrow down the result set to only the necessary data.</div>
          </>
    )
  };
};

export const RULE_SQL_A_09 = (facts: any) => {
  return {
    investigation: (
      <ConsistentDiv>
        {facts['sort-space-type'] === 'Disk' ? (
          <>
            The query uses the hard drive to sort the data.
            <ul>
              <li>Sort Key: {facts['sorted_keys'] ? facts['sorted_keys'] : 'N/A'}</li>
              <li>
                Temp IO Blocks Read: <b>{facts['sort-read-buffers']}</b>
              </li>
              <li>
                Temp IO Blocks Written: <b>{facts['sort-written-buffers']}</b>
              </li>
            </ul>
          </>
        ) : (
          'No sorting i/o operations were performed.'
        )}
      </ConsistentDiv>
    ),
    impact: <>Performance - sorting the rows on the hard drive (disk) is much slower than sorting in memory. </>,
    remediation: (
      <>
        <ul>
          <li>
            The PostgreSQL configuration property used to control how much memory can be used for sorting is work_mem. The default
            value of <b>work_mem</b> is 2MB (250 IO Blocks of 8KB).
          </li>
          <li>
            Configure a larger <b>work_mem</b> using the SET command. For ex. <code>SET work_mem = 1GB;</code> Restart the server.
          </li>
          <li>
            The best way to determine the optimal value for work_mem is to experiment with different values and measure the
            performance of your queries. Setting a value too high will reserve too much unnecessary memory for sorting, for every
            new connection.{' '}
          </li>
        </ul>
      </>
    )
  };
};

export const RULE_SQL_11 = (facts: any, changeTabActivity: any) => {
  return {
    investigation: (
      <ConsistentDiv>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <span style={{ marginRight: 5 }}>
            In PostgresSQL, &quot;cost&quot; is a unit of measurement used to estimate the relative performance of different query
            execution plans. The optimizer uses this information to determine which plan is the most efficient. The cost of the
            SQL command is{' '}
            <b>{numberWithCommas(facts['plan-type'] === 'actual' ? facts['total-cost'] : facts['estimated-cost'])}</b>.
          </span>
        </div>
      </ConsistentDiv>
    ),
    impact: (
      <ConsistentDiv>
        High cost reading the rows usually result in excessive IO and Memory utilization. Joining many rows might result in high
        CPU. High cost of sorting also might result into high CPU and memory usage.
      </ConsistentDiv>
    ),
    remediation: (
      <ol>
        <li>
          <Flex>
            Use the tab
            <LogContainer onClick={() => changeTabActivity(3)}>Query Tale</LogContainer>
            to review each step in the execution plan and its cost
          </Flex>
        </li>
      </ol>
    )
  };
};

export const RULE_SQL_20 = (facts: any) => {
  return {
    investigation: (
      <ConsistentDiv>
        {facts['index-on-functions-array'] && facts['index-on-functions-array'].length > 0 ? (
          <>
            <div>
                The SQL command uses a <StyledCodeBlock>WHERE</StyledCodeBlock> clause. The following expressions use a{' '}
              {facts['index-on-functions-array'].length > 1 ? 'functions' : 'function'}
              <br /> to evaluate the {facts['index-on-functions-array'].length > 1 ? 'expressions' : 'expression'}:{' '}
              {facts['index-on-functions-array'].map((functionDetails: any) => (
                <em>{functionDetails.functionDeclaration}</em>
              ))}
            </div>
            <>
              <ul>
                {facts['index-on-functions-array'].map((functionDetails: any) => (
                  <li>
                    expression: {functionDetails.functionDeclaration}
                    <br />
                    index: {functionDetails?.index?.name ? functionDetails?.index?.name : 'Index Missing'}
                  </li>
                ))}
              </ul>
            </>
          </>
        ) : (
            <div>There are no functions on the <StyledCodeBlock>WHERE</StyledCodeBlock> clause.</div>
        )}
      </ConsistentDiv>
    ),
    impact: (
      <div>
        Performance - The Query Optimizer uses indexes to read only the rows relevant to the query. When the WHERE clause uses a
        function, in most cases the the Optimizer can not use the index which slows down the query and might generate intensive IO
        operations.{' '}
      </div>
    ),
    remediation: (
      <>
        <ol>
          <li>
            Check the execution plan. Sometimes other indexes are used to filter the data efficiently, which make the impact
            minimal.
          </li>
          <li>
            If the entire table is scanned because the none of the indexes can be used, consider adding a new index on the
            expression.{' '}
          </li>
        </ol>
      </>
    )
  };
};

export const RULE_SQL_21 = () => {
  return {
    investigation: (
      <ConsistentDiv>
        The SQL command uses <b>SELECT *</b> instead of specifying the actual columns.
      </ConsistentDiv>
    ),
    impact: (
      <ul>
        <li>
            <b>Increased data transfer:</b> Retrieves unnecessary data, slowing down query performance.
        </li>
        <li>
            <b>Reduce Query Performance:</b> Slows execution, especially with large or complex data types.
        </li>
        <li>
            <b>Increase Maintenance Complexity:</b> Makes code harder to maintain and more prone to issues with schema changes.
        </li>
        <li>
            <b>Hinder Index Optimization:</b> Selecting only the necessary columns helps suggest covering indexes,
            which can improve performance by allowing the database to use indexes for the entire query without accessing the table.
        </li>
      </ul>
    ),
      remediation: <ConsistentDiv>Replace <StyledCodeBlock>SELECT *</StyledCodeBlock> with the actual columns: <StyledCodeBlock>SELECT column1, column2…</StyledCodeBlock></ConsistentDiv>
  };
};

export const RULE_SQL_22 = () => {
  return {
    investigation: (
      <ConsistentDiv>
        The SQL Command should have a WHERE clause to filter which rows to update and which shouldn't be updated.
      </ConsistentDiv>
    ),
    impact: (
      <ConsistentDiv>
          Without a <StyledCodeBlock>WHERE</StyledCodeBlock> clause, all rows in the table would be deleted, which could result in data loss and unexpected
        consequences.
      </ConsistentDiv>
    ),
    remediation: (
      <ConsistentDiv>
        Add a <StyledCodeBlock>WHERE</StyledCodeBlock> clause to the SQL command
      </ConsistentDiv>
    )
  };
};

export const RULE_SQL_23 = () => {
  return {
    investigation: (
      <ConsistentDiv>
        The SQL Command should have a WHERE clause to filter which rows to delete and which shouldn't be deleted.{' '}
      </ConsistentDiv>
    ),
    impact: (
      <ConsistentDiv>
        Without a WHERE clause, all rows in the table would be deleted, which could result in data loss and unexpected
        consequences.
      </ConsistentDiv>
    ),
    remediation: (
      <ConsistentDiv>
        Add a <StyledCodeBlock>WHERE</StyledCodeBlock> clause to the SQL command
      </ConsistentDiv>
    )
  };
};

export const RULE_SQL_24 = (facts: any) => {
  const isLikeExpressionExist: boolean = facts['like-expression-was-used'].reduce((acc: any, cur: any) => {
    acc = acc || cur?.likeExpressionExist;
    return acc;
  }, false);
  const likeExpressionList: any = (facts['like-expression-was-used'] || []).map((exp: any) => (
    <li>
        <StyledCodeBlock>{exp?.likeExpression}</StyledCodeBlock>
    </li>
  ));
  return {
    investigation: (
      <ConsistentDiv>
        {isLikeExpressionExist ? (
          <>
            <div>The WHERE clause contains LIKE. The LIKE expression's</div>
            <ul>{likeExpressionList}</ul>
          </>
        ) : (
            <div>The <StyledCodeBlock>WHERE</StyledCodeBlock> clause doesn't contains any <StyledCodeBlock>LIKE</StyledCodeBlock></div>
        )}
      </ConsistentDiv>
    ),
    impact: (
      <div>
        <ul>
          <li><b>High CPU and slow response time:</b> using a wildcard character (%) at the beginning of the search pattern in a
              <StyledCodeBlock>LIKE</StyledCodeBlock> statement (e.g. <StyledCodeBlock>LIKE '%text'</StyledCodeBlock>) result in a table scan.
              This can extremely affect large tables as it requires examining every row, regardless of any indexes that may be present.</li>
          <li><b>Performance:</b> When you use a wildcard character (%) at the beginning of the search pattern in a <StyledCodeBlock>LIKE</StyledCodeBlock>
              statement (e.g. <StyledCodeBlock>LIKE  '%example'</StyledCodeBlock>), the database engine needs to scan the entire table to find matching rows. This can be extremely slow for
              large tables as it requires examining every row, regardless of any indexes that may be present.</li>
        </ul>
      </div>
    ),
    remediation: (
      <>
        <ol>
          <li>Minimize the number of rows scanned using <StyledCodeBlock>LIMIT</StyledCodeBlock> or other conditions <StyledCodeBlock>(WHERE)</StyledCodeBlock>.</li>
          <li>
            In PostgresSQL, trigrams are three-character sequences that represent substrings of a text. Trigrams are commonly used
            for similarity matching and indexing in text search scenarios.
            <ol>
              <li>
                  Enable the extension: <StyledCodeBlock>CREATE EXTENSION pg_trgm;</StyledCodeBlock>
              </li>
              <li>
                Create a GIN index on the trigram representation of the column. For ex.{' '}
                  <StyledCodeBlock>CREATE INDEX trigram_gin_idx ON your_table USING gin (text_column gin_trgm_ops);</StyledCodeBlock>
              </li>
              <li>
                Perform trigram-based searches: <StyledCodeBlock>SELECT * FROM your_table WHERE text_column % 'search_text';</StyledCodeBlock>
              </li>
            </ol>
          </li>
        </ol>
      </>
    )
  };
};
