import InsightDetailsCard from '../InsightDetailsCard/InsightDetailsCard';
import Flex from '../../core/Flex';
import {
  AssertionContainer,
  AssertionDetailsContainer,
  AssertionDetailsTitleContainer,
  AssertionsTabContainer
} from './AssertionTab.styled';
import { Label } from '../Ruler/Ruler.styled';
import { InvestigationDetail } from '../InvestigationDetail/InvestigationDetail';
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import { Assertion } from '../../types/Assertion.type';
import InsightLoader from '../Loader/Insight';
import { getInsightInvestigation } from '../../utils/automated-investgiation/automated-investigation';
import { useRecoilValue } from 'recoil';
import { debugModeState } from '../../atoms/debug-mode.atom';
import { analyticsTrack } from '../../utils/segment-analytics';
import LottieAnimation from '../../core/Lottie';
import SafeInnerHtml from '../../utils/SafeInnerHtml';
import { PriorityTextToNumber } from '../../enums';
import SplashScreenIcon from '../../../assets/lotties/ splash-screen.json';
import {useEffect, useState} from "react";

const InvestigationTitle = {
  INSIGHT: 'Insight Details',
  IMPACT: 'Impact',
  REMEDIATION: 'Remediation Plan'
};
interface AssertionTabProps {
  assertions: Assertion[];
  facts?: any;
  assertionCurrentIndex?: number;
  setAssertionIndexCallback?: any;
  tableInfos: any[];
  isLoading?: boolean;
  selectedQuery?: any;
  changeTabActivity?: any;
  headHeight?: string;
  tableItem?: any;
  extensionsReportAssertions?: boolean;
  configurationsReportAssertions?: boolean;
  indexReportAssertions?: boolean;
  isSidebar?: boolean;
  insightsView?: boolean;
  tableName?: string;
  schemaName?: string;
}

export const AssertionTab = ({
  insightsView,
  assertions,
  isLoading,
  assertionCurrentIndex = 0,
  setAssertionIndexCallback,
  facts,
  tableInfos,
  changeTabActivity,
  selectedQuery,
  headHeight,
  tableItem,
  extensionsReportAssertions,
  indexReportAssertions,
  configurationsReportAssertions,
  isSidebar,
 tableName,
 schemaName,
}: AssertionTabProps) => {
  const [selectedRuleId, setSelectedRuleId] = useState("");
  const debugMode = useRecoilValue(debugModeState);

  useEffect(() => {
    if (sortedAssertions.length > 0 && !selectedRuleId) {
      setSelectedRuleId(sortedAssertions[0].ruleId);
      setAssertionIndexCallback && setAssertionIndexCallback(0);
    }
  }, [ selectedRuleId, setAssertionIndexCallback]);


  const cleanSuccessAssertions = (assertions: Assertion[]): Assertion[] => {
    if (
      assertions.every(
        (assertion: Assertion) => (assertion?.condition?.event?.priority || assertion.priority) === PriorityTextToNumber.INFO
      )
    ) {
      return [
        {
          priority: PriorityTextToNumber.INFO,
          title: 'All Checks passed'
        } as Assertion
      ];
    }
    return assertions
      .filter((a) => (a.condition?.event?.priority || a?.priority) !== PriorityTextToNumber.INFO)
      .sort((assertionA: Assertion, assertionB: Assertion) => {
        const priorityA = assertionA?.condition?.event?.priority || assertionA?.priority;
        const priorityB = assertionB?.condition?.event?.priority || assertionB?.priority;
        return priorityA - priorityB;
      });
  };
  const sortedAssertions = cleanSuccessAssertions(assertions);

  const automatedInvestigation =
    extensionsReportAssertions || configurationsReportAssertions || indexReportAssertions
      ? {
          investigation: <SafeInnerHtml unsafeInnerHtmlText={sortedAssertions[assertionCurrentIndex]?.details} />,
          impact: <SafeInnerHtml unsafeInnerHtmlText={sortedAssertions[assertionCurrentIndex]?.impact} />,
          remediation: <SafeInnerHtml unsafeInnerHtmlText={sortedAssertions[assertionCurrentIndex]?.remediation} />
        }
      : getInsightInvestigation(
          facts,
          sortedAssertions[assertionCurrentIndex]?.ruleId,
          tableInfos,
          changeTabActivity,
          selectedQuery,
          sortedAssertions[assertionCurrentIndex],
          tableItem, tableName, schemaName
        );

  if(insightsView) {
    return <>
  {automatedInvestigation ? (
        <AssertionDetailsContainer>
          <AssertionDetailsTitleContainer justify={'space-between'}>
            <Flex>
              <Label weight={600}>
                {debugMode &&
                  [assertions[assertionCurrentIndex]?.category, assertions[assertionCurrentIndex]?.ruleId]
                    .filter(Boolean)
                    .join(' - ')}
              </Label>
            </Flex>
          </AssertionDetailsTitleContainer>
          {[
            {
              title: InvestigationTitle.INSIGHT,
              details: automatedInvestigation?.investigation,
              icon: <InsightsDetailsIcon width={24} />,
              key: 0
            },
            {
              title: InvestigationTitle.IMPACT,
              details: automatedInvestigation?.impact,
              icon: <Pendulum width={24} />,
              key: 1
            },
            {
              title: InvestigationTitle.REMEDIATION,
              details: automatedInvestigation?.remediation,
              icon: <Wrench width={24} />,
              key: 2
            }
          ].map((e: any) => {
            return <InvestigationDetail isLoading={isLoading} key={e.key} title={e.title} details={e.details} icon={e.icon} />;
          })}
        </AssertionDetailsContainer>
      ) : (
        !isLoading && (
          <Flex fw>
            <LottieAnimation width={150} height={250} lottieJsonFile={SplashScreenIcon} loop={true} />
          </Flex>
        )
      )}

    </>
  }

  return (
    <AssertionsTabContainer headHeight={headHeight}>
      {isLoading ? (
        <InsightLoader />
      ) : (
        <AssertionContainer>
          {sortedAssertions.map((assertion: Assertion, idx: number) => {
            return (
                <InsightDetailsCard
                    choosen={assertion?.ruleId === selectedRuleId}
                    index={idx}
                    isSidebar={isSidebar}
                    callback={(assertionData: any) => {
                      analyticsTrack('PerformanceDetails Insight', assertionData);
                      setAssertionIndexCallback(assertionData.idx);
                      setSelectedRuleId(assertionData.ruleId);
                    }}
                    assertionDetails={assertion}
                    key={idx}
                />
            )
          })}
        </AssertionContainer>
      )}
      {automatedInvestigation ? (
        <AssertionDetailsContainer>
          <AssertionDetailsTitleContainer justify={'space-between'}>
            <Flex>
              <Label weight={600}>
                {debugMode &&
                  [assertions[assertionCurrentIndex]?.category, assertions[assertionCurrentIndex]?.ruleId]
                    .filter(Boolean)
                    .join(' - ')}
              </Label>
            </Flex>
          </AssertionDetailsTitleContainer>
          {[
            {
              title: InvestigationTitle.INSIGHT,
              details: automatedInvestigation?.investigation,
              icon: <InsightsDetailsIcon width={24} />,
              key: 0
            },
            {
              title: InvestigationTitle.IMPACT,
              details: automatedInvestigation?.impact,
              icon: <Pendulum width={24} />,
              key: 1
            },
            {
              title: InvestigationTitle.REMEDIATION,
              details: automatedInvestigation?.remediation,
              icon: <Wrench width={24} />,
              key: 2
            }
          ].map((e: any) => {
            return <InvestigationDetail isLoading={isLoading} key={e.key} title={e.title} details={e.details} icon={e.icon} />;
          })}
        </AssertionDetailsContainer>
      ) : (
        !isLoading && (
          <Flex fw>
            <LottieAnimation width={150} height={250} lottieJsonFile={SplashScreenIcon} loop={true} />
          </Flex>
        )
      )}
    </AssertionsTabContainer>
  );
};
