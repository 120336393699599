import { Grid } from '@mui/material';
import styled from 'styled-components';
import Typography from '../../core/Typography';
import Flex from '../../core/Flex';

export const GridListContainer = styled(Grid)<{ open?: boolean }>`
  border: none;
  background: inherit;
  position: relative;
  width:100%;
  transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: ${({ theme, open }) => (open ? theme.colors.text.title : theme.colors.new.primary.main)}};
  border-radius: 4px 4px 0px 0px;
  padding: 0 14px;
  height: 46px;
  svg {
    stroke: ${({ theme, open }) => (open ? theme.colors.text.title : theme.colors.new.primary.main)}};
  }
`;

export const AccordionContainer = styled(Flex)`
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  margin: 4px 0;
  border: 1px solid ${({ theme }) => theme.colors.grey[150]};
`;
export const AccordionTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.new.title}};
`;

export const ProductionGridUIContainer = styled.div`
  height: 400px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.grey[100]};
  border-radius: 5px;
  overflow-y: auto;
`;
