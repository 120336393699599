import { ReactComponent as ConfigurationIcon } from '@icons/settings.svg';
import { ReactComponent as TablesIcon } from '@icons/tables.svg';
import { ReactComponent as IndexesIcon } from '@icons/indexes.svg';
import { ReactComponent as QueriesIcon } from '@icons/queries.svg';
import { ProductionIssuesAPIType } from '../../Projects/ProjectItem/ProjectAPI';
import { ProductionTables } from '../components/ProductionTab/ProductionTables';
import { ProductionConfiguration } from '../components/ProductionTab/ProductionConfiguration';
import { ProductionIndexes } from '../components/ProductionTab/ProductionIndexes';
import { ProductionQueries } from '../components/ProductionTab/ProductionQueries';

const calcCacheSummary = (
  data: Array<{
    CACHE_HITS: number;
    DISC_BLOCKS_READ: number;
    [key: string]: any;
  }>
) => {
  const cacheSummary: { CACHE_HITS_SUM: number; DISC_BLOCKS_READ_SUM: number } = { CACHE_HITS_SUM: 0, DISC_BLOCKS_READ_SUM: 0 };
  data.map((transction: any) => {
    cacheSummary.CACHE_HITS_SUM = cacheSummary?.CACHE_HITS_SUM + transction?.CACHE_HITS;
    cacheSummary.DISC_BLOCKS_READ_SUM = cacheSummary?.DISC_BLOCKS_READ_SUM + transction?.DISC_BLOCKS_READ;
  });

  const result: number =
    (cacheSummary?.CACHE_HITS_SUM / (cacheSummary?.DISC_BLOCKS_READ_SUM + cacheSummary?.CACHE_HITS_SUM)) * 100;

  const calcScore = (num: number) => {
    if (num <= 95) return 100;
    if (90 >= num && num < 95) return 80;
    if (70 > num && num <= 90) return 50;
    if (num < 70) return 20;

    return 50;
  };

  return {
    rawSummary: cacheSummary,
    result: result,
    score: calcScore(result)
  };
};

const calcTransactionSummary = (
  data: Array<{
    COMMITED_TRANSACTIONS: number;
    ROLLEDBACK_TRANSACTIONS: number;
    [key: string]: any;
  }>
) => {
  const transactionsSummary: { COMMITED_TRANSACTIONS_SUM: number; ROLLEDBACK_TRANSACTIONS_SUM: number } = {
    COMMITED_TRANSACTIONS_SUM: 0,
    ROLLEDBACK_TRANSACTIONS_SUM: 0
  };
  data.map((transction: any) => {
    transactionsSummary.COMMITED_TRANSACTIONS_SUM =
      transactionsSummary?.COMMITED_TRANSACTIONS_SUM + transction?.COMMITED_TRANSACTIONS;
    transactionsSummary.ROLLEDBACK_TRANSACTIONS_SUM =
      transactionsSummary?.ROLLEDBACK_TRANSACTIONS_SUM + transction?.ROLLEDBACK_TRANSACTIONS;
  });

  const result =
    (transactionsSummary?.ROLLEDBACK_TRANSACTIONS_SUM /
      (transactionsSummary?.COMMITED_TRANSACTIONS_SUM + transactionsSummary?.ROLLEDBACK_TRANSACTIONS_SUM)) *
    100;

  const calcScore = (num: number) => {
    if (num === 0) return 100;
    if (0.01 >= num && num <= 0.5) return 80;
    if (0.5 > num && num <= 3) return 50;
    if (num > 3) return 20;

    return 50;
  };

  return {
    rawSummary: transactionsSummary,
    result: result,
    score: calcScore(result)
  };
};

const calcTempFilesCreation = (data: Array<{ time: Date; value: string }>) => {
  let tempFileCreatedSummary = 0;

  data.map((item: { time: Date; value: string }) => {
    tempFileCreatedSummary = tempFileCreatedSummary + parseInt(item?.value);
  });

  const calcScore = (num: number) => {
    if (num <= 100) return 100;
    if (101 >= num && num <= 5_000) return 80;
    if (1_000 > num && num <= 50_000) return 50;
    if (num > 50_000) return 20;

    return 50;
  };

  return {
    rawSummary: data, // For consistnecy only.
    result: tempFileCreatedSummary,
    score: calcScore(tempFileCreatedSummary)
  };
};

export const calcDatabaseHealthBar = (
  transactionData: Array<{
    COMMITED_TRANSACTIONS: number;
    ROLLEDBACK_TRANSACTIONS: number;
    [key: string]: any;
  }>,
  cacheHitsData: Array<{
    CACHE_HITS: number;
    DISC_BLOCKS_READ: number;
    [key: string]: any;
  }>,
  tempFileCreationData: Array<{ time: Date; value: string }>
) => {
  const transactionResult = calcTransactionSummary(transactionData);
  const cacheResult = calcCacheSummary(cacheHitsData);
  const tempFilesCreationREsult = calcTempFilesCreation(tempFileCreationData);

  const totalScore = (transactionResult?.score + cacheResult?.score + tempFilesCreationREsult?.score) / 3;

  return totalScore;
};

export const ProductionTabItem = {
  configuration: {
    name: 'Configuration',
    icon: <ConfigurationIcon />,
    path: ProductionIssuesAPIType.PRODUCTION_CONFIG,
    component: ProductionConfiguration
  },

  tables: {
    name: 'Tables',
    icon: <TablesIcon />,
    path: ProductionIssuesAPIType.PRODUCTION_TABLES,
    component: ProductionTables
  },

  indexes: {
    name: 'Indexes',
    icon: <IndexesIcon />,
    path: ProductionIssuesAPIType.PRODUCTION_INDEXES,
    component: ProductionIndexes
  },
  queries: {
    name: 'Queries',
    icon: <QueriesIcon />,
    path: ProductionIssuesAPIType.PRODUCTION_QUERIES,
    component: ProductionQueries
  }
};

export const meanBy = (items: any[], by: string): number => {
  const validItems = items.filter(item => item.score !== undefined);
  const average = validItems.length ? validItems.reduce((sum, db ) => {
    if (db[by] && typeof db[by] != "number")
      throw "type is not a number"
    return sum + db[by]
  }, 0) / validItems.length : 0;
  return !average ? 0 : Math.round(average);
};
