import {createGroupNode,createUnionNode, createTableNode, createSortingNode, createResultNode, createEdge, createJoinNode, createGroupByNode } from './mySQLNodeBuilder';
import {SQLOperationType, SQLExplainNode, ParseContext, ParseResult, SQLExplainEdge, NodeResult, NodeDiscoveryResult } from './dbParserTypes';

export const MySQLParser = (data: any, context: ParseContext): ParseResult => {
  const nodes: SQLExplainNode[] = [];
  const edges: SQLExplainEdge[] = [];
  const createdTableNodes = new Map<string, Set<string>>();

  createdTableNodes.set('default', new Set<string>());
  const discoveryResult = discoverNodes(data,context);
  recursiveNodeBuilder(data, context, nodes, edges, null, createdTableNodes, discoveryResult);

  return { nodes, edges };
};

const discoverNodes = (
    obj: any,
    context: ParseContext,
    result: NodeDiscoveryResult = { hasNestedLoop: false, tableNames: new Set(), unionCount: 0, subqueryCount: 0 },
    level: number = 0
): NodeDiscoveryResult => {
  if (!context.discoveryTree) {
    context.discoveryTree = {};
  }

  for (const key in obj) {
    if (key === 'query_block') {
      const queryBlockKey = `query_block_${result.subqueryCount}`;
      context.discoveryTree[queryBlockKey] = {
        type: 'result',
        level: level
      };
      discoverNodes(obj[key], context, result, level + 1);
    } else if (key === 'table') {
      const tableInfo = obj[key];
      const tableNode = {
        table_name: tableInfo.table_name,
        type: 'table',
        level: level
      };
      const tableKey = `table_${tableInfo.table_name}`;
      if (!context.discoveryTree[tableKey]) {
        context.discoveryTree[tableKey] = tableNode;
      }
      if (tableInfo.materialized_from_subquery) {
        result.subqueryCount += 1;
        const subQueryKey = `subquery_${result.subqueryCount}`;
        if (!context.discoveryTree[subQueryKey]) {
          context.discoveryTree[subQueryKey] = {
            type: 'subquery',
            level: level + 1,
            children: 0,
            children_array: []
          };
        }
        const previousSubQueryKey = context.currentSubQueryKey;
        context.currentSubQueryKey = subQueryKey;
        discoverNodes(tableInfo.materialized_from_subquery.query_block, context, result, level + 1);
        context.currentSubQueryKey = previousSubQueryKey;
      }
    } else if (key === 'grouping_operation') {
      const groupingNode = {
        type: 'group_by',
        level: level
      };
      if (context.currentSubQueryKey) {
        context.discoveryTree[context.currentSubQueryKey].children_array.push(groupingNode);
        context.discoveryTree[context.currentSubQueryKey].children += 1;
      }
      discoverNodes(obj[key], context, result, level + 1);
    } else if (key === 'union_result' || key === 'union') {
      result.unionCount += 1;
      const unionKey = `union_${result.unionCount}`;
      context.discoveryTree[unionKey] = {
        type: 'union',
        level: level + 1,
        children: 0,
        children_array: []
      };

      const previousUnionKey = context.currentUnionKey;
      context.currentUnionKey = unionKey;

      if (obj[key].query_specifications) {
        for (const subQuery of obj[key].query_specifications) {
          discoverNodes(subQuery, context, result, level + 1);
        }
      }
      context.currentUnionKey = previousUnionKey;
    }

    if (typeof obj[key] === 'object' && key !== 'materialized_from_subquery' && key !== 'query_block' && key !== 'union_result') {
      discoverNodes(obj[key], context, result, level);
    }
  }
  context.hasUnion = result.unionCount > 0;
  context.hasSubQuery = result.subqueryCount > 0;

  return result;
};


const recursiveNodeBuilder = (
    obj: any,
    context: ParseContext,
    nodes: SQLExplainNode[],
    edges: SQLExplainEdge[],
    parentNodeId: string | null,
    createdTableNodesMap: Map<string, Set<string>>,
    discoveryResult: NodeDiscoveryResult,
    estimatedRows: number | null = null,
    loopId: string = 'default'
): NodeResult => {
  let lastNodeId: string | null = parentNodeId;
  let accumulatedEstimatedRows: number | null = estimatedRows;

  for (const key in obj) {
    if (keyToHandlerMap[key as SQLOperationType]) {
      if (key === 'materialized_from_subquery') {
        const subQueryResult = handleSubQuery(
            obj[key],
            context,
            nodes,
            edges,
            parentNodeId,
            createdTableNodesMap,
            accumulatedEstimatedRows
        );
        if (subQueryResult.nodeId) {
          lastNodeId = subQueryResult.nodeId;
        }
        continue;
      }
      const result = recursiveNodeBuilder(
          obj[key],
          context,
          nodes,
          edges,
          parentNodeId,
          createdTableNodesMap,
          discoveryResult,
          accumulatedEstimatedRows,
          loopId
      );
      if (result.nodeId) {
        lastNodeId = result.nodeId;
      }
      if (result.estimatedRows !== null && result.estimatedRows !== undefined) {
        accumulatedEstimatedRows = result.estimatedRows;
      }

      const currentResult = keyToHandlerMap[key as SQLOperationType](
          obj[key],
          context,
          nodes,
          edges,
          context.currentSubQueryGroupNodeId ? context.currentSubQueryGroupNodeId : lastNodeId,
          createdTableNodesMap,
          accumulatedEstimatedRows,
          loopId
      );

      if (currentResult.nodeId) {
        lastNodeId = currentResult.nodeId;
      }
      if (currentResult.estimatedRows !== null && currentResult.estimatedRows !== undefined) {
        accumulatedEstimatedRows = currentResult.estimatedRows;
      }
      // Create edges without overriding parentId to subquery
      if (
          currentResult.nodeId &&
          parentNodeId &&
          key !== SQLOperationType.NESTED_LOOP &&
          !edges.some(edge => edge.source === currentResult.nodeId && edge.target === parentNodeId)
      ) {
        edges.push(createEdge(currentResult.nodeId, parentNodeId, context));
      }
    }
  }

  return { nodeId: lastNodeId, estimatedRows: accumulatedEstimatedRows || 0 };
};

const handleSubQuery = (
    subQueryData: any,
    context: ParseContext,
    nodes: SQLExplainNode[],
    edges: SQLExplainEdge[],
    parentNodeId: string | null,
    createdTableNodesMap: Map<string, Set<string>>,
    estimatedRows: number | null
): NodeResult => {
  const subQueryGroupNodeId = `subquery_${context.nodeIdCounter++}`;
  const groupNodeWidth = 480;
  const groupNodeHeight = 660;
  const subQueryGroupNode = createGroupNode(subQueryGroupNodeId, context, groupNodeWidth, groupNodeHeight);
  subQueryGroupNode.position = { x: context.currentXPosition, y: context.currentYPosition };
  nodes.push(subQueryGroupNode);
  const previousSubQueryGroupNodeId = context.currentSubQueryGroupNodeId;
  context.currentSubQueryGroupNodeId = subQueryGroupNodeId;
  const previousXPosition = context.currentXPosition;
  const previousYPosition = context.currentYPosition;
  let currentChildX = groupNodeWidth / 4.2;
  let currentChildY = groupNodeHeight - 180;
  const subQueryNodes: SQLExplainNode[] = [];
  const subQueryEdges: SQLExplainEdge[] = [];

  const subQueryResult = recursiveNodeBuilder(
      subQueryData.query_block,
      context,
      subQueryNodes,
      subQueryEdges,
      subQueryGroupNodeId,
      createdTableNodesMap,
      { hasNestedLoop: true, tableNames: new Set(), unionCount: 0, subqueryCount: 1 },
      estimatedRows
  );

  subQueryNodes.forEach((node, index) => {
    node.position.x = currentChildX;
    node.position.y = currentChildY - (index * 200);
    node.position.x += subQueryGroupNode.position.x;
    node.position.y += subQueryGroupNode.position.y;
  });

  nodes.push(...subQueryNodes);
  edges.push(...subQueryEdges);

  if (subQueryNodes.length > 1) {
    for (let i = 0; i < subQueryNodes.length - 1; i++) {
      const sourceNode = subQueryNodes[i];
      const targetNode = subQueryNodes[i + 1];
      if (sourceNode.type !== 'sql-sub-query-node' && targetNode.type !== 'sql-sub-query-node') {
        if (!edges.some(edge => edge.source === sourceNode.id && edge.target === targetNode.id)) {
          edges.push(createEdge(sourceNode.id, targetNode.id, context));
        }
      }
    }
  }

  let finalResultNodeId: string | null = null;
  if (!nodes.some(node => node.id === `query_result_${subQueryGroupNodeId}`)) {
    const resultNode = createResultNode(subQueryData.query_block, context, subQueryResult.estimatedRows, subQueryGroupNodeId);
    resultNode.id = `query_result_${subQueryGroupNodeId}`;
    resultNode.position.x = currentChildX;
    resultNode.position.y = currentChildY - (subQueryNodes.length * 200);
    resultNode.position.x += subQueryGroupNode.position.x;
    resultNode.position.y += subQueryGroupNode.position.y;
    nodes.push(resultNode);
    finalResultNodeId = resultNode.id;

    if (subQueryResult.nodeId && subQueryResult.nodeId !== subQueryGroupNodeId) {
      edges.push(createEdge(subQueryResult.nodeId, resultNode.id, context));
    }
    finalResultNodeId = resultNode.id;
  }
  if (finalResultNodeId) {
    const nextNodeInFlow = nodes.find(
        node =>
            node.data.type !== 'sql-sub-query-node' &&
            node.id !== finalResultNodeId &&
            node.parentId !== subQueryGroupNodeId
    );
    if (nextNodeInFlow) {
      nextNodeInFlow.position.x = previousXPosition;
      nextNodeInFlow.position.y = previousYPosition - 200;
      edges.push(createEdge(finalResultNodeId, nextNodeInFlow.id, context));
    }
  }

  context.currentSubQueryGroupNodeId = previousSubQueryGroupNodeId;
  context.currentXPosition = previousXPosition + 366;
  context.currentYPosition = previousYPosition - 400;

  return { nodeId: finalResultNodeId, estimatedRows };
};

const handleUnion = (
    unionData: any,
    context: ParseContext,
    nodes: SQLExplainNode[],
    edges: SQLExplainEdge[],
    parentNodeId: string | null,
    createdTableNodesMap: Map<string, Set<string>>,
    estimatedRows: number | null
): NodeResult => {
  const numberOfGroups = unionData.query_specifications.length;
  let maxGroupWidth = 0;
  let totalHeight = 0;
  const initialX = context.currentXPosition;
  const initialY = context.currentYPosition;
  const unionNode = createUnionNode(context);
  unionNode.position.x = initialX + 200;
  unionNode.position.y = initialY - 150;
  nodes.push(unionNode);

  unionData.query_specifications.forEach((queryBlock: any, index: number) => {
    const groupNodeId = `group_${index}`;
    const nestedLoopLength = queryBlock.query_block?.nested_loop?.length * 2 || 1;
    const groupWidth = Math.max(nestedLoopLength * 200, 400);
    const groupHeight = Math.max(nestedLoopLength * 220, 500);
    maxGroupWidth = Math.max(maxGroupWidth, groupWidth);
    totalHeight += groupHeight + 100;
    const groupInitialX = initialX + (index * (groupWidth + 300));
    const groupInitialY = initialY;

    const groupNode = createGroupNode(groupNodeId, context, groupWidth, groupHeight);
    groupNode.position.x = groupInitialX;
    groupNode.position.y = groupInitialY;
    nodes.push(groupNode);
    context.currentXPosition = 0;
    context.currentYPosition = 0;

    const subNodes: SQLExplainNode[] = [];
    const queryBlockResult = recursiveNodeBuilder(
        queryBlock.query_block,
        context,
        subNodes,
        edges,
        groupNode.id,
        createdTableNodesMap,
        {
          hasNestedLoop: true,
          tableNames: new Set(),
          unionCount: 0,
          subqueryCount: 0
        },
        estimatedRows,
        groupNodeId
    );

    subNodes.forEach((node, idx) => {
      if (!node.parentId) {
        node.parentId = groupNodeId;
      }
      node.position.x += subNodes.length === 1 ? (groupWidth / 1.25): (groupWidth * 1.125)
      node.position.y +=   groupHeight - (150 * (idx + 1));
    });

    nodes.push(...subNodes);

    if (!nodes.some(node => node.id === `query_result_${groupNodeId}`)) {
      const resultNode = createResultNode(queryBlock.query_block, context, queryBlockResult.estimatedRows, groupNode.id);
      resultNode.id = `query_result_${groupNodeId}`;
      nodes.push(resultNode);
      resultNode.position.x = (groupWidth / 2 - 125)
      resultNode.position.y = groupNode.position.y + 50;

      if (queryBlockResult.nodeId) {
        edges.push(createEdge(queryBlockResult.nodeId, resultNode.id, context));
      }
      edges.push(createEdge(resultNode.id, unionNode.id, context));
    }
  });

  const unionNodeX = initialX + (maxGroupWidth * numberOfGroups) / 2;
  const unionNodeY = initialY - (totalHeight / 5);
  unionNode.position.x = unionNodeX;
  unionNode.position.y = unionNodeY;
  context.currentXPosition = unionNodeX;
  context.currentYPosition = unionNodeY - 200;
  const nextNodeInFlow = nodes.find(node => node.data.type === 'result' && node.id.startsWith('node_') && node.id !== unionNode.id);

  if (nextNodeInFlow) {
    nextNodeInFlow.position.x = context.currentXPosition;
    nextNodeInFlow.position.y = context.currentYPosition;
    edges.push(createEdge(unionNode.id, nextNodeInFlow.id, context));
  }

  if (parentNodeId && nextNodeInFlow) {
    edges.push(createEdge(nextNodeInFlow.id, parentNodeId, context));
  }

  return { nodeId: unionNode.id, estimatedRows };
};

const handleQueryBlock = (
    queryBlock: any,
    context: ParseContext,
    nodes: SQLExplainNode[],
    edges: SQLExplainEdge[],
    parentNodeId: string | null,
    createdTableNodesMap: Map<string, Set<string>>,
    estimatedRows: number | null
): NodeResult => {
  const resultNode = createResultNode(queryBlock, context, estimatedRows);
  nodes.push(resultNode);

  if (parentNodeId && !edges.some(edge => edge.source === parentNodeId && edge.target === resultNode.id)) {
    edges.push(createEdge(parentNodeId, resultNode.id, context));
  }

  return { nodeId: resultNode.id, estimatedRows };
};
const handleGroupingOperation = (
    groupingOperation: any,
    context: ParseContext,
    nodes: SQLExplainNode[],
    edges: SQLExplainEdge[],
    parentNodeId: string | null,
    createdTableNodesMap: Map<string, Set<string>>,
    estimatedRows: number | null
): NodeResult => {
  const groupByNode = createGroupByNode(context, parentNodeId || undefined);
  nodes.push(groupByNode);
  context.currentYPosition += context.verticalSpacing;

  // Ensure the edge between the parent (table) and this group by node
  if (parentNodeId && !edges.some(edge => edge.source === parentNodeId && edge.target === groupByNode.id)) {
    edges.push(createEdge(parentNodeId, groupByNode.id, context));
  }

  return { nodeId: groupByNode.id, estimatedRows };
};


const handleTable = (
    table: any,
    context: ParseContext,
    nodes: SQLExplainNode[],
    edges: SQLExplainEdge[],
    parentNodeId: string | null,
    createdTableNodesMap: Map<string, Set<string>>,
    estimatedRows: number | null,
    loopId: string
): NodeResult => {
  const currentSet = createdTableNodesMap.get(loopId) || new Set<string>();

  if (currentSet.has(table.table_name)) {
    return { nodeId: parentNodeId, estimatedRows };
  }

  currentSet.add(table.table_name);
  createdTableNodesMap.set(loopId, currentSet);

  // Only add parentId if inside subquery
  const tableNode = createTableNode(table, context, context.currentSubQueryGroupNodeId ? context.currentSubQueryGroupNodeId : undefined);
  nodes.push(tableNode);

  const tableRows = table.rows_produced_per_join || estimatedRows || 0;

  // Create edges, but do not connect to subquery if it should be independent
  if (parentNodeId && !edges.some(edge => edge.source === parentNodeId && edge.target === tableNode.id)) {
    edges.push(createEdge(parentNodeId, tableNode.id, context));
  }

  return { nodeId: tableNode.id, estimatedRows: tableRows };
};

const handleOrderingOperation = (
    orderingOperation: any,
    context: ParseContext,
    nodes: SQLExplainNode[],
    edges: SQLExplainEdge[],
    parentNodeId: string | null,
    createdTableNodesMap: Map<string, Set<string>>,
    estimatedRows: number | null
): NodeResult => {
  const sortingNode = createSortingNode(context, parentNodeId || undefined);
  nodes.push(sortingNode);

  context.currentYPosition += context.verticalSpacing;

  if (parentNodeId && !edges.some(edge => edge.source === parentNodeId && edge.target === sortingNode.id)) {
    edges.push(createEdge(parentNodeId, sortingNode.id, context));
  }

  return { nodeId: sortingNode.id, estimatedRows };
};

const handleNestedLoop = (
    nestedLoops: any[],
    context: ParseContext,
    nodes: SQLExplainNode[],
    edges: SQLExplainEdge[],
    parentNodeId: string | null,
    createdTableNodesMap: Map<string, Set<string>>,
    estimatedRows: number | null,
    loopId: string
): NodeResult => {
  let lastJoinNodeId: string | null = parentNodeId;
  let finalEstimatedRows: number | null = estimatedRows;

  const nestedLoopId = `${loopId}-nestedloop-${context.nodeIdCounter}`;

  nestedLoops.forEach((loop: any, index: number) => {
    if (!createdTableNodesMap.has(nestedLoopId)) {
      createdTableNodesMap.set(nestedLoopId, new Set<string>());
    }

    const currentSet = createdTableNodesMap.get(nestedLoopId)!;

    if (!currentSet.has(loop.table.table_name)) {
      context.currentXPosition -= context.horizontalSpacing;

      const tableNode = createTableNode(loop.table, context, parentNodeId || undefined);
      nodes.push(tableNode);
      currentSet.add(loop.table.table_name);

      if (index === 0) {
        lastJoinNodeId = tableNode.id;
        finalEstimatedRows = loop.table.rows_produced_per_join;
      } else {
        const maxFilteredRows = Math.max(Number(loop.table.rows_produced_per_join), context.latestRowsAfterFilter);
        finalEstimatedRows = maxFilteredRows;
        const joinNode = createJoinNode(maxFilteredRows, loop.table.filtered || 'No Filter', context, parentNodeId || undefined);
        nodes.push(joinNode);
        edges.push(createEdge(lastJoinNodeId!, joinNode.id, context));
        edges.push(createEdge(tableNode.id, joinNode.id, context));
        lastJoinNodeId = joinNode.id;
      }
    }
  });

  if (lastJoinNodeId && parentNodeId) {
    edges.push(createEdge(lastJoinNodeId, parentNodeId, context));
  }
  return { nodeId: lastJoinNodeId, estimatedRows: finalEstimatedRows };
};


const keyToHandlerMap: Record<
    any,
    any
> = {
  [SQLOperationType.QUERY_RESULT]: handleQueryBlock,
  [SQLOperationType.TABLE]: handleTable,
  [SQLOperationType.GROUP_BY]: handleGroupingOperation,
  [SQLOperationType.SORT]: handleOrderingOperation,
  [SQLOperationType.NESTED_LOOP]: handleNestedLoop,
  [SQLOperationType.UNION]: handleUnion,
  [SQLOperationType.SUBQUERY]: handleSubQuery
};

