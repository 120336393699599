import styled from 'styled-components';

import Flex from '../../../core/Flex';
import { SvgImg } from '../../../core/SvgImg';
import Typography from '../../../core/Typography';

export const PullHeader = styled(Flex)`
  width: 100%;
`;
export const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.main};
  padding: ${({ pd }) => (pd ? '0 15px' : 0)};
`;
export const SectionTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.main};
`;
export const SectionHeaderTitle = styled(Typography)`
  padding: 8px 0 8px 8px;
  color: ${({ theme }) => theme.colors.text.main};
`;
export const ProductionTitle = styled(Typography)`
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.text.title};
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 360px;
`;
export const EmptySectionText = styled(Typography)`
  padding: 20px 15%;
`;
export const CardTextTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary.border};
`;
export const ApiKeyTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.info.dark};
`;

export const FlexWrapper = styled(Flex)`
  width: 100%;
`;
export const PopoverContainer = styled(Flex)`
  padding: 5px 10px;
`;
export const KeyImg = styled(SvgImg)`
  height: 25px;
  width: 25px;
  fill: ${({ theme }) => theme.colors.info.dark};
`;

export const Section = styled(Flex)`
  align-items: center;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.grey[150]};
  border-radius: 4px;
  min-width: 330px;
  width: 33%;
  margin-right: 24px;
`;
export const EnvSection = styled(Section)`
  width: 66%;
  margin: 0;
`;
export const SectionHeader = styled(Flex)`
  width: 100%;
  align-self: start;
  justify-content: center;
  background: ${({ theme }) => theme.colors.grey[50]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[150]};
`;
export const SectionBody = styled(Flex)`
  width: 100%;
  height: calc(100vh - 185px);
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 8px;
`;
export const SectionProdBody = styled(Flex)`
  width: 50%;
`;
export const SectionEnvBody = styled(SectionBody)`
  border-right: 1px solid ${({ theme }) => theme.colors.grey[150]};
`;
export const SectionPullBody = styled(Flex)`
  height: inherit;
  width: 100%;
`;
export const SectionEmpty = styled(Flex)`
  text-align: center;
  min-height: 250px;
`;
export const PullWrapper = styled(Flex)`
  padding: 8px 16px;
  width: inherit;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.grey[150]};
`;

export const ApiKeyContainer = styled(Flex)`
  padding: 5px 10px;
  cursor: pointer;
  &:hover {
    filter: brightness(70%);
  }
`;
export const ContainerItem = styled(Flex)`
  margin: 4px 0;
  align-items: start;
  width: calc(100% - 12px);
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  box-shadow: 0px 1px 1px rgba(9, 18, 53, 0.18), 0px 4px 4px rgba(34, 36, 94, 0.07);
  .get-settings {
    display: none;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.grey[20]};
    .get-settings {
      display: block;
    }
  }
  &:first-child {
    margin-top: 8px;
  }
`;
