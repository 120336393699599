import styled, { useTheme } from 'styled-components';
import { useState } from 'react';
import { BarChart, Bar, ResponsiveContainer, Cell } from 'recharts';

import { ShortDateFormat, capitalizeFirstLetter, getFormattedDate, toNumbersSuffix, convertKbToSize } from '../../../utils/utils';
import Typography from '../../../core/Typography';
import MTooltip from '../../../core/Tooltip/Tooltip';

const CellContainer = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
`;

const BarContainer = styled(BarChart)`
  margin-bottom: -4px;
  margin-top: 10px;
`;
const BorderBottom = styled.div<{ maxWidth: number }>`
  height: 2px;
  width: ${({ maxWidth }) => `${maxWidth}px`};
  background: ${({ theme }) => theme.colors.chart.main};
`;

const BarYText = styled(Typography)<{ maxWidth: number }>`
  width: ${({ maxWidth }) => `${maxWidth}px`};
  top: -8px;
  position: absolute;
  text-align: center;
  z-index: 5;
  color: ${({ theme }) => theme.colors.grey[500]};
`;
interface TrendDataGridCellI {
  data: any[];
  payloadName?: string;
  width?: number;
  height? : number;
  hideTitle?: boolean;
  dateFormat?: ShortDateFormat;
  valueTooltipFormatting?: (value: number) => string | 0;
  title?: string;
  sizeIsKB? : boolean;
}

export const TrendDataGridCell = ({
        data,
        payloadName = 'Calls ',
        width = 150,
        height = 60,
        hideTitle = false,
        dateFormat = 'l',
        valueTooltipFormatting,
        title = 'Max in 7 days: ',
        sizeIsKB
}: TrendDataGridCellI) => {
  const [focusBar, setFocusBar] = useState<number | null>(null);
  const [mouseLeave, setMouseLeave] = useState(false);
  const [tooltipState, setTooltipState] = useState<string[]>([]);
  const { colors }: any = useTheme();
  if (!data || data.length === 0) {
    return <></>;
  }
  const formatTooltip = (time: string, value: number, sizeIsKB: boolean = false): void => {
      const valueFormat = sizeIsKB
          ? convertKbToSize(value)
          : (valueTooltipFormatting?.(value) || `${toNumbersSuffix(value).num} ${toNumbersSuffix(value).suffix}`);

      setTooltipState(['Date: ' + getFormattedDate(time, dateFormat), capitalizeFirstLetter(payloadName) + ': ' + valueFormat]);
  };
  const showBar = data.reduce((partialSum: any, a: { value: any }) => Math.max(partialSum, a.value), 0);
  return (
    <CellContainer>
      <MTooltip
        title={
          <Typography>
            {tooltipState[0]} <br />
            {tooltipState[1]}
          </Typography>
        }
        toolTipProps={{ placement: 'top-start' }}
      >
        {!hideTitle && (
            sizeIsKB ? (
                <BarYText s weight="500" maxWidth={width}>
                  {title}
                  {convertKbToSize(showBar)}
                  {payloadName && ` ${payloadName}`}
                </BarYText>
            ) : (
                <BarYText s weight="500" maxWidth={width}>
                  {title}
                  {valueTooltipFormatting?.(showBar) || toNumbersSuffix(showBar).num + toNumbersSuffix(showBar).suffix}{' '}
                  {!valueTooltipFormatting && payloadName}
                </BarYText>
            )
        )}
        <ResponsiveContainer width="100%" height={60}>
          <>
            <BarContainer
              width={width}
              height={height}
              data={data}
              onMouseMove={(state) => {
                if (state?.activePayload?.[0]?.payload?.time) {
                  formatTooltip(state.activePayload[0]?.payload?.time, state.activePayload[0]?.payload?.value, sizeIsKB);
                  setFocusBar(state.activeTooltipIndex ?? null);
                  setMouseLeave(true);
                } else {
                  setFocusBar(null);
                  setMouseLeave(false);
                }
              }}
            >
              <Bar
                minPointSize={1}
                dataKey="value"
                legendType="line"
                type="monotone"
                barSize={10}
                background={{ fill: '#F9FAFB' }}
              >
                {data?.map((_: any, index: number) => (
                  <Cell
                    key={`cell-${index}`}
                    stroke={focusBar === index && mouseLeave ? colors.grey[200] : 'none'}
                    strokeWidth={0.5}
                    fill={focusBar === index && mouseLeave ? colors?.chart?.hover : colors?.chart?.main}
                  />
                ))}
              </Bar>
            </BarContainer>
            <BorderBottom maxWidth={width} />
          </>
        </ResponsiveContainer>
      </MTooltip>
    </CellContainer>
  );
};
