import styled, { css } from 'styled-components';
import { ReactComponent as CloseIcon } from '@icons/close-bold.svg';
import { ReactComponent as ArrowIcon } from '@icons/arrow-down.svg';
import { ReactComponent as SortIcon } from '@icons/sort.svg';
import { AgGridReact } from 'ag-grid-react';
import { ReactComponent as DeleteIcon } from '@icons/trash.svg';
import { ReactComponent as SettingsI } from '@icons/settings.svg';
import { ReactComponent as ChartI } from '@icons/barChart.svg';
import { ReactComponent as HistoryI } from '@icons/history.svg';
import { ReactComponent as ArrowI } from '@icons/arrow-down.svg';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import Flex from '../../core/Flex';
import Typography from '../../core/Typography';
import { breatheAnimation, pulseAnimation, shake } from '../../utils/animation';

export const AdviceContainer = styled(Flex)`
  padding: 12px 24px;
`;

export const Container = styled(Flex)`
  height: inherit;
  width: 100%;
`;

export const ErdContainer = styled(Flex)`
  height: inherit;
  width: 100%;
  flex-direction: row;
`;

export const TitleWrapper = styled(Typography)`
  margin: 2px 0;
`;
export const CellTitle = styled(Typography)`
  display: flex;
  height: inherit;
  align-items: center;
`;
export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.title};
`;

export const TitleDrawer = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.main};
  padding: ${({ padding }) => padding || '0 16px'};
`;
export const TitleTooltip = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.main};
`;
export const TextDrawer = styled(Typography)`
  padding-left: 3px;
`;
export const MainInnerDrawer = styled(Flex)`
  position: absolute;
  z-index: 4;
  right: -12px;
  top: -45px;
  height: calc(100vh - 40px);
  background-color: ${({ theme }) => theme.colors.white};
  border-left: 1px solid ${({ theme }) => theme.colors.new.border};
`;
export const HeaderDrawer = styled(Flex)`
  width: 100%;
  height: 37px;
  background: ${({ theme }) => theme.colors.grey[50]};
`;

export const LabelWrapper = styled(Flex)`
  margin: 0 0 0 12px;
`;

export const TableHeader = styled(Flex)`
  width: 100%;
  height: 40px;
`;
export const CardWrapper = styled(Flex)`
  padding: 16px;
  margin: 4px 0;
  width: calc(100% - 32px);
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.grey[150]}`};
`;
export const BodyDrawer = styled(Flex) <{ width: string }>`
  width: ${({ width }) => (width ? width : '65vw')};
  flex-direction: column;
  align-items: start;
  justify-content: start;
`;
export const Close = styled(CloseIcon)`
  cursor: pointer;
  height: 20px;
  width: 20px;
  stroke: ${({ theme }) => theme.colors.text.main};
`;
export const ArrowWrapper = styled.div`
  position: absolute;
  right: 8px;
  padding-top: 5px;
`;
export const Delete = styled(DeleteIcon)`
  cursor: pointer;
  stroke: ${({ theme }) => theme.colors.new.primary.main};
  &:hover {
    stroke: ${({ theme }) => theme.colors.assertions.errors};
  }
`;
export const SettingsIcon = styled(SettingsI)`
  height: 18px;
  width: 18px;
  stroke: ${({ theme }) => theme.colors.text.title};
`;
export const BarIcon = styled(ChartI)`
  height: 18px;
  width: 18px;
  stroke: ${({ theme }) => theme.colors.text.title};
`;

export const HistoryIcon = styled(HistoryI)`
  height: 18px;
  width: 18px;
  stroke: ${({ theme }) => theme.colors.text.title};
`;

export const Arrow = styled(ArrowIcon)`
  height: 20px;
  width: 20px;
  transform: rotate(-90deg);
  stroke: ${({ theme }) => theme.colors.info.main};
`;
export const SortIconWrapper = styled(Flex) <{ isActive?: boolean }>`
  width: 20px;
  transform: ${({ isActive }) => (isActive ? 'rotate(180deg)' : '')};
`;
export const MoreWrapper = styled(Flex)`
  padding: 4px 12px;
  border-radius: 12px;
    border: 1px solid ${({ theme }) => theme.colors.grey[100]};
  cursor: pointer;
    margin 10px;
  animation-name: ${pulseAnimation};
  animation-duration: 2s;
  animation-iteration-count: 2;
`;
export const MoreIcon = styled(ArrowI)`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  fill: ${({ theme }) => theme.colors.info.main};
`;
export const SortI = styled(SortIcon)`
  height: 20px;
  width: 20px;
  stroke: ${({ theme }) => theme.colors.info.main};
`;

export const TabWrapper = styled.div`
  width: 914px;
  overflow-x: auto;
  height: calc(100vh - 85px);
`;
export const PlanWrapper = styled.div`
  height: 86vh;
  overflow: hidden;
`;
export const WrapperList = styled.div`
  border-radius: 3px;
  overflow-y: auto;
  height: calc(100vh - 140px);
  overflow-x: hidden;
  width: inherit;
  border: 1px solid ${({ theme }) => theme.colors.grey[100]};
`;
export const ContainerItem = styled.div<{ isActive: boolean }>`
  width: inherit;
  background: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  border: 0.5px solid ${({ theme }) => theme.colors.grey[100]};
  position: relative;
  .arrow-icon-item {
    display: none;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.grey[20]};
    .arrow-icon-item {
      display: block;
    }
  }
  ${({ isActive }) =>
    isActive &&
    css`
      animation-name: ${breatheAnimation};
      animation-duration: 2s;
      animation-iteration-count: infinite;
    `}
`;

export const TooltipContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: 5px;
  box-shadow: 0px 4px 16px 2px rgba(0, 0, 0, 0.38);
`;

export const GridItem = styled(Flex) <{ isClickable: boolean }>`
  height: 100%;
  word-break: break-all;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
`;

export const TabContainer = styled(Flex)`
  padding: 12px 16px;
  flex-direction: column;
  border-radius: 6px;
  justify-content: start;
  align-items: start;
  width: calc(100% - 38px);
  overflow-x: auto;
  height:  ${({ noHeight }) => (noHeight ? null : `calc(100vh - 150px)`)}; 
`;
export const DrawerItemWrapper = styled(Flex)`
  max-width: 990px;
  align-item: start;
  overflow-x: hidden;
`;

export const DataGridUIContainer = styled.div<{ height?: string }>`
  height: ${({ height }) => `calc(100vh - ${height ? height : '100px'})`};
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.grey[100]};
  border-radius: 5px;
  overflow-y: auto;
`;
export const HistoryContainer = styled(Flex) <{ height?: string }>`
  height: ${({ height }) => `calc(100vh - ${height ? height : '100px'})`};
  overflow-y: auto;
  align-items: start;
`;

export const DataGridUI = styled(AgGridReact) <{ onRowClicked: any }>`
  font-family:
    Inter,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
  width: inherit;
  .ag-row,
  .ag-header-cell-label .ag-header-cell-text {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    line-height: 22px;
  }
  .ag-row {
    ${({ onRowClicked }) =>
    !!onRowClicked &&
    css`
        cursor: pointer;
      `}
  }
  .ag-row:hover::before {
    background: ${({ theme }) => theme.colors.new.primary[150]};
  }

  .ag-cell {
    color: ${({ theme }) => theme.colors.grey[500]};
  }
  .disabled-row {
    background-color: ${({ theme }) => theme.colors.grey[50]};
    opacity: 0.5;
  }
  .disabled-row:hover > * {
    cursor: auto;
    background-color: ${({ theme }) => theme.colors.grey[50]};
  }
`;

export const ToggleWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 3px;
  z-index: 6;
`;

export const Wrapper = styled.div`
  height: 100%;
  position: relative;
`;

export const SubTitle = styled(Typography)`
  padding-right: 3px;
  color: ${({ theme }) => theme.colors.text.main};
`;
export const MainTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.new.primary.main};
`;

export const HistoryCardContainer = styled(Flex)`
  justify-content: start;
  width: calc(100% - 64px);
  padding: 8px 20px 0px;
`;

export const HistoryText = styled(Typography)`
  max-width: calc(100vw - 850px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const DividerTitle = styled(Typography)`
  width: 120px;
  text-align: center;
  color: ${({ theme }) => theme.colors.text.title};
`;

export const Divider = styled(Flex)`
  width: 100%;
  height: 1px;
  padding: 0.5px 0;
  margin: 16px 0;
  background: ${({ theme }) => theme.colors.grey[150]};
`;

export const InfoIcon = styled.div`
  margin-right: 12px;
  margin-top: 5px;
  width: 20px;
  height: 20px;
`;

export const DescriptionInfoWrapper = styled(Flex)`
  border-radius: 6px;
  padding: 12px 24px;
  width: calc(100% - 48px);
  justify-content: start;
  border: 1px solid ${({ theme }) => theme.colors.new.info[100]};
  background: ${({ theme }) => theme.colors.new.info[150]};
`;

export const TagLabelWrapper = styled(Flex)`
  width: 130px;
  margin-right: 12px;
`;

export const SuggestionWrapper = styled(Flex)`
  margin: 16px 0;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.new.defaultBG};
`;

export const QueryCardWrapper = styled(Flex) <{ isActive: boolean }>`
  padding: 12px 18px;
  border-radius: 8px;
  cursor: pointer;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.grey[150]};
  background: ${({ theme }) => theme.colors.white};
  flex: 1;
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.primary.main};
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);
  }
  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.colors.text.title};
      border: 1px solid ${({ theme }) => theme.colors.primary.main};
    `}
`;

export const Contract = styled(KeyboardDoubleArrowRightIcon)`
  cursor: pointer;
  padding-top: 0.25rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  height: 1.25rem;
  width: 1.25rem;
  stroke: ${({ theme }) => theme.colors.text.main};
`;
