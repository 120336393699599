import styled from 'styled-components';
import Flex from '../../core/Flex';

export const AssertionsTabContainer = styled.div<{ headHeight?: string }>`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: inherit;
  position: relative;
  height: ${({ headHeight }) => (headHeight ? `calc(100vh - ${headHeight})` : 'calc(100vh - 330px)')};
`;

export const AssertionContainer = styled.div`
  flex: 0.25;
  min-width: 250px;
  overflow: scroll;
  padding: 5px 0;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.grey[150]};
  background: ${({ theme }) => theme.colors.white};
`;

export const AssertionDetailsContainer = styled.div`
  flex: 0.75;
  width: 100%;
  overflow: auto;
`;

export const AssertionDetailsTitleContainer = styled(Flex)`
  width: calc(100% - 24px);
  padding-left: 24px;
`;
