import { createTheme } from '@mui/material';
import { createGlobalStyle } from 'styled-components';

export const SideMenuWidth = '225px';
export const GlobalStyles = createGlobalStyle`
   body {
    background: #FCFCFD;
    color: #556077;
    margin:0;
    padding:0;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: overlay;
  }
  /* width */
::-webkit-scrollbar {
  width: 5px;
  height:5px;
}
ol {
  padding: 0px 0px 0px 10px;
}

li::marker {
   font-size: 1.1em;
   font-weight: 600;
   color: #4B4ECD;
}
.linkTo {
  text-decoration: none;
  color: #556077;
}
.linkToFull {
  text-decoration: none;
  color: #556077;
  width:100%;
  height:100%;
}
.linkToDisabled {
  text-decoration: none;
  color: #556077;
  pointer-events: none;
}

.disabled-true {
  color: #CED0D6;
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}
.recharts-layer {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.recharts-brush-texts {
  display:none;
}
/* Track */
 ::-webkit-scrollbar-track {
  background: transparent;
} 

/* Handle */
::-webkit-scrollbar-thumb {
  background: #D3D3DF;
  border-radius: 5px;
}

/* AgGridReact */
.ag-theme-material {
  --ag-header-height: 40px;
  --ag-header-foreground-color: #383975; //grey[50]
  --ag-header-background-color: #F1F1F6; //text.main
  --ag-header-cell-hover-background-color: #F9F9FB; //grey[20]
  // --ag-header-cell-moving-background-color: rgb(80, 40, 140);
}
// Monaco Editor 
.appended-text {
  background-color: #4b4ecd;
  opacity: 0.7;
  border-radius: 1px;
  padding: 2px;
  color: white !important;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease-in-out;
}
.selected-table {
  background-color: green;
  color:yellow;
}
.appended-text:hover {
  opacity: 1;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border: 1px red solid;
}

`;
export const theme = {
  colors: {
    primary: { light: '#0097FB', main: '#0E639C', dark: '#050b20', border: '#7285F0' },
    secondary: { dark: '#091235', hover: '#222A49' },
    info: { main: '#4B4ECD', hover: '#3135BF', dark: '#667BEB' },
    error: {
      critical: '#F20530',
      criticalBg: '#FEE7EB',
      high: '#FF790B',
      medium: '#FFCC00',
      low: '#03D0FF',
      healthy: '#63D471',
      skipped: '#FFCC00'
    },
    assertions: { success: '#03D0FF', fail: '#F20530', skipped: '#FFCC00', errors: '#F47C7C', check: '#00CB6C' },
    tablesInfo: { alert: '#ff7b7b' },
    grey: {
      20: '#F8F9FB',
      50: '#F2F4F7',
      100: '#E4E5F8',
      150: '#E4E6EB',
      200: '#C9CAF0',
      300: '#CED0D6',
      350: '#EBEBF1',
      400: '#6B7383',
      450: '#A7A8D7',
      500: '#556077',
      600: '#E9E9E9',
    },
    text: {
      title: '#4B4ECD',
      main: '#383975',
      disabled: '#9FA5B3'
    },
    new: {
      border: '#D0D5DD',
      primary: {
        50: '#0023DD',
        100: '#AAB6F4',
        125: '#DDE2FB',
        150: '#F2F4FE',
        main: '#344054'
      },
      secondary: {
        50: '#5E47D2',
        125: '#E7E3FC',
        150: '#F9F8FE',
        text: '#00A6DB',
        main: '#101828'
      },
      info: {
        50: '#00A6DB',
        100: '#AAE6F9',
        150: '#F8FDFE'
      },
      success: {
        50: '#00A95A',
        100: '#D5F0E2',
        150: '#ECFDF3'
      },
      warning: {
        50: '#FFF8F9',
        100: '#FCB9C5',
        150: '#FFF8F9',
        200: '#FFECEB'
      },
      medium: {
        150: '#ffcc0033'
      },
      high: {
        150: '#ff790b33'
      },
      title: '#667085'
    },
    white: '#fff',
    defaultBG: '#FCFCFD',
    chart: {
      main: '#b7cdef',
      hover: '#608EDC',
      secondary: '#ACC8F9'
    }
  }
};

export const themeMui = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#4B4ECD',
      contrastText: '#383975'
    },
    secondary: {
      main: '#4B4ECD',
      contrastText: '#fff'
    },
    grey: {
      50: '#F1F1F6',
      500: '#556077'
    },
    action: {
      hover: '#F2F3FC'
    }
  },
  typography: {
    fontSize: 13,
    fontFamily:
      'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '&.Mui-disabled': {
            color: '#fff'
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: '#091235',
          color: '#fff',
          boxShadow: 'none'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          color: '#383975',
          borderColor: '#D7D7E3',
          '& input': {
            padding: '4px 8px'
          }
        },
        notchedOutline: {
          borderColor: '#D7D7E3'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: '34px'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: '0 15px 8px',
          minHeight: '30px',
          textTransform: 'none',
          fontWeight: 500,
          fontSize: '14px',
          color: '#556077',
          svg: {
            stroke: '#556077'
          },
          '&.Mui-selected': {
            svg: {
              stroke: '#4B4ECD'
            }
          },
          '&:hover': {
            color: '#4B4ECD',
            svg: {
              stroke: '#4B4ECD'
            }
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          border: 0,
          minHeight: '30px',
          borderBottom: 1,
          borderColor: '#E4E5F8',
          borderStyle: 'solid'
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          '.MuiList-root': {
            padding: 0,
            borderRadius: '8px',
            minHeight: '44px'
          },
          '.MuiMenu-paper': {
            border: '1px solid #EAECF0',
            boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.13)'
          }
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 40,
          height: 22,
          padding: 0,
          margin: '0 8px',
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: '2px 0',
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                backgroundColor: '#4B4ECD',
                opacity: 1,
                border: 0
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                backgroundColor: '#D0D5DD'
              }
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
              color: '#33cf4d',
              border: '6px solid #fff'
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              color: '#E4E5F8'
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.3
            }
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 18,
            height: 18,
            margin: '0 4px'
          },
          '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: '#E4E5F8',
            opacity: 1,
            transition: 'background-color 0.5s'
          }
        }
      }
    }
  }
});
