import { atom } from 'recoil';
import { SnackbarSeverityE } from '../enums/snackbar-severity.enum';

export interface SnackbarStateI {
  open: boolean;
  severity: any;
  text: string;
  cb?: any;
  descriptions?: string;
}

export const snackbarState = atom<SnackbarStateI>({
  key: 'snackbarState',
  default: {
    open: false,
    severity: SnackbarSeverityE.info,
    text: 'default',
    descriptions: ''
  }
});
