import { Skeleton } from '@mui/material';
import styled from 'styled-components';
import Flex from '../../core/Flex';
interface TableLoaderProps {
  size?: number;
}

const TableLoader = ({ size = 5 }: TableLoaderProps) => {
  const list = [];
  for (let index = 1; index <= size; index++) {
    list.push(
      <Row key={index} justify="space-between">
        <Skeleton sx={{ ml: 2 }} animation="pulse" variant="rectangular" width={40} height={25} />
        <Skeleton animation="pulse" variant="rectangular" width="35%" height={20} />
        <Skeleton animation="pulse" variant="rectangular" width="35%" height={20} />
        <Skeleton sx={{ mr: 2 }} animation="pulse" variant="rectangular" width={80} height={25} />
      </Row>
    );
  }
  return (
    <Container direction="column" justify="start">
      <Skeleton sx={{ mt: -2 }} animation="pulse" variant="text" width={'100%'} height={70} />
      {list.map((item) => item)}
    </Container>
  );
};

export default TableLoader;

const Row = styled(Flex)`
  width: 100%;
  height: 45px;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.grey[100]};
`;
const Container = styled(Flex)`
  width: 100%;
  border-radius: 5px;
  overflow: auto;
`;
