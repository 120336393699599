import styled from "styled-components";
import Flex from "../../core/Flex";
import {theme} from "../../theme";
import {GridContainer} from "../LogAnalysis/LogAnalyzer.styled";
import Typography from "../../core/Typography";
import { ReactComponent as ArrowI } from '@icons/arrow-down.svg';

export const Container = styled.div<{ open: boolean }>`
    overflow: auto;
    width:${({ open }) => (open ? `calc(100% - 250px)` : '100%')}};
    transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
`;


export const HostContainer = styled(`div`)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'start',
    width: '100%',
    whiteSpace: 'nowrap',
    height: '100%',
});

export const HostTitle = styled(`div`)({
    display: 'flex',
    flex: 6,
    alignItems: 'center',
    height: '100%',
    color: theme.colors.new.secondary.main,
    marginTop: '23px',
});

export const InfoContainer = styled(`div`)({
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    height: '100%',
    justifyContent: 'space-between',
});

export const Info = styled(`div`)({
    display: 'flex',
    margin: '3px',
});

export const VerticalDivider = styled(`div`)({
    height: '70px',
    width: '1px',
    marginRight: '13px',
    background: theme.colors.grey[150]
});

export const DatabaseContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    white-space: nowrap;
    height: 100%;
    padding: 10px;
    cursor: pointer;

    &:hover {
        background-color: #f6f6f6;
    }
`;

export const InfoHorizontalContainer = styled(`div`)({
    display: 'flex',
    flexDirection: 'row',
    flex: 2,
    height: '100%',
    minWidth: '43%',
});

export const DatabaseTitle = styled(`div`)({
    display: 'flex',
    flex: 6,
    alignItems: 'center',
    height: '100%',
    color: theme.colors.new.secondary.main,
});

export const HostGridListContainer = styled(GridContainer)`
    border: none;
    background: inherit;
`;


export const DatabaseGridListContainer = styled(GridContainer)`
    display: flex;
    padding: 8px;
    text-decoration: none;
    background: ${({ theme }) => theme.colors.white};
    border: 0.5px solid ${({ theme }) => theme.colors.grey[100]};
    &:hover {
        background: ${({ theme }) => theme.colors.grey[20]};
    }
    ${({isClickable}) => {
    if(isClickable) {
        return `
            &:hover {
                cursor: pointer;
            }
        `;
    }}}
`;

export const TagContainer = styled.div`
    width: 70%;
`;

export const DividerTitle = styled(Typography)`
  width: 150px;
  text-align: center;
  color: ${({ theme }) => theme.colors.text.title};
`;

export const TagLabelWrapper = styled(Flex)`
  width: 100%;
  margin-top: 8px;
    display: flex;
    margin-bottom: 22px;
`;

export const TagContainerWrapper = styled(Flex)`
    margin-left: 12px;
`;

export const IndexSuggestionContainer = styled(Flex)`
    flex-direction: column;
    width: 100%;
`;

export const ButtonContainer = styled(Flex)`
    padding: 4px 12px;
    border-radius: 12px;
    border: 2px solid ${({ theme }) => theme.colors.grey[100]};
    cursor: pointer;
    transition: background-color 0.3s ease;
    :hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.grey[100]};
    }`;

export const Arrow = styled(ArrowI)`
    height: 20px;
    width: 20px;
    border-radius: 50%;
    rotate: -90deg;
    fill: ${({ theme }) => theme.colors.info.main};
`;