import { SQLEngineType, ParseContext, ParseResult } from './dbParserTypes';
import { getDimensions } from './common';
import { MySQLParser } from './mySQLParser';

const parsers: Record<SQLEngineType, (data: any, context: ParseContext) => ParseResult> = {
  MySQL: MySQLParser,
  PostgreSQL: () => { throw new Error('PostgreSQL parser not implemented yet'); },
  MSSQL: () => { throw new Error('MSSQL parser not implemented yet'); },
  Oracle: () => { throw new Error('Oracle parser not implemented yet'); },
};

export const parseExplainData = (data: any, engineType: SQLEngineType): ParseResult => {
  const context: ParseContext = {
    currentLevel: 0, 
    currentXPosition: 0, 
    currentYPosition: 0, 
    latestRowsAfterFilter: 0,
    nodeIdCounter: 1, 
    nodes: [], 
    edges: [], 
    horizontalSpacing: 200, 
    verticalSpacing: 150, 
  };
  
  const parser = parsers[engineType];
  if (!parser) throw new Error(`Unsupported SQL engine: ${engineType}`);

  return parser(data, context);
};
