import Slider from '@mui/material/Slider';
import styled from 'styled-components';
import Flex from '../Flex';
import Typography from '../Typography';

interface Props {
  value?: number | string;
  min?: number;
  max?: number;
  step?: number;
  label?: string;
  width?: string;
  isMinimize?: boolean;
}

export default function HealthSlider({ step = 1, max = 100, value, label, min = 0, width, isMinimize = false }: Props) {
  let valueFormatted: number | string = 0;
  const isString = typeof value === 'string';
 if (isString) {
   valueFormatted = value;
 }
 else {
   valueFormatted = value ? value?.toFixed(0) : 0;
 }
  return (
    <Container direction={isMinimize ? 'row' : 'column'} align={isMinimize ? 'center' : 'start'} width={width}>
      {!isMinimize && (
        <LabelContainer>
          <Label>{label}</Label>
          {value !== undefined && <Label>{isString ? valueFormatted : `${valueFormatted}%`}</Label>}
        </LabelContainer>
      )}
      <PercentageSlider
        disabled
        min={min}
        max={max}
        sx={{ p: '5px 0' }}
        value={+valueFormatted || 0}
        step={step}
        aria-label="Default"
        valueLabelDisplay="auto"
      />
      {isMinimize && (
        <MinContainer>
          <Label>{label}</Label>
          <Label>{valueFormatted}%</Label>
        </MinContainer>
      )}
    </Container>
  );
}
const Container = styled(Flex)<{ width: string }>`
  width: ${({ width }) => (width ? width : '100%')};
`;
const LabelContainer = styled(Flex)`
  width: 100%;
  justify-content: space-between;
`;
const MinContainer = styled(Flex)`
  margin-left: 16px;
  justify-content: space-between;
`;
const Label = styled(Typography)`
  color: ${({ theme }) => theme.colors.new.primary.main};
  font-weight: 600;
`;

const PercentageSlider = styled(Slider)(({ value }) => ({
  color: 'red',
  height: '7px',
  '& .Mui-disabled,.MuiSlider-track': {
    border: 'none',
    height: '7px',
    backgroundColor: Number(value) > 30 ? (Number(value) > 80 ? '#00CB6C' : '#FFCC00') : '#F20530'
  },
  '& .Mui-disabled,.MuiSlider-rail': {
    border: 'none',
    height: '7px',
    backgroundColor: '#E4E5F8'
  },
  '& .Mui-disabled,.MuiSlider-thumb': {
    display: 'none'
  },
  '& .Mui-disabled,.MuiSlider-valueLabel': {
    display: 'none'
  }
}));
