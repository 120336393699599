import ky from 'ky';
import * as Sentry from '@sentry/react';
import { useAuth } from '@frontegg/react';
import { useSetRecoilState } from 'recoil';
import { snackbarState } from '../atoms/snackbar.atom';
import { SnackbarSeverityE } from '../enums/snackbar-severity.enum';
import { useNavigate } from 'react-router-dom';

const errorInitState = {
  counter: 0,
  URL: ''
};
export const createRestClient = () => {
  const retryErrorState = errorInitState;
  const { user } = useAuth();
  const setSnackbarS = useSetRecoilState(snackbarState);
  const navigate = useNavigate();

  return ky.create({
    prefixUrl: `${window.location.origin}/api/`,
    retry: {
      limit: 1,
      statusCodes: [500, 408, 404, 403],
      methods: ['get']
    },
    timeout: 30_000,
    hooks: {
      beforeRequest: [
        async (request) => {
          const token = user?.accessToken;
          const apiKey = localStorage.getItem('apiKey');
          request.headers.set('Authorization', `Bearer ${token}`);
          request.headers.set('userId', user?.id || '');
          apiKey && request.headers.set('X-Api-Key', apiKey);
        }
      ],
      afterResponse: [
        async (_input, _options, response) => {
          if (isURLWhitelisted(_input.url)) return;
          if (response?.status === 403 && _options?.method === 'GET') {
            setSnackbarS({ open: false, severity: SnackbarSeverityE.warning, text: '403' });
            navigate('/error');
          }
        }
      ],
      beforeError: [
        async (error) => {
          const { response } = error;
          //detect API Error and send only once to sentry also print the error to logs with counter (the max counter is 4)
          if (response?.url && retryErrorState.URL !== response?.url) {
            retryErrorState.counter = 1;
            retryErrorState.URL = response?.url;
            Sentry.captureException(new Error(`Error-${response?.status}-${response?.url}`), {
              extra: {
                ErrorMessage: `StatusCode: ${response?.status}, URL:${response?.url}`,
                ...getErrorHeaderParams(response?.body)
              }
            });
          } else {
            retryErrorState.counter++;
          }
          console.warn({ statusCode: response?.status, URL: response?.url, retryCount: retryErrorState.counter });
          if (error.name === 'HTTPError') {
            return await error;
          }
          return error;
        }
      ]
    }
  });
};

export const getErrorHeaderParams = (body: any) => ({
  'X-Amz-Cf-Id': body?.headers?.['X-Amz-Cf-Id'],
  'X-Ray-Id': body?.headers?.['X-Ray-Id']
});

// Here we can add to whitelist any APIs that are allowed to with Error
const whitelistURL = ['logs'];

const isURLWhitelisted = (url: string): boolean => !!whitelistURL.find((whiteList: string) => url.includes(whiteList));
