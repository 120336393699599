import styled, { css } from 'styled-components';
import Flex from '../../core/Flex';
import { Grid } from '@mui/material';
import Typography from '../../core/Typography';
import { ReactComponent as JiraI } from '@icons/brand/jira.svg';
import { ReactComponent as SlackI } from '@icons/brand/slack-icon.svg';
import { ReactComponent as InfoI } from '@icons/info-blue.svg';
import { ReactComponent as AlertI } from '@icons/alert-icon.svg';
import {ReactComponent as RefreshI} from '@icons/refresh.svg';

export const MainTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.new.primary.main};
`;
export const MetricContainer = styled(Flex)`
  padding: 12px 24px;
  margin: 0 12px;
  min-width: 130px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.new.border};
  background: ${({ theme }) => theme.colors.grey[50]};
`;

export const InfoIcon = styled(InfoI)`
  margin-right: 18px;
  width: 35px;
  height: 35px;
`;

export const DescriptionIcon = styled(InfoI)`
  margin-right: 18px;
  max-width: 28px;
  min-width: 28px;
  max-height: 30px;
  min-height: 30px;
`;

export const AlertIcon = styled(AlertI)`
  margin-right: 18px;
  min-width: 28px;
  min-height: 28px;
  max-height: 28px;
  max-width: 28px;
`;

export const InsightWrapper = styled(Flex)<{ severity: number }>`
  border-radius: 6px;
  padding: 12px 24px;
  justify-content: start;
  width: calc(100% - 50px);
  border: 1px solid ${({ theme }) => theme.colors.new.primary[125]};
  background: ${({ theme }) => theme.colors.new.primary[150]};
  ${({ severity }) =>
    severity === 4 &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.new.info[100]};
      background: ${({ theme }) => theme.colors.new.info[150]};
    `}
  ${({ severity }) =>
    severity === 3 &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.error.medium};
      background: ${({ theme }) => theme.colors.new.medium[150]};
    `}
    ${({ severity }) =>
    severity === 2 &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.error.high};
      background: ${({ theme }) => theme.colors.new.high[150]};
    `}
    ${({ severity }) =>
    severity === 1 &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.error.critical};
      background: ${({ theme }) => theme.colors.new.warning[200]};
    `}
`;

export const DescriptionWrapper = styled(Flex)`
  border-radius: 6px;
  justify-content: start;
  width: 95%;
  padding: 12px 24px;
  border: 1px solid ${({ theme }) => theme.colors.new.primary[125]};
  background: ${({ theme }) => theme.colors.new.primary[150]};
  margin-bottom: 20px;
  flex: 1 5;
`;
export const AccordionContainer = styled(Flex)`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  margin: 4px 0;
  border: 1px solid ${({ theme }) => theme.colors.grey[150]};
`;

export const GridListContainer = styled(Grid)<{ open?: boolean }>`
  border: none;
  background: inherit;
  position: relative;
  width:100%;
  transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: ${({ theme, open }) => (open ? theme.colors.text.title : theme.colors.new.primary.main)}};
  border-radius: 4px 4px 0px 0px;
  padding: 0 14px;
  height: 46px;
`;

export const JiraIcon = styled(JiraI)`
  width: 18px;
  height: 18px;
  margin: 0 8px;
`;
export const SlackIcon = styled(SlackI)`
  width: 20px;
  height: 20px;
  margin: 0 8px;
`;

export const Title = styled(Typography)<{ mr?: boolean; whiteSpace?: string }>`
  color: ${({ theme }) => theme.colors.text.main};
  margin-right: ${({ mr }) => (mr ? '12px' : 0)};
  ${({whiteSpace}) => `white-space: ${whiteSpace}`};
`;

export const AlertGridUIContainer = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.grey[100]};
  border-radius: 0 0 5px 5px;
  overflow-y: auto;
  padding: 16px 24px;
  background-color: ${({ theme }) => theme.colors.defaultBG};
`;

export const AlertContextGridContainer = styled(Flex)`
  min-height: 140px;
  height: 300px;
  width: inherit;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.grey[100]};
  overflow-y: auto;
`;

export const Divider = styled(Flex)`
  width: 100%;
  height: 1px;
  padding: 0.5px 0;
  margin: 8px 0 16px;
  background: ${({ theme }) => theme.colors.grey[150]};
`;

export const TooltipContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: 5px;
  box-shadow: 0px 4px 16px 2px rgba(0, 0, 0, 0.38);
`;
export const DateDrawer = styled(Flex)`
  padding: 4px 8px;
  justify-content: start;
  border-radius: 5px 5px 0 0;
  background-color: ${({ theme }) => theme.colors.grey[100]};
`;
export const TimeFrameContainer = styled(Flex)`
  border-right: 2px solid ${({ theme }) => theme.colors.new.border};
  padding-right: 8px;
  margin-right: 8px;
`;

export const TooltipItem = styled(Flex)`
  padding: 2px 8px 4px;
`;

export const IdleIcon = styled.div<{ circleColor?: string }>`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 0 8px;
  background: ${({ theme, circleColor }) => (circleColor ? circleColor : theme.colors.grey[300])};
`;

export const HostText = styled(Typography)<{ isFull?: boolean }>`
  flex-basis: 50%;
  max-width: ${({ isFull }) => (isFull ? '52vw' : '55vw')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 8px;
  color: ${({ theme }) => theme.colors.grey[500]};
`;

export const DBText = styled(HostText)`
  flex-basis: 25%;
  max-width: 200px;
`;

export const Placeholder = styled.div`
  flex-basis: 25%;
  max-width: 200px;
  visibility: hidden; /* Make the placeholder invisible */
`;

export const RefreshIcon = styled(RefreshI)`
  width: 20px;
  height: 19px;
    fill: ${({ theme }) => theme.colors.new.primary.main};
  &:hover {
    filter: brightness(70%);
  }
`;