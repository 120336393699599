import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import { ValueFormatterParams } from '@ag-grid-community/core';
import { ReactComponent as InfoI } from '@icons/info-primary.svg';

import { BodyDrawer, Close, DataGridUI, HeaderDrawer, TitleDrawer } from '../../../Reports/Reports.styled';
import MDrawer from '../../../../core/Drawer/Drawer';
import Flex from '../../../../core/Flex';
import MTooltip from '../../../../core/Tooltip/Tooltip';
import Typography from '../../../../core/Typography';
import { BYTE_SIZE, byteMe } from '../../../../utils/byteMe';
import { getFactsAsKeyVal, numberWithCommas } from '../../../../utils/utils';
import { TrendDataGridCell } from '../../../Reports/common/TrendDataGridCell';
import { getTableDBIdAndParamsAPI } from '../../../SchemaAnalysis/SchemaAnalysisAPI';
import { RestClientContext } from '../../../../auth/RestClientAuthProvider';
import { analyticsTrack } from '../../../../utils/segment-analytics';
import TableLoader from '../../../../components/Loader/TableLoader';
import { ReportTabState, getSSAssertions } from '../../../Reports/utils';
import { AssertionTab } from '../../../../components/AssertionsTab/AssertionTab';
import { ProductionGridUIContainer } from '../../ProductionTab.styled';

const ExtraInfoDetails = () => {
  return (
    <div>
      -1 indicates that the table has never yet been vacuumed or analyzed, thus row (<code>pg_catalog.reltuples</code>) count is
      unknown. Run the <code>ANALYZE</code> command to generate statistics. For ex. <code>ANALYZE</code> <b>my_table</b>
    </div>
  );
};

const CustomizeRowReadHeader = () => {
  return (
    <MTooltip title={<ExtraInfoDetails />}>
      <Flex>
        <Typography weight="600" style={{ paddingRight: '8px' }}>
          Number of rows
        </Typography>
        <InfoI height={14} width={14} />
      </Flex>
    </MTooltip>
  );
};

export const ProductionTables = (tables: any[], isLoading: boolean) => {
  const restClient = useContext(RestClientContext);
  const [isSSALoading, setSSALoading] = useState(false);
  const [insight, setSelectedInsight] = useState<any>(null);
  const { projectApiKey = '' } = useParams();

  const [assertionIndex, setAssertionIndex] = useState(0);
  const [tableAnalysisItem, setTableAnalysisItem] = useState<any>();

  const [columnDefs] = useState([
    {
      field: 'host',
      cellStyle: { justifyContent: 'start', alignItems: 'center', display: 'flex' },
      headerName: 'Host',
      valueGetter: (params: any) => params?.data?.context?.host
    },
    {
      field: 'db',
      cellStyle: { justifyContent: 'start', alignItems: 'center', display: 'flex' },
      headerName: 'Database',
      valueGetter: (params: any) => params?.data?.context?.db
    },
    {
      field: 'schemaName',
      cellStyle: { justifyContent: 'start', alignItems: 'center', display: 'flex' },
      headerName: 'Schema Name',
      valueGetter: (params: any) => params?.data?.context?.schema
    },
    {
      field: 'tableName',
      cellStyle: { justifyContent: 'start', alignItems: 'center', display: 'flex' },
      headerName: 'Table Name',
      valueGetter: (params: any) => params?.data?.context?.table_name
    },
    {
      headerName: 'Number of rows',
      cellStyle: { justifyContent: 'start', alignItems: 'center', display: 'flex' },
      headerComponent: CustomizeRowReadHeader,
      valueGetter: (params: any) => params?.data?.num_of_rows?.[params?.data?.num_of_rows?.length - 1]?.value,
      valueFormatter: (params: any) => numberWithCommas(params?.data?.num_of_rows?.[params?.data?.num_of_rows?.length - 1]?.value)
    },
    {
      headerName: 'Table Size',
      sortable: false,
      valueGetter: (params: any) => params.data?.table_size,
      cellRenderer: ({ value }: ValueFormatterParams) => (
        <TrendDataGridCell data={value} payloadName="Size" valueTooltipFormatting={(v) => byteMe(v, BYTE_SIZE.KB)} />
      )
    },
    {
      headerName: 'Dead Rows',
      sort: 'desc',
      cellStyle: { justifyContent: 'start', alignItems: 'center', display: 'flex' },
      valueGetter: (params: any) => params?.data?.dead_rows?.[params?.data?.dead_rows?.length - 1]?.value,
      valueFormatter: (params: any) =>
        numberWithCommas(params?.data?.dead_rows?.[params?.data?.dead_rows?.length - 1]?.value)
    }
  ]);
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1
    }),
    []
  );

  const getTableAnalysisItem = async () => {
    setSSALoading(true);
    if (insight?.table_name && insight?.schema_name && insight?.pmc_id && projectApiKey) {
      const resTableList = await getTableDBIdAndParamsAPI(
        restClient,
        insight.table_name,
        insight.schema_name,
        insight.pmc_id,
        projectApiKey
      );
      setTableAnalysisItem(resTableList);
      analyticsTrack('getTableAnalysisList Success', resTableList);
    }
    setSSALoading(false);
  };

  useEffect(() => {
    if (insight) {
      getTableAnalysisItem();
    }
  }, [insight]);

  return (
    <>
      <ProductionGridUIContainer className="ag-theme-material">
        {isLoading ? (
          <TableLoader size={6} />
        ) : (
          <DataGridUI
            rowData={tables}
            animateRows={true}
            columnDefs={columnDefs}
            suppressCellFocus={true}
            multiSortKey={'ctrl'}
            rowHeight={75}
            defaultColDef={defaultColDef}
            onRowClicked={(row: any) => {
              row?.data?.insights &&
                setSelectedInsight({
                  ...row.data.insights,
                  pmc_id: row.data?.context?.pmc_id,
                  db_host: row.data?.context?.host,
                  db_name: row.data?.context?.db
                });
            }}
          />
        )}
      </ProductionGridUIContainer>
      <MDrawer anchor="right" onClose={() => setSelectedInsight(null)} open={!!insight}>
        <HeaderDrawer justify="space-between">
          <TitleDrawer h3 weight="600">
            Table Statistics Details
          </TitleDrawer>
          <Flex margin="8px 16px">
            <Close title="close-icon" onClick={() => setSelectedInsight(null)} />
          </Flex>
        </HeaderDrawer>
        <BodyDrawer>
          <Tabs sx={{ minHeight: '35px', ml: 1 }} value={0}>
            {ReportTabState.map((tabItem: string) => (
              <Tab key={tabItem} sx={{ textTransform: 'none', fontWeight: 600 }} id={`report-tab-${tabItem}`} label={tabItem} />
            ))}
          </Tabs>
          <AssertionTab
            assertions={getSSAssertions(true, tableAnalysisItem?.table_insights)}
            headHeight="100px"
            facts={getFactsAsKeyVal(tableAnalysisItem?.table_insights?.assertions?.metrics)}
            assertionCurrentIndex={assertionIndex}
            setAssertionIndexCallback={setAssertionIndex}
            tableInfos={[]}
            changeTabActivity={0}
            isLoading={isSSALoading}
            selectedQuery={0}
            tableItem={tableAnalysisItem}
            isSidebar={true}
          />
        </BodyDrawer>
      </MDrawer>
    </>
  );
};
