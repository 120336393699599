import { keyframes } from 'styled-components';

export const breatheAnimation = keyframes`
 0% { background: #E4E5F8; }
 50% { background: #F2F3FC; }
 100% { background: #E4E5F8; }
`;

export const pulseAnimation = keyframes`
0% { box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2); }
100% { box-shadow: 0 0 0 10px rgba(0, 0, 0, 0); }
`;
export const selectBorderAnimation = keyframes`
0% { box-shadow: 0 0 0 0px #E4E6EB; }
50% { box-shadow: 0 0 0 3px #E4E6EB; }
100% { box-shadow: 0 0 0 0px #E4E6EB; }
`;

export const shake = keyframes`
10%, 70%, 90 {
  transform: translate3d(-0.5px, 0, 0);
}

30%, 60% {
  transform: translate3d(0.5px, 0, 0);
}

40%, 50%, 80% {
  transform: translate3d(-1px, 0, 0);
}

50% {
  transform: translate3d(1px, 0, 0);
}`;
