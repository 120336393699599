import styled, { css } from 'styled-components';
import Typography from '../../../../core/Typography';
import Flex from '../../../../core/Flex';

export const MetisSimpleDetailsBoxContainer = styled(Flex)<{ flex: number; isActive: boolean; margin: string }>`
  margin: ${({ margin }) => (margin ? margin : '8px')};
  position: relative;
  cursor: ${({ isActive }) => (isActive ? 'pointer' : 'auto')};
  flex-direction: column;
  background: ${({ theme }) => theme.colors.grey[20]};
  border: 1px solid ${({ theme }) => theme.colors.grey[150]};
  border-radius: 8px;
  width: inherit;
  flex: ${({ flex }) => (flex ? flex : 1)};
  .observability-see-more {
    display: none;
  }
  ${({ isActive }) =>
    isActive &&
    css`
      &:hover {
        background: ${({ theme }) => theme.colors.grey[150]};
        box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);
        border: 1px solid ${({ theme }) => theme.colors.info.dark};
        .observability-see-more {
          display: block;
        }
      }
    `}
`;

export const MetisSimpleDetailsBoxHeaderTitleText = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.main};
  padding: 8px 16px;
`;
export const BoxBody = styled.div`
  width: 100%;
  border-radius: 0px 0px 8px 8px;
  background: ${({ theme }) => theme.colors.white};
`;

export const HoveAction = styled(Typography)`
  color: ${({ theme }) => theme.colors.grey[400]};
  font-weight: 600;
  padding-right: 16px;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.text.main};
  }
`;

export const MetisSimpleDetailsBoxHeader = styled(Flex)`
  width: 100%;
  border-radius: 8px 8px 0px 0px;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[150]};
  background: ${({ theme }) => theme.colors.white};
`;
