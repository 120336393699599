import { atom } from 'recoil';

export interface ErdViewI {
  viewPort: {x: number, y: number, zoom: number}
  nodes: any[];
  currentTable: any;
  schemaChangeLog: any;
}

export const erdViewAtom = atom<ErdViewI>({
  key: 'erdViewAtom',
  default: {
    viewPort:{x: 0, y: 50, zoom: 0.1},
    nodes: [],
    currentTable: undefined,
    schemaChangeLog: undefined
  }
});
