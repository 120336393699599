import styled from 'styled-components';
import { Grid, Skeleton } from '@mui/material';

import Flex from '../../../../core/Flex';
import Typography from '../../../../core/Typography';
import MTooltip from '../../../../core/Tooltip/Tooltip';
import { TrendDataGridCell } from '../../../Reports/common/TrendDataGridCell';
import LottieAnimation from '../../../../core/Lottie';
import OrangeAlert from '../../../../../assets/lotties/orange-alert.json';
import { BYTE_SIZE, byteMe } from '../../../../utils/byteMe';

interface ODTablesI {
  fastestGrowingTablesDailyGrowth: any[];
  topLargestTables: any[];
  isLoading: boolean;
}

const ODTables = ({ fastestGrowingTablesDailyGrowth = [], topLargestTables = [], isLoading }: ODTablesI) => {
  return (
    <Container align="start">
      <Grid container>
        <Grid item xs={3.5}>
          <GridIndexItem>
            <Title weight="600">Top 3 Largest Tables</Title>
            <TableLarge direction="column" align="start">
              {topLargestTables &&
                topLargestTables?.map((item: any, i: number) => (
                  <TableLarge key={i + item?.table} justify="space-between">
                    <MTooltip disable={item.table.length < 20} title={item.table}>
                      <MTitle weight="500">{item.table}</MTitle>
                    </MTooltip>
                    <Title pd>{getTableSizeFormatter(Number(item?.value))}</Title>
                  </TableLarge>
                ))}
            </TableLarge>
          </GridIndexItem>
        </Grid>
        <Grid item xs={8.5}>
          <GridItem>
            <Flex>
              <Title weight="600">Fastest Growing Tables</Title>
              <Title pd>last 7 days</Title>
            </Flex>
            <Wrapper>
              {isLoading ? (
                <Wrapper direction="column">
                  <Wrapper>
                    <Skeleton variant="text" width={150} height={20} sx={{ m: '4px 24px' }} />
                    <Skeleton variant="text" width={150} height={20} sx={{ m: '4px 24px' }} />
                    <Skeleton variant="text" width={150} height={20} sx={{ m: '4px 24px' }} />
                  </Wrapper>
                  <Wrapper>
                    <Skeleton variant="text" width={150} height={70} sx={{ m: '4px 24px' }} />
                    <Skeleton variant="text" width={150} height={70} sx={{ m: '4px 24px' }} />
                    <Skeleton variant="text" width={150} height={70} sx={{ m: '4px 24px' }} />
                  </Wrapper>
                </Wrapper>
              ) : fastestGrowingTablesDailyGrowth.length ? (
                fastestGrowingTablesDailyGrowth.map((item, idx) => (
                  <Flex key={idx} direction="column" align="start" margin="0 12px">
                    <MTooltip disable={item?.table?.length < 20} title={item?.table}>
                      <MTitle weight="600">{item?.table}</MTitle>
                    </MTooltip>
                    <CellWrapper>
                      <TrendDataGridCell
                        width={170}
                        payloadName=""
                        data={item?.data?.map((i: { date: string; value: string }) => ({
                          ...i,
                          value: +i?.value || 0,
                          time: i?.date
                        }))}
                        title="Max in 7 days: "
                        sizeIsKB={true}
                      />
                    </CellWrapper>
                  </Flex>
                ))
              ) : (
                <EmptyText />
              )}
            </Wrapper>
          </GridItem>
        </Grid>
      </Grid>
    </Container>
  );
};

const getTableSizeFormatter = (formate: number) => {
  const params = byteMe(formate, BYTE_SIZE.KB) || '0 KB';
  const data = formate ? params.split(' ') : ['0', 'KB'];
  return (
    <TableSizeContainer justify="start">
      <div>{data[0]}</div>
      <Flex margin="0 0 0 6px">{data[1]}</Flex>
    </TableSizeContainer>
  );
};
export const EmptyText = () => (
  <Flex justify="start" direction={'row'} margin="24px 0">
    <LottieAnimation width={29} height={29} lottieJsonFile={OrangeAlert} loop={false} />
    <EmptyTitle>Insufficient data available for display. Sufficient data will be collected within the next 24 hours.</EmptyTitle>
  </Flex>
);

const TableSizeContainer = styled(Flex)`
  width: 62px;
`;
export const MTitle = styled(Typography)`
  padding: 8px 0;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.text.main};
  overflow: hidden;
  text-overflow: ellipsis;
  width: 155px;
`;
const TableLarge = styled(Flex)`
  width: 100%;
`;
export const GridItem = styled(Flex)`
  padding: 24px 16px;
  align-items: start;
  flex-direction: column;
`;
export const CellWrapper = styled(Flex)`
  margin-top: 4px;
`;
export const GridIndexItem = styled(GridItem)`
  min-height: 152px;
  padding: 0 10px;
  margin: 24px 16px;
  border-right: 2px solid ${({ theme }) => theme.colors.grey[150]};
`;
export const Wrapper = styled(Flex)`
  width: 100%;
  justify-content: center;
`;
const Container = styled(Flex)`
  min-height: 200px;
  overflow: auto;
  width: 100%;
`;

const Title = styled(Typography)<{ pd: boolean }>`
  color: ${({ theme }) => theme.colors.text.main};
  padding: 8px 0;
  padding-left: ${({ pd }) => (pd ? '8px' : 0)};
`;

const EmptyTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.main};
  padding: 6px 0 0 6px;
`;

export default ODTables;
