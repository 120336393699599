import styled, { css } from 'styled-components';

import Flex from '../Flex';
import Typography from '../Typography';

interface ILabel {
  type: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'empty';
  title: string;
  icon?: React.ReactNode;
}

const TagLabel = ({ type, title, icon }: ILabel) => {
  return (
      <LabelWrapper type={type}>
        {icon}
        <LabelText s weight="600">
          {title}
        </LabelText>
      </LabelWrapper>
  );
};

export default TagLabel;

const LabelWrapper = styled(Flex)<{ type: ILabel['type'] }>`
  text-align: center;
  border-radius: 6px;
  padding: 2px 12px;
  height: 24px;
  border: 1px solid ${({ theme }) => theme.colors.new.primary[125]};
  background: ${({ theme }) => theme.colors.new.primary[150]};
  ${({ type }) =>
    type === 'success' &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.new.success[50]};
      background: ${({ theme }) => theme.colors.new.success[150]};
      div {
        color: ${({ theme }) => theme.colors.new.success[50]};
      }
      svg {
        stroke: ${({ theme }) => theme.colors.new.success[50]};
      }
    `}
  ${({ type }) =>
    type === 'info' &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.new.info[100]};
      background: ${({ theme }) => theme.colors.new.info[150]};
      div {
        color: ${({ theme }) => theme.colors.new.info[50]};
      }
    `}
    ${({ type }) =>
    type === 'secondary' &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.new.secondary[100]};
      background: ${({ theme }) => theme.colors.new.secondary[150]};
      div {
        color: ${({ theme }) => theme.colors.new.secondary[50]};
      }
    `}
    ${({ type }) =>
    type === 'warning' &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.new.warning[100]};
      background: ${({ theme }) => theme.colors.new.warning[150]};
      div {
        color: ${({ theme }) => theme.colors.error.critical};
      }
      svg {
        stroke: ${({ theme }) => theme.colors.error.critical};
      }
    `}
  ${({ type }) =>
    type === 'empty' &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.grey[600]};
      background: ${({ theme }) => theme.colors.grey[600]};
      div {
        color: ${({ theme }) => theme.colors.grey[500]};
      }
      svg {
        stroke: ${({ theme }) => theme.colors.grey[500]};
      }
    `}
`;

const LabelText = styled(Typography)`
  text-align: center;
  padding-left: 4px;
  padding-top: 1px;
  min-width: 44px;
  color: ${({ theme }) => theme.colors.new.primary[50]};
`;
