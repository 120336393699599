import { FormControl, InputAdornment, TextField } from '@mui/material';
import { useState } from 'react';
import styled, { useTheme, css } from 'styled-components';
import { ReactComponent as SearchIcon } from '@icons/search.svg';

interface ISearch {
  onSubmit: (searchParam: string) => void;
  onChangeCallback?: (searchParam: string) => void;
  fullWidth: boolean;
  width?: string;
  placeholder?: string;
  minWidth?: string;
  value?: string;
  marginBottom?: string;
}
const Input = styled(TextField)`
  .MuiOutlinedInput-input {
    padding: 4px;
  }
`;
export const FormControlWrapper = styled.div<any>`
  min-width: ${({ minWidth }) => (minWidth ? minWidth : '12rem')};
  ${({ width ,marginBottom}) =>
    width &&
    css`
      width: ${width};
      margin-bottom: ${marginBottom};
    `};
`;

function Search({ fullWidth, onSubmit, width, minWidth, placeholder, onChangeCallback, value = '' , marginBottom = '0'}: ISearch) {
  const [searchValue, setSearchValue] = useState(value);

  const theme: any = useTheme();
  return (
    <FormControlWrapper width={width} minWidth={minWidth} marginBottom={marginBottom}>
      <FormControl size="small" variant="outlined" fullWidth={fullWidth}>
        <Input
          color="primary"
          size="small"
          sx={{ color: theme.colors.text.main }}
          id="outlined-adornment-weight"
          value={value}
          placeholder={placeholder || 'Search'}
          onChange={(e) => {
            setSearchValue(e.target.value);
            onChangeCallback && onChangeCallback(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  onSubmit(searchValue);
                }}
                position="start"
              >
                <SearchIcon title="search" />
              </InputAdornment>
            )
          }}
        />
      </FormControl>
    </FormControlWrapper>
  );
}

export default Search;
