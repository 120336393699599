import styled from 'styled-components';
import { ValueFormatterParams } from '@ag-grid-community/core';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as ConnectGitImg } from '@icons/connect-git.svg';
import {useRecoilValue, useResetRecoilState} from 'recoil';

import Flex from '../../core/Flex';
import EmptyProjectItem from '../Projects/components/EmptyProjectItem';
import { getPR_API } from '../Projects/ProjectItem/ProjectAPI';
import { RestClientContext } from '../../auth/RestClientAuthProvider';
import { analyticsTrack } from '../../utils/segment-analytics';
import { logState } from '../../atoms/log-analyzer.atom';
import { filterState } from '../../atoms/filters.atom';
import { ProjectCardType } from '../../types/ProjectCard.type';
import {apiKeysState, ApiKeyType, convertApiIdToApiKey, useActiveAPIKey} from '../../atoms/api-keys.atom';
import TableLoader from '../../components/Loader/TableLoader';
import { DataGridUI } from '../Reports/Reports.styled';
import SeveritiesSummary from '../../components/SeveritiesSummary/SeveritiesSummary';
import {getFormattedDate} from '../../utils/utils';

const TestCICard = () => {
  const { projectApiKey = '' } = useParams();
  const resetList = useResetRecoilState(logState);
  const resetFilterState = useResetRecoilState(filterState);
  const navigate = useNavigate();
  const selectedStateApiKey: ApiKeyType = useActiveAPIKey(projectApiKey);
  const {apiKeys} = useRecoilValue(apiKeysState);

  const [PRListLoader, setPRListLoader] = useState<boolean>(true);
  const [PRList, setPRList] = useState<any[]>([]);
  const insightsFormatter = (params: ValueFormatterParams) => {
    return params?.data?.type === ProjectCardType.SCHEMA_MIGRATION ? (
      <SeveritiesSummary
        severity_critical={params?.data?.migration?.insights?.severity_critical}
        severity_high={params?.data?.migration?.insights?.severity_high}
        severity_medium={params?.data?.migration?.insights?.severity_medium}
        severity_low={params?.data?.migration?.insights?.severity_low}
        showAll={true}
      />
    ) : (
      <SeveritiesSummary
        severity_critical={params?.data?.assertions?.severity_critical}
        severity_high={params?.data?.assertions?.severity_high}
        severity_medium={params?.data?.assertions?.severity_medium}
        severity_low={params?.data?.assertions?.severity_low}
        showAll={true}
      />
    );
  };
  const restClient = useContext(RestClientContext);
  const getPR = async () => {
    setPRListLoader(true);
    const { prs } = await getPR_API(restClient, convertApiIdToApiKey(projectApiKey, apiKeys));

    setPRList(prs);
    setPRListLoader(false);
  };
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1
    }),
    []
  );
  const [columnDefs] = useState([
    {
      field: 'test_name',
      cellStyle: { justifyContent: 'start', alignItems: 'center', display: 'flex' },
      headerName: 'Name'
    },
    {
      field: 'type',
      cellStyle: { justifyContent: 'start', alignItems: 'center', display: 'flex' },
      headerName: 'Type'
    },
    {
      flex: 0.6,
      field: 'created_at',
      sort: 'desc',
      cellStyle: { justifyContent: 'start', alignItems: 'center', display: 'flex' },
      headerName: 'Created',
      valueFormatter: (params: any) => getFormattedDate(params?.data?.created_at)
    },
    {
      headerName: 'Insights',
      sortable: false,
      flex: 0.8,
      cellStyle: { justifyContent: 'start', alignItems: 'center', display: 'flex', marginLeft: '-20px ' },
      cellRenderer: insightsFormatter
    }
  ]);

  const handelSelectTest = ({ data }: any) => {
    const test = data;
    analyticsTrack('SelectPullRequest', { test });
    resetList();
    resetFilterState();
    const tagName = test?.pr_name;
    if (test?.migration?.id && test.type === ProjectCardType.SCHEMA_MIGRATION) {
      navigate(tagName + '/migration/' + test.migration.id, {
        state: { name: selectedStateApiKey.label, test: { ...test, title: tagName, tags: [test.pr_name] } }
      });
    } else {
      navigate(tagName, {
        state: { name: selectedStateApiKey.label, test: { ...test, tags: [test.pr_name] } }
      });
    }
  };

  useEffect(() => {
    if (PRList.length === 0) {
      getPR();
    }
  }, []);
  return (
    <>
      <Flex direction="column" justify={PRList.length || PRListLoader ? 'start' : 'center'}>
        {PRListLoader && <TableLoader size={10} />}
        {PRList?.length ? (
          <DataGridUIContainer className="ag-theme-material">
            <DataGridUI
              rowData={PRList}
              animateRows={true}
              columnDefs={columnDefs}
              suppressCellFocus={true}
              multiSortKey={'ctrl'}
              rowHeight={55}
              defaultColDef={defaultColDef}
              onRowClicked={handelSelectTest}
            />
          </DataGridUIContainer>
        ) : (
          <EmptyProjectItem
            isLoading={PRListLoader}
            type="TEST"
            EmptyIcon={ConnectGitImg}
            title="Create tests for pull requests and branches on your project’s repository."
          />
        )}
      </Flex>
    </>
  );
};

export default TestCICard;

export const DataGridUIContainer = styled.div`
  height: calc(100vh - 240px);
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.grey[100]};
  border-radius: 5px;
  overflow-y: auto;
`;
