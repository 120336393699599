import { Accordion, AccordionSummary, AccordionDetails, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const AccordionItem = styled(AccordionSummary)({
  flexDirection: 'row-reverse',
  borderBottom: '1px solid #EAECF0',
  borderLeft: '1px solid #EAECF0',
  borderRight: '1px solid #EAECF0',
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(-90deg)',
    marginRight: '10px'
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    padding: 0,
    overflow: 'hidden'
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)'
  },
  '&:hover': {
    background: '#F8F9FB'
  }
});

export const AccordionWrapper = styled(Accordion)`
  box-shadow: none;
  width: inherit;
  color: inherit;
  background: inherit;
  position: relative;
  ::before {
    height: 0px;
  }
`;

export const Details = styled(AccordionDetails)({
  display: 'flex',
  flexWrap: 'wrap',
  padding: '0'
});
export const Header = styled(Grid)({
  height: 55,
  alignItems: 'center'
});
