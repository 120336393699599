import styled, { css } from 'styled-components';


export const TableContainer = styled.div`
  height: 75vh;
`;

export const FilterContainer = styled.div`
  min-height: 20px;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
`;


export const FilterItem = styled.div`
margin-left: 5px;
`

export const TabContainer = styled.div`
margin-bottom: 10px;`


export const InfoContainer = styled.div`
margin: 5px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    color: rgb(56, 57, 117);

`