import * as Sentry from '@sentry/react';
import { KyInstance } from 'ky/distribution/types/ky';
import { ProjectCardType } from '../../../types/ProjectCard.type';
import { SeveritiesI } from '../../../types/Severity.type';

export const getPR_API = async (
  restClient: KyInstance,
  projectApiKey = ''
): Promise<{ prs: any[]; totalSeverities: SeveritiesI }> => {
  try {
    const resPRList: any[] = await restClient.get(`workflows/${projectApiKey}`).json();
    let totalSeverities: SeveritiesI = { severity_critical: 0, severity_high: 0, severity_medium: 0, severity_low: 0 };
    if (resPRList.length) {
      const doubleWorkflowMigrationList: any[] = [];
      const updatedPRList = resPRList.map((pr) => {
        if (pr?.test?.id && pr?.migration?.id) {
          doubleWorkflowMigrationList.push({
            ...pr,
            test_name: pr?.pr_name,
            type: ProjectCardType.SCHEMA_MIGRATION,
            created_at: pr?.migration?.created_at,
            assertions: {
              severity_critical: pr?.migration?.insights?.severity_critical || 0,
              severity_high: pr?.migration?.insights?.severity_high || 0,
              severity_medium: pr?.migration?.insights?.severity_medium || 0,
              severity_low: pr?.migration?.insights?.severity_low || 0
            }
          });
        }

        return {
          ...pr,
          test_name: pr?.pr_name,
          type: pr?.test?.id ? ProjectCardType.PERFORMANCE : ProjectCardType.SCHEMA_MIGRATION,
          created_at: pr?.test?.id ? pr?.test?.created_at : pr?.migration?.created_at,
          assertions: pr?.test?.id
            ? {
                severity_critical: pr?.test?.insights?.severity_critical || 0,
                severity_high: pr?.test?.insights?.severity_high || 0,
                severity_medium: pr?.test?.insights?.severity_medium || 0,
                severity_low: pr?.test?.insights?.severity_low || 0
              }
            : {
                severity_critical: pr?.migration?.insights?.severity_critical || 0,
                severity_high: pr?.migration?.insights?.severity_high || 0,
                severity_medium: pr?.migration?.insights?.severity_medium || 0,
                severity_low: pr?.migration?.insights?.severity_low || 0
              }
        };
      });
      [...updatedPRList, ...doubleWorkflowMigrationList].forEach(
        (s) =>
          (totalSeverities = {
            severity_critical: totalSeverities.severity_critical + s?.assertions?.severity_critical || 0,
            severity_high: totalSeverities.severity_high + s?.assertions?.severity_high || 0,
            severity_medium: totalSeverities.severity_medium + s?.assertions?.severity_medium || 0,
            severity_low: totalSeverities.severity_low + s?.assertions?.severity_low || 0
          })
      );

      return {
        prs: [...updatedPRList, ...doubleWorkflowMigrationList]
          .sort((a, b) => new Date(a?.created_at).getTime() - new Date(b?.created_at).getTime())
          .reverse(),
        totalSeverities
      };
    }
    return { prs: [], totalSeverities };
  } catch (error: any) {
    const body = error?.response;
    Sentry.captureException({ error: body }, { extra: { ErrorMessage: "couldn't fetch getPR List" } });
    return { prs: [], totalSeverities: { severity_critical: 0, severity_high: 0, severity_medium: 0, severity_low: 0 } };
  }
};
export const getENV_API = async (restClient: KyInstance, projectApiKey = ''): Promise<any[]> => {
  try {
    const recentActivity: any = await restClient
      .get(`traces/all-slim/aggregate`, {
        searchParams: {
          'api-key': projectApiKey
        }
      })
      .json();
    if (recentActivity?.severity && (recentActivity?.totalQueries > 0 || recentActivity?.totalRequests > 0)) {
      const updatedEnv = {
        test_name: 'Recent Activity',
        created_at: recentActivity?.start_time || new Date(),
        assertions: {
          1: recentActivity?.severity?.Critical || 0,
          2: recentActivity?.severity?.High || 0,
          3: recentActivity?.severity?.Medium || 0,
          4: recentActivity?.severity?.Low || 0
        }
      };
      return [updatedEnv];
    }
    return [];
  } catch (error: any) {
    const body = error?.response;
    Sentry.captureException({ error: body }, { extra: { ErrorMessage: "couldn't fetch getENV" } });
    return [];
  }
};

export enum ProductionIssuesAPIType {
  PRODUCTION_ISSUES = 'production-issues',
  PRODUCTION_CONFIG = 'production-config',
  PRODUCTION_EXTENSION = 'production-extension',
  PRODUCTION_QUERIES = 'production-queries',
  PRODUCTION_TABLES = 'production-tables',
  PRODUCTION_INDEXES = 'production-indexes'
}
