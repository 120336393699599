import styled, { css } from 'styled-components';
import Flex from '../../core/Flex';
import { SvgImg } from '../../core/SvgImg';
import Typography from '../../core/Typography';

export const Container = styled(Flex)`
  &:first-child {
    margin: 5px 10px 0px 10px;
  }
  padding: 8px;
  margin: 10px 10px 0px 10px;
  border-radius: 6px;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey[150]};

  ${({ choosen }) =>
    choosen &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.text.title};
      div {
        color: ${({ theme }) => theme.colors.text.title};
      }
    `}
  &:hover {
    background: ${({ theme }) => theme.colors.grey[20]};
  }
`;

export const Wrapper = styled(Flex)`
  padding-top: 4px;
  padding-bottom: 4px;
  width: 100%;
`;

export const HeaderContainer = styled(Flex)`
  width: 100%;
`;

export const QueryContainer = styled.div`
  padding-top: 20px;
`;

export const Label = styled(Typography)`
  margin: ${({ margin }) => margin || ' 0px 3px 0px'};
`;

export const ColoredLabel = styled(Typography)`
  margin: ${({ margin }) => margin || ' 0px 3px 0px'};
  padding: 0px 5px 0px 5px;
  border-radius: 2px;
  background: ${({ theme }) => theme.colors.grey[300]};
`;

export const Title = styled(Typography)<{ isSidebar: boolean }>`
  padding-left: 15px;
  max-width: ${({ isSidebar }) => (isSidebar ? '160px' : '200px')};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.new.primary.main};
`;

export const Content = styled(Typography)`
  color: ${({ theme }) => theme.colors.grey[600]};
  padding-top: 4px;
  margin: ${({ margin }) => margin || ' 0px 0px 0px 10px'};
`;

export const SeverityLabel = styled(SvgImg)`
  height: 20px;
  width: 60px;
  padding-right: 25px;
  position: absolute;
`;

export const SeverityItem = styled.div<any>`
  color: ${({ theme }) => theme.colors.white};
  margin-left: ${({ marginLeft }) => marginLeft || '-2px'};
  text-align: center;
  ${({ severityColor }) =>
    severityColor &&
    css`
      background: ${({ theme }: any) => theme.colors.error[severityColor]};
    `};
`;

export const SeverityWrapper = styled(Flex)`
  margin-right: 10px;
`;
