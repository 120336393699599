import { atom, selector } from 'recoil';
import { LogRecord } from '../types/LogRecord.type';

export interface recentActivityListI {
  group_count: string;
  latest_date: string;
  http_target: string;
  // http_status: string;

  critical: string;
  high: string;
  medium: string;
  low: string;
}
export interface logStateI {
  logs: LogRecord[];
  take: number;
  skip: number;
  startTime: string;
  selectedItemPosition: number;
  isGetMoreLogs: boolean;
  tagName?: string;
  sourceName?: string;
}

export type mainLogType = {
  recent: logStateI;
  test: logStateI;
  recentActivityList: {
    list: recentActivityListI[];
    take: number;
    skip: number;
    isGetMore: boolean;
  };
};

export const initState = {
  logs: [],
  take: 50,
  skip: 0,
  startTime: '',
  isGetMoreLogs: true,
  selectedItemPosition: 0
};

export const logState = atom<mainLogType>({
  key: 'logState',
  default: {
    recent: initState,
    test: initState,
    recentActivityList: { list: [], take: 50, skip: 0, isGetMore: true }
  }
});

export const logTestSelector = selector<LogRecord[]>({
  key: 'logTestSelector',
  get: ({ get }: any) => {
    return get(logState).test.logs;
  }
});
export const logRecentSelector = selector<LogRecord[]>({
  key: 'logRecentSelector',
  get: ({ get }: any) => {
    return get(logState).recent.logs;
  }
});
