import Box from '@mui/material/Box';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { SimpleTreeView } from '@mui/x-tree-view';
import { useRecoilState } from 'recoil';
import { erdViewAtom } from '../../atoms/erd-view.atom';
import { useEffect, useState } from 'react';
import { changeLogAtom } from '../../atoms/changeLogAtom.atom';
import SeveritiesSummary from '../../components/SeveritiesSummary/SeveritiesSummary';
import Search from '../../core/Search';
import Select from '../../components/Select/Select';
import Typography from '../../core/Typography';

interface  Table {
    name: string;
    data: any;
    changeLog: string;
    insightsSummary: any;
}

interface Schema {
  name: string;
  tables: Table [];
  color: string;
}

interface DBTreeData {
  schemas: Schema[]
}

interface ListDataProps {
  data: any;
  callback: any;
  schemaAnalysisList: any[];
  schemaChangeLog: any;
}

export const getSeverityCircle = (color: string) => (
  <span style={{
    height: '8px',
    width: '8px',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '5px',
    backgroundColor: color,
  }} />
);



export default function ListTree({data, callback, schemaAnalysisList, schemaChangeLog}: ListDataProps) {
  const [changeLogA, setChangeLog] = useRecoilState(changeLogAtom);
  const [erdAtom, setErdAtom] = useRecoilState(erdViewAtom);
  const [tablesList, setTablesList] = useState(data);
  const [filterSearchParams, setFilterSearchParams] = useState<string>('');



  useEffect(() => {
    const filterTables = data.filter((el: any) => el.data.table_name.includes(filterSearchParams) || filterSearchParams.length === 0 )
    setTablesList(filterTables)
  },[filterSearchParams])

  useEffect(() => {
      if(changeLogA?.changeLog?.length === 0) {
            return setTablesList(data);
       }

      let createdTablesList = data?.map((table: any) => {
          let findAddedTable = changeLogA.changeLog.find((el: any) => el.action === 'table_added' && el.name == table.id);
          let findAlterTable = changeLogA.changeLog.find((el: any) => (el.action === 'column_removed' || el.action === 'column_added') && `${el?.name?.split('.')[0]}.${el?.name?.split('.')[1]}` == table.id);
          if(findAddedTable) {
            return {...table, data: {...table.data,  changeLog: 'table_added'}}
          }
          if(findAlterTable) {
            return {...table, data: {...table.data,  changeLog: 'table_updated'}}
          }
          return table
      })

      let removeTablesArrat = changeLogA.changeLog.reduce((acc: any, cur: any) => {
          if(cur.action === 'table_removed') {
            let schemaName;
            let tableName;
            if(cur.name.split('.').length === 2) {
              schemaName = `${cur.name.split('.')[0]}`;
              tableName = `${cur.name.split('.')[1]}`
            }
            if(cur.name.split('.').length === 3) {
              schemaName = `${cur.name.split('.')[1]}`;
              tableName = `${cur.name.split('.')[2]}`
            }
            acc.push({
              id: cur.name,
              data: {
                schema_name: schemaName,
                table_name: tableName,
                changeLog: 'table_removed'
              }
            })
          }
          return acc;
      }, []) 

      setTablesList([...createdTablesList, ...removeTablesArrat])

  }, [changeLogA.changeLog,data]);

  const onSelectDate = (date: any) => {
    setChangeLog((old: any) => ({     
        ...old,
        changeLog: date === 'Current' ? [] : schemaChangeLog?.[date],
        date: date     
    }))
}

  function convertToDBTreeData(tablesList: any) {
    const dbTreeData: any = { schemas: [] };
  
    tablesList.forEach((item: any) => {
      const { schema_name, table_name, columns, changeLog } = item.data;
      const findInsights = schemaAnalysisList.find((el: any) => `${el.schema_name}.${el.table_name}` === `${schema_name}.${table_name}`)
      const tableData = { name: table_name, data: columns, id: `${schema_name}.${table_name}`,changeLog, insightsSummary:{critical:  findInsights?.severity_critical, high: findInsights?.severity_high, medium: findInsights?.severity_medium, low: findInsights?.severity_low } };
  
      // Check if the schema already exists in dbTreeData
      let schema: any = dbTreeData.schemas.find((s: any) => s.name === schema_name);
      if (!schema) {
        // If the schema doesn't exist, create a new one
        schema = { name: schema_name, tables: [], color: item.data.schemaColor };
        dbTreeData.schemas.push(schema);
      }
  
      // Add the table to the schema's tables array
      schema.tables.push(tableData);
    });
  
    return dbTreeData;
  }

  const onClickNode = (table: any) => {
    const requiredTable = erdAtom.nodes.find((node: any) => node.id === table.id )

    if(requiredTable) {
      callback(requiredTable);
      setErdAtom((old: any) => ({
          ...old, 
          viewPort: {...requiredTable.position, zoom : 0.4},
          currentTable: requiredTable
      }))
    }
  }

  const generateTree = (data: DBTreeData ) => {
   return  data.schemas.map((schema: Schema, schemaIdx: any) => {
        return <TreeItem key={`grid-${schemaIdx}`} itemId={`grid-${schemaIdx}`} label={
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
          <div style={{height: 12, width: 2 , borderRadius: 1, backgroundColor: `${schema.color}`, marginRight: 5}} />
          {schema.name}
          </div>
          }
          >
     
          {   
            schema.tables.map((table: Table, idx: number) => {
                return <TreeItem key={`grid-sub-${table.name}-${schemaIdx}-${idx}`} onClick={() => onClickNode(table)} itemId={`grid-sub-${table.name}-${schemaIdx}-${idx}`} label={
                  <>
                  <span style={{ display: 'flex', alignItems: 'center'}}>
                  <span  style={{ 
                    padding: table.changeLog ? '0px 3px 0px 3px': undefined,
                    borderRadius: table.changeLog ? 5 : undefined,
                    backgroundColor: table.changeLog === 'table_added' ? '#B6FFCE' : table.changeLog === 'table_removed' ? '#FF4848' : table.changeLog === 'table_updated' ? '#FF9551' : 'transparent',
                    marginRight: 3,
                    color:  'black', 
                    textDecoration: table.changeLog === 'table_removed' ? 'line-through' : ''
                  }}>
                    {table.name}
                  </span>
                  {table?.insightsSummary?.critical > 0 ? getSeverityCircle('#F20530'): <></>}
                  {table?.insightsSummary?.high > 0 ? getSeverityCircle('#FF790B'): <></>}
                  {table?.insightsSummary?.medium > 0 ? getSeverityCircle('#FFCC00'): <></>}
                  {table?.insightsSummary?.low > 0 ? getSeverityCircle('#03D0FF'): <></>}
                 </span>
              </>} />
            })    
          }
          
        </TreeItem>
    })

  }

  const dateList = () => {
    if(!schemaChangeLog) {
      return []
    }
   return  [...Object.keys(schemaChangeLog),'Current']?.reverse()?.map((el: any, idx: number) => ({ id: idx, name: el, val: el}))

 }
 
  return (
    <Box sx={{ minHeight: 600, minWidth: 250, borderRight: '1px solid rgba(0, 0, 0, 0.1)', paddingRight: 2}}>
      <div style={{marginTop: 3}}>
      <Search
            fullWidth
            width="100%"
            minWidth="200px"
            placeholder="Search Table"
            value={filterSearchParams}
            onSubmit={setFilterSearchParams}
            onChangeCallback={setFilterSearchParams}
          />
        <div style={{display: 'flex',justifyContent: 'space-between', flexDirection: 'row', marginTop: 10, width: '100%' ,alignItems: 'center' }}>
          <Typography>
            Compare To:
          </Typography>
        <Select
            name=""
            minWidth={'150px'}
            isSorted={false}
            value={changeLogA.date}
            list={dateList()}
            onSelect={onSelectDate}
          />
        </div>
      </div>  
      <div style={{overflowY: 'auto', maxHeight: 650}}>
      <SimpleTreeView>
        {generateTree(convertToDBTreeData(tablesList))}
      </SimpleTreeView>
      </div>   
    </Box>
  );
}